import { FILE_LENGTH, FILE_SAVED } from 'Core/modules/validators/file'

export const orderInfo = {
  id: null,
  status: null,
  payment_status: null,
  total_price: null,
  PromoCodeDiscountPrice: null,
  DiscountedTotalPrice: null,
  comment: null,
  payment_id: null,
  products: [
    {
      sku: null,
      title: { kz: '', ru: '', en: '' },
      merchant_title: { kz: '', ru: '', en: '' },
      status: '',
      quantity: 1,
      price: '',
      total_price: '',
    },
  ],
  customer: { full_name: '', email: '', phone: '' },
  employee: { full_name: '', tdId: '' },
  payment_type: '',
  address: '',
  recipient: { full_name: '', phone: '' },
}
export const columnNames = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    scopedSlots: { customRender: 'sku' },
  },
  {
    title: 'Название',
    dataIndex: 'title',
    key: 'title',
    scopedSlots: { customRender: 'title' },
  },
  {
    title: 'Тип хранения',
    dataIndex: 'storage_type',
    key: 'storage_type',
    scopedSlots: { customRender: 'storage_type' },
  },
  {
    title: 'Название продавца',
    dataIndex: 'merchant_title',
    key: 'merchant_title',
    scopedSlots: { customRender: 'merchant_title' },
  },
  {
    title: 'Точка выдачи',
    dataIndex: 'pickup_point_id',
    key: 'pickup_point_id',
    scopedSlots: { customRender: 'pickup_point_id' },
  },
  {
    title: 'Количество',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Цена за единицу',
    dataIndex: 'price',
    key: 'price',
    scopedSlots: { customRender: 'price' },
  },
  {
    title: 'Общая цена',
    dataIndex: 'total_price',
    key: 'total_price',
    scopedSlots: { customRender: 'total_price' },
  },
  {
    title: 'Дата доставки',
    dataIndex: 'delivery_dates',
    key: 'delivery_dates',
    scopedSlots: { customRender: 'delivery_dates' },
  },
  {
    title: 'Статус товара',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Причина',
    dataIndex: 'cancel_reason',
    key: 'cancel_reason',
    scopedSlots: { customRender: 'cancel_reason' },
  },
]
export const returnColumnNames = [
  {
    title: 'Название',
    dataIndex: 'title.ru',
    key: 'title.ru',
  },
  {
    title: 'Количество к возврату',
    dataIndex: 'quantity',
    key: 'quantity',
    scopedSlots: { customRender: 'quantity' },
  },
  {
    title: 'Количество в заказе',
    dataIndex: 'max_amount',
    key: 'max_amount',
  },
]
export const returnForm = {
  order_id: null,
  desired_date: null,
  products: [],
  address: {
    floor: '',
    apartment: '',
    house: '',
    street: '',
    city: '',
    region: '',
    geo: {
      latitude: null,
      longitude: null,
    },
  },
}

export const CANCEL_REASONS = {
  incorrectSpecs: {
    value: 'incorrectSpecs',
    label: 'не та характеристика товара',
  },
  incorrectSize: {
    value: 'incorrectSize',
    label: 'не тот размер / габариты',
  },
  changedDecision: {
    value: 'changedDecision',
    label: 'передумал / пропала потребность',
  },
  foundCheaper: {
    value: 'foundCheaper',
    label: 'нашёл дешевле',
  },
  longDelivery: {
    value: 'longDelivery',
    label: 'долгая доставка',
  },
  NotAvailableFromPartner: {
    value: 'NotAvailableFromPartner',
    label: 'Нет в наличии у партнера',
  },
  ProductPriceIsNotCorrect: {
    value: 'ProductPriceIsNotCorrect',
    label: 'Цена товара не корректная',
  },
  AccidentallyIssued: {
    value: 'AccidentallyIssued',
    label: 'Случайно оформили',
  },
}
export const returnFormRules = {
  desired_date: {
    required: true,
    fullField: 'Дата вывоза',
  },
  'address.floor': {
    required: true,
    fullField: 'Этаж',
  },
  'address.apartment': {
    required: true,
    fullField: 'Квартира',
  },
  'address.house': {
    required: true,
    fullField: 'Дом',
  },
  'address.street': {
    required: true,
    fullField: 'Улица',
  },
  'address.city': {
    required: true,
    fullField: 'Город',
  },
  'address.region': {
    required: true,
    fullField: 'Регион',
  },
  'address.geo.latitude': {
    required: true,
    fullField: 'Широта',
    type: 'number',
  },
  'address.geo.longitude': {
    required: true,
    fullField: 'Долгота',
    type: 'number',
  },
}
export const returnFormProductRules = {
  photos: [
    {
      validator: FILE_LENGTH,
      fullField: 'Медиа контент',
      max: 5,
      min: 0,
    },
    {
      validator: FILE_SAVED,
      fullField: 'Медиа контент',
    },
  ],
}
