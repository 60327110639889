export default {
  namespaced: true,
  state: {
    categories: [],
    merchantCategories: [],
    isLoading: false,
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    SET_MERCHANT_CATEGORIES(state, categories) {
      state.merchantCategories = categories
    },
    SET_CATEGORIES_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
  },
  actions: {
    async fetchCategoryTree(context) {
      let data = []
      try {
        context.commit('SET_CATEGORIES_LOADING', true)
        data = await this._vm.$API.Storefront.getCategoryTree()
        addMetaToCategories(data)
      } finally {
        context.commit('SET_CATEGORIES_LOADING', false)
        context.commit('SET_CATEGORIES', data)
      }
    },
    async fetchMerchantCategoryTree(context, merchantID) {
      let data = []
      try {
        context.commit('SET_CATEGORIES_LOADING', true)
        data = await this._vm.$API.Storefront.getMerchantCategories(merchantID)
        addMetaToCategories(data)
        return data
      } finally {
        context.commit('SET_CATEGORIES_LOADING', false)
        context.commit('SET_MERCHANT_CATEGORIES', data)
      }
    },
    getCategoryBySlug(context, { slug, rootCategory }) {
      const root = rootCategory
        ? rootCategory
        : context?.getters?.getCategoryTree
      let cat = null
      for (const cat1 of root) {
        if (cat1?.slug === slug) return (cat = cat1)
        for (const cat2 of cat1?.children) {
          if (cat2.slug === slug) return (cat = cat2)
          for (const cat3 of cat2?.children) {
            if (cat3.slug === slug) return (cat = cat3)
          }
        }
      }
      if (cat) {
        // eslint-disable-next-line no-unused-vars
        const { children, ...category } = cat
        return category
      }
      return null
    },
  },
  getters: {
    getCategoryTree: (state) => state.categories,
    isLoading: (state) => state.isLoading,
    categoriesFlatObject: (state) => {
      return makeFlatCategories(state.categories)
    },
    merchantCategoriesFlatObject: (state) => {
      return makeFlatCategories(state.merchantCategories)
    },
    categoriesFlatArray: (_, getters) => {
      return Object.values(getters.categoriesFlatObject)
    },
    merchantCategoriesFlatArray: (_, getters) => {
      return Object.values(getters.merchantCategoriesFlatObject)
    },
  },
}

export function addMetaToCategories(categories) {
  if (!categories?.length) return []
  categories.forEach((category1) => {
    category1.level = 1
    category1.children?.forEach((category2) => {
      category2.level = 2
      category2.parent = category1.slug
      category2.children.forEach((category3) => {
        category3.level = 3
        category3.parent = category2.slug
        category3.grandParent = category1.slug
      })
    })
  })
}

export function makeFlatCategories(rootCategory) {
  const flatCats = {}
  rootCategory.forEach((cat1) => {
    flatCats[cat1.slug] = cat1
    flatCats[cat1.slug].path = {
      ru: cat1.title.ru,
      kz: cat1.title.kz,
    }
    cat1.children.forEach((cat2) => {
      flatCats[cat2.slug] = cat2
      flatCats[cat2.slug].path = {
        ru: flatCats[cat1.slug].path?.ru + ' - ' + cat2.title?.ru,
        kz: flatCats[cat1.slug].path?.kz + ' - ' + cat2.title?.kz,
      }

      cat2.children.forEach((cat3) => {
        flatCats[cat3.slug] = cat3
        flatCats[cat3.slug].path = {
          ru: flatCats[cat2.slug].path?.ru + ' - ' + cat3.title?.ru,
          kz: flatCats[cat2.slug].path?.kz + ' - ' + cat3.title?.kz,
        }
      })
    })
  })
  return flatCats
}
