export const IS_LINKED_FIELDS = {
  yes: {
    value: true,
    label: 'Связанные',
    variant: 'blue',
  },
  no: {
    value: false,
    label: 'Не связанные',
    variant: 'orange',
  },
}

export const IS_ACTIVE_FIELDS = {
  yes: {
    value: true,
    label: 'Активные',
    variant: 'blue',
  },
  no: {
    value: false,
    label: 'Не активные',
    variant: 'orange',
  },
}

export const rules = {
  model_id: {
    message: 'Обязательное поле',
    required: true,
  },
}

export const categoryRules = {
  category: {
    message: 'Обязательное поле',
    required: true,
  },
}
