import NotifyGoodArrive from 'Proposal/NotifyGoodArrive/NotifyGoodArrive'
import PriceCuts from 'Proposal/PriceCuts/PriceCuts'
import Suggestion from 'Proposal/Suggestion/Suggestion'

export default [
  {
    path: '/notify-good-arrive',
    name: 'NotifyGoodArrive',
    component: NotifyGoodArrive,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['model-view'],
      },
      search: {
        name: 'Сообщить о поступлении товара',
        icon: 'alert',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/price-cuts',
    name: 'PriceCuts',
    component: PriceCuts,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['model-view'],
      },
      search: {
        name: 'Ожидающие снижение цен',
        icon: 'fall',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/suggestion',
    name: 'Suggestion',
    component: Suggestion,
    meta: {
      permission: {
        service: 'storefront',
        permissions: ['model-view'],
      },
      search: {
        name: 'Не нашли товар',
        icon: 'meh',
      },
      failPath: '/censor',
    },
  },
]
