var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('h2',[_vm._v("Внешние уведомления")]),_c('a-form-model',{ref:"form",attrs:{"layout":"inline","model":_vm.filter},on:{"submit":function($event){$event.preventDefault();return _vm.onSearch.apply(null, arguments)}}},[_c('a-form-model-item',{attrs:{"label":"Тип"}},[_c('a-radio-group',{attrs:{"default-value":null,"button-style":"solid"},on:{"change":_vm.onSearch},model:{value:(_vm.filter.type),callback:function ($$v) {_vm.$set(_vm.filter, "type", $$v)},expression:"filter.type"}},[_c('a-radio-button',{attrs:{"value":null}},[_vm._v(" Все")]),_vm._l((_vm.NOTIFICATION_TYPES),function(item,index){return _c('a-radio-button',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])})],2)],1)],1),_c('a-table',{staticClass:"mt--2",attrs:{"columns":_vm.notificationsColumnNames,"loading":_vm.isLoading,"data-source":_vm.notifications,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
        var id = ref.id;

        return id;
},"pagination":_vm.pagination,"bordered":""},on:{"change":_vm.onPageChange},scopedSlots:_vm._u([{key:"type",fn:function(types){return [(types && types.length)?_c('span',[_vm._v(" "+_vm._s(types[0].channel)+" ")]):_vm._e()]}},{key:"created_at",fn:function(created_at){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(created_at))+" ")]}},{key:"push_title",fn:function(title){return [_vm._v(" "+_vm._s(title || '-')+" ")]}},{key:"actions",fn:function(_, notification){return [_c('a-button',{directives:[{name:"can",rawName:"v-can",value:({
          service: 'clients',
          permissions: ['notification-view'],
        }),expression:"{\n          service: 'clients',\n          permissions: ['notification-view'],\n        }"}],attrs:{"type":"primary"},on:{"click":function($event){return _vm.onOpenNotification(notification)}}},[_c('a-icon',{attrs:{"type":"eye"}})],1)]}}])}),_c('a-modal',{attrs:{"title":"Детализация по уведомлению","visible":_vm.isDetailsModalVisible},on:{"cancel":function($event){return _vm.toggleModal(false)}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('a-button',{on:{"click":function($event){return _vm.toggleModal(false)}}},[_vm._v("Закрыть")])]},proxy:true}])},[_c('a-spin',{attrs:{"spinning":_vm.isDetailsLoading}},[(_vm.selectedNotification)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.selectedNotification.body)}}):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }