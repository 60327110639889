
























import { Component, Vue } from 'vue-property-decorator'
import { showErrorMsg } from 'Core/utils'
import { ColumnNames } from './constants'

@Component
class InfoOnParsingList extends Vue {
  ColumnNames = ColumnNames
  isLoading = false
  dataSource: any[] = []
  pagination = {
    current: 1,
    total: 0,
    defaultPageSize: 20,
  }
  filter = {
    file_uuid: this.$route.params.uuid,
    type: 'update',
    state: 'error',
    page: 1,
    limit: 20,
  }

  async initialize() {
    try {
      this.isLoading = true
      const { data, total } =
        await this.$API.ContentDownloader.getParsingResultInfo(this.filter)
      this.dataSource = data
      this.pagination.total = total
      this.isLoading = false
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  onPageChange({ current }) {
    this.filter.page = current
    this.pagination.current = current
    this.initialize()
  }

  mounted() {
    this.initialize()
  }
}
export default InfoOnParsingList
