import OrderList from 'Order/pages/OrderList/OrderList.vue'
import OrderInfo from 'Order/pages/OrderInfo/OrderInfo.vue'

import UnconfirmedOrdersList from 'Order/pages/UnconfirmedOrders/UnconfirmedOrdersList/UnconfirmedOrdersList'
import ReturnsList from 'Order/pages/ReturnsList/ReturnsList'
import ReturnInfo from 'Order/pages/ReturnInfo/ReturnInfo'

import OrderByClickList from 'Order/pages/OrderByClick/List/List'
import OrderByClickInfo from 'Order/pages/OrderByClick/SingleOrder/SingleOrder'
import MixedOrderList from 'Order/pages/MixedOrders/List/MixedOrderList'

export default [
  {
    path: '/orders',
    name: 'OrdersList',
    component: OrderList,
    meta: {
      permission: {
        service: 'oms',
        permissions: ['merchant-order-view'],
      },
      search: {
        name: 'Все заказы',
        icon: 'shopping',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/order/:id',
    name: 'OrderInfo',
    component: OrderInfo,
    meta: {
      permission: {
        service: 'oms',
        permissions: ['buyer-order-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/unconfirmed-orders',
    name: 'UnconfirmedOrders',
    component: UnconfirmedOrdersList,
    meta: {
      permission: {
        service: 'oms',
        permissions: ['merchant-order-view'],
      },
      search: {
        name: 'Неподтвержденные заказы',
        icon: 'fall',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/returns',
    name: 'ReturnsList',
    component: ReturnsList,
    meta: {
      permission: {
        service: 'oms',
        permissions: ['merchant-order-view'],
      },
      search: {
        name: 'Заявки на возврат',
        icon: 'fall',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/returns/:id',
    name: 'ReturnInfo',
    component: ReturnInfo,
    meta: {
      permission: {
        service: 'oms',
        permissions: ['merchant-order-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/orders-by-click',
    name: 'OrderByClickList',
    component: OrderByClickList,
    meta: {
      permission: {
        service: 'oms',
        permissions: ['merchant-order-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/orders-by-click/id=:id',
    name: 'OrderByClickInfo',
    component: OrderByClickInfo,
    meta: {
      permission: {
        service: 'oms',
        permissions: ['merchant-order-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/all-orders',
    name: 'MixedOrderList',
    component: MixedOrderList,
    meta: {
      permission: {
        service: 'oms',
        permissions: ['merchant-order-view'],
      },
      failPath: '/censor',
    },
  },
]
