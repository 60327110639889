<template>
  <div>
    <a-form-model-item>
      <a-input
        class="input-def-wrap"
        v-model="search"
        @change="filterHandler"
      />
    </a-form-model-item>
    <template v-if="!search">
      <div v-for="(item, index) in propList" :key="index">
        <div>
          <list-item
            :title="item.title.ru"
            :has-child="hasChild"
            @click.native="getCategoryChild(step, item)"
          >
            <a-checkbox
              v-if="selectable"
              @click="onCheckCategory($event, item)"
              :checked="selectedCategorySlugs.includes(item.slug)"
            />
          </list-item>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-for="(item, index) in filteredList" :key="index">
        <div>
          <list-item
            :title="item.title.ru"
            :has-child="hasChild"
            @click.native="getCategoryChild(step, item)"
          >
            <a-checkbox
              v-if="selectable"
              @click="onCheckCategory($event, item)"
              :checked="selectedCategorySlugs.includes(item.slug)"
            />
          </list-item>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Fuse from 'fuse.js'

import ListItem from 'Core/components/ListDefault/ListItem'

export default {
  name: 'ListDefault',
  components: {
    ListItem,
  },
  props: {
    hasChild: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: null,
    },
    propList: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectedCategories: Array,
  },
  watch: {
    propList(val) {
      this.fuse = new Fuse(val, this.fuseConfig)
      this.search = ''
    },
  },
  data() {
    return {
      search: '',
      fuseConfig: {
        isCaseSensitive: false,
        keys: ['title.ru', 'title.kz'],
      },
      fuse: null,
      filteredList: [],
    }
  },
  mounted() {
    this.fuse = new Fuse(this.propList, this.fuseConfig)
  },
  methods: {
    getCategoryChild(step, item) {
      if (step) {
        this.$emit('getCategoryChild', { step, item })
      } else {
        this.$emit('setChild', item)
      }
    },
    filterHandler() {
      this.filteredList = this.fuse.search(this.search).map((item) => {
        return item.item
      })
    },
    onCheckCategory(event, category) {
      event.stopPropagation()

      if (event.target.checked) {
        this.$emit('onCategoryChecked', category)
      } else {
        this.$emit('onCategoryUnchecked', category)
      }
    },
  },
  computed: {
    selectedCategorySlugs() {
      if (!this.selectable || !this.selectedCategories?.length) return []
      return this.selectedCategories.map((c) => c.slug)
    },
  },
}
</script>

<style scoped>
.input-def-wrap {
  display: block;
  margin: auto;
  width: 90%;
}
</style>
