export const columnNames = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },

  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'slug',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Тип платежа',
    dataIndex: 'payment_type',
    key: 'payment_type',
    scopedSlots: { customRender: 'payment_type' },
  },
  {
    title: 'Статус оплаты',
    dataIndex: 'payment_status',
    key: 'payment_status',
    scopedSlots: { customRender: 'payment_status' },
  },
  {
    title: 'Клиент',
    dataIndex: 'customer',
    key: 'customer',
    scopedSlots: { customRender: 'customer' },
  },
  {
    title: 'Исполнитель',
    dataIndex: 'executor',
    key: 'executor',
    scopedSlots: { customRender: 'executor' },
  },
  {
    title: 'Дата создания заказа',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'Дата завершения заказа',
    dataIndex: 'end_at',
    key: 'end_at',
    scopedSlots: { customRender: 'end_at' },
  },
  {
    title: 'ID Airba Pay',
    dataIndex: 'payment_id',
    key: 'payment_id',
    scopedSlots: { customRender: 'payment_id' },
  },
]

export const clientProfile = {
  created_at: null,
  email: null,
  first_name: null,
  id: null,
  is_email_verified: false,
  is_enabled: false,
  is_phone_verified: false,
  language: null,
  last_name: null,
  patronymic: null,
  phone: null,
  updated_at: null,
}

export const clientRequisites = {
  addresses: [],
  bank_data: {
    additional_income: null,
    children_amount: null,
    company_activity_type: null,
    job_position: null,
    last_job_work_experience: null,
    marital_status: null,
    monthly_income: null,
    work_place: null,
  },
  birth_date: null,
  created_at: null,
  devices: [],
  financial_credits: {
    iin: null,
    phone: null,
  },
  iin: null,
  is_disabled: false,
  receivers: [],
  sex: {
    en: null,
    kz: null,
    ru: null,
  },
  tdid: null,
  updated_at: null,
}

export const addressesColumnNames = [
  {
    title: 'Город',
    dataIndex: 'city',
    key: 'city',
    scopedSlots: { customRender: 'city' },
  },
  {
    title: 'Улица',
    dataIndex: 'street',
    key: 'street',
    scopedSlots: { customRender: 'street' },
  },
  {
    title: 'Дом',
    dataIndex: 'house',
    key: 'house',
    scopedSlots: { customRender: 'house' },
  },
  {
    title: 'Квартира',
    dataIndex: 'apartment',
    key: 'apartment',
    scopedSlots: { customRender: 'apartment' },
  },
  {
    title: 'Этаж',
    dataIndex: 'floor',
    key: 'floor',
    scopedSlots: { customRender: 'floor' },
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
    scopedSlots: { customRender: 'comment' },
  },
  {
    title: 'Адрес по умолчанию',
    dataIndex: 'is_default',
    key: 'is_default',
    scopedSlots: { customRender: 'is_default' },
  },
]

export const receiversColumnNames = [
  {
    title: 'Имя',
    dataIndex: 'first_name',
    key: 'first_name',
    scopedSlots: { customRender: 'first_name' },
  },
  {
    title: 'Номер телефона',
    dataIndex: 'phone',
    key: 'phone',
    scopedSlots: { customRender: 'phone' },
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'Получатель по умолчанию',
    dataIndex: 'is_default',
    key: 'is_default',
    scopedSlots: { customRender: 'is_default' },
  },
]

export const notificationsColumnNames = [
  {
    title: 'Тип уведомления',
    dataIndex: 'types',
    key: 'types',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: 'Дата отправки уведомления',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Тема уведомления',
    dataIndex: 'title',
    key: 'title',
    scopedSlots: { customRender: 'push_title' },
  },
  {
    title: 'Действия',
    dataIndex: 'actions',
    key: 'actions',
    scopedSlots: { customRender: 'actions' },
  },
]

export const NOTIFICATION_TYPES = {
  sms: {
    value: 'sms',
    label: 'СМС',
  },
  email: {
    value: 'email',
    label: 'Email',
  },
  push: {
    value: 'push',
    label: 'Push уведомление',
  },
}

export const innerNotificationColumnNames = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Номер заказа',
    dataIndex: 'order_id',
    key: 'order_id',
    scopedSlots: { customRender: 'order_id' },
  },
  {
    title: 'Текст',
    dataIndex: 'body',
    key: 'body',
    scopedSlots: { customRender: 'body' },
  },
  {
    title: 'Дата отправки',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
]
