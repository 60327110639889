
























































import { Component, Vue } from 'vue-property-decorator'
import { showErrorMsg } from '@/Core/utils'
import { ORDER_STATUSES } from 'Core/types/order'
import { columnNames } from './constants'

@Component
class OrderByClickList extends Vue {
  columnNames = columnNames
  ORDER_STATUSES = ORDER_STATUSES
  loading = false
  orders = []
  ordersTotalCount = 0
  params = {
    limit: 10,
    page: 1,
  }
  pagination = {
    current: 1,
    total: 0,
    defaultPageSize: 50,
  }
  $API: any

  get cityList() {
    return this.$store.getters['core/cities']
  }
  async getOrdersByClickList() {
    try {
      this.loading = true
      const { data, total } = await this.$API.PreorderService.getOrderList(
        this.params,
      )
      this.orders = data
      this.ordersTotalCount = total
    } catch (error) {
      showErrorMsg(error.message)
    } finally {
      this.loading = false
    }
  }

  async onPageChange({ current }) {
    this.params.page = current
    this.pagination.current = current
    await this.getOrdersByClickList()
  }

  getCityNameById(id) {
    const CITY = this.cityList[id]
    return CITY ? CITY.title.ru : '-'
  }

  mounted() {
    this.getOrdersByClickList()
  }
}

export default OrderByClickList
