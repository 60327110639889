











import { Component, Prop, Vue } from 'vue-property-decorator'
import OuterNotifications from './OuterNotifications.vue'
import InnerNotifications from './InnerNotifications.vue'

@Component({ components: { OuterNotifications, InnerNotifications } })
class ClientNotifications extends Vue {
  @Prop()
  id: string
}

export default ClientNotifications
