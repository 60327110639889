





















import { showErrorMsg } from '@/Core/utils'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { IPartnerListParams } from 'Core/modules/API/@types/MerchantProfile.type'

@Component
class MerchantSelectorAutocomplete extends Vue {
  @Prop()
  value: string

  merchantID = ''
  dataSource: any = []
  searchText = ''
  isLoading = false

  handleSearch(searchText: string) {
    this.searchText = searchText
    this.isLoading = true
    // debounced request
    setTimeout(() => {
      if (searchText && searchText === this.searchText) {
        this.fetchMerchants({ store_name: searchText })
      } else {
        this.isLoading = false
      }
    }, 1000)
  }

  async fetchMerchants({ store_name, merchant_id }: IPartnerListParams) {
    try {
      this.isLoading = true
      const response: any = await this.$API.MerchantProfile.getPartnerList({
        store_name,
        limit: 50,
        merchant_id,
      })
      this.dataSource = response.data
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  handleChange(value) {
    this.merchantID = value
    this.$emit('input', value)
    const payload = { id: value, store_name: this.getStorenameById(value) }
    this.$emit('onSelect', payload.id ? payload : null)
  }

  getStorenameById(id: string) {
    if (!id) return null
    const item = this.dataSource?.find((item) => item.id === id)
    return item.store_name
    // return this.dataSource
  }

  @Watch('value', { immediate: true })
  async onValueChange(newValue: string, oldValue: string) {
    if (!oldValue && newValue) {
      await this.fetchMerchants({ merchant_id: newValue })
      this.merchantID = newValue
    }
  }
}

export default MerchantSelectorAutocomplete
