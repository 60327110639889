import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'
import {
  IContentDownloaderParams,
  IContentDownloaderResponse,
} from 'Core/modules/API/@types/ContentDownloader.type'

/**
 *  Сервис массового создания базовых продуктов
 * <br> Swagger
 * <br> {@link https://admin-api.test.airba.dev/content-downloader/swagger/index.html}
 * */
export class ContentDownloaderService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'content-downloader',
    })
  }

  @Api
  async parseCSV(formData) {
    const response = await this.POST(`/v1/products/create/parse/csv`, formData)
    return response
  }

  @Api
  async updateBaseProductsWithFile(formData) {
    const response = (await this.POST(
      `/v1/products/update/parse/csv`,
      formData,
    )) as any
    return response
  }

  @Api
  async getParsingResultInfo(params) {
    const response = (await this.GET(
      `/v1/products/${params.type}/${params.state}`,
      params,
    )) as any
    return response
  }

  @Api
  async getProgressCountByState(
    params: IContentDownloaderParams,
  ): Promise<IContentDownloaderResponse> {
    const response = (await this.GET(
      `/v1/products/${params.type}/${params.state}/count`,
      params,
    )) as any
    return response
  }
}
