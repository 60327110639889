export const installmentFormRules = {
  'title.ru': {
    type: 'string',
    fullField: 'Заголовок на русском',
    required: true,
    max: 50,
  },
  admin_title: {
    type: 'string',
    fullField: 'Заголовок на русском',
    required: true,
    max: 50,
  },
  partner_title: {
    type: 'string',
    fullField: 'Заголовок на русском',
    required: true,
    max: 50,
  },
  categories: {
    type: 'array',
    fullField: 'Зона действия рассрочки',
    required: true,
  },
  commission: [
    {
      type: 'number',
      fullField: 'Комиссионные',
      required: true,
      max: 100,
    },
  ],
  duration: {
    type: 'number',
    fullField: 'Продолжительность',
    required: true,
    // min: 3,
    // max: 99,
  },
  min_product_price: {
    type: 'number',
    fullField: 'Мин. сумма товара',
    required: true,
    min: 0,
    max: 99999999,
  },
  max_product_price: {
    type: 'number',
    fullField: 'Макс. сумма товара',
    required: true,
    min: 100,
    max: 99999999,
  },
  end_at: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  background_color: [
    {
      type: 'string',
      message: 'Выберите цвет',
      required: true,
    },
    {
      pattern: /^#[0-9a-f]{6}$/i,
      message: 'Невалидный hex цвет',
    },
  ],
  text_color: [
    {
      type: 'string',
      message: 'Выберите цвет',
      required: true,
    },
    {
      pattern: /^#[0-9a-f]{6}$/i,
      message: 'Невалидный hex цвет',
    },
  ],
}
