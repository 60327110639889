<template>
  <div>
    <a-form-model
      ref="form"
      :model="pageInfo"
      :rules="rules"
      @submit.prevent="updateHandler"
    >
      <a-space direction="vertical" style="width: 100%">
        <a-row :gutter="32" type="flex">
          <a-col flex="400px" style="max-width: 100%">
            <h2>Оформление старницы:</h2>
            <a-form-model-item
              label="Цвет текста (hex)"
              prop="banner.text_color"
            >
              <div>
                <a-tag @click="pageInfo.banner.text_color = '#FFFFFF'">
                  белый
                </a-tag>
                <a-tag
                  color="black"
                  @click="pageInfo.banner.text_color = '#000000'"
                >
                  черный
                </a-tag>
                <a-tag
                  color="red"
                  @click="pageInfo.banner.text_color = '#FF0000'"
                >
                  красный
                </a-tag>
                <a-tag
                  color="orange"
                  @click="pageInfo.banner.text_color = '#FF6600'"
                >
                  оранжевый
                </a-tag>
              </div>
              <a-input v-model="pageInfo.banner.text_color" />
            </a-form-model-item>
            <a-form-model-item
              label="Цвет фона (hex)"
              prop="banner.background_color"
            >
              <div>
                <a-tag @click="pageInfo.banner.background_color = '#FFFFFF'">
                  белый
                </a-tag>
                <a-tag
                  color="black"
                  @click="pageInfo.banner.background_color = '#000000'"
                >
                  черный
                </a-tag>
                <a-tag
                  color="red"
                  @click="pageInfo.banner.background_color = '#FF0000'"
                >
                  красный
                </a-tag>
                <a-tag
                  color="orange"
                  @click="pageInfo.banner.background_color = '#FF6600'"
                >
                  оранжевый
                </a-tag>
              </div>
              <a-input v-model="pageInfo.banner.background_color" />
            </a-form-model-item>

            <div
              v-for="item in pageInfo.contact_info.social_medias"
              :key="item.name"
            >
              <a-form-model-item :label="item.name">
                <a-input v-model="item.url" />
              </a-form-model-item>
            </div>
          </a-col>
          <!--          <a-col flex="400px" style="max-width: 100%">-->
          <!--            <h2>Превю:</h2>-->
          <!--            {{pageInfo.store_name}}-->
          <!--            {{pageInfo.logo_url}}-->
          <!--          </a-col>-->
        </a-row>
        <a-button type="primary" html-type="submit"> Сохранить </a-button>
      </a-space>
    </a-form-model>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  name: 'PartersPage',
  data() {
    return {
      pageInfo: {
        banner: {
          text_color: '#FFFFFF',
          background_color: '#000000',
        },
        contact_info: {
          social_medias: [],
        },
      },
      rules: {
        banner: {
          text_color: [
            {
              type: 'string',
              message: 'Поле обязательно для заполнения',
              required: true,
            },
            {
              pattern: /^#[0-9a-f]{3,6}$/i,
              message: 'Невалидный hex цвет',
            },
          ],
          background_color: [
            {
              type: 'string',
              message: 'Поле обязательно для заполнения',
              required: true,
            },
            {
              pattern: /^#[0-9a-f]{3,6}$/i,
              message: 'Невалидный hex цвет',
            },
          ],
        },
      },
    }
  },
  computed: {
    merchantID() {
      return this.$route.params.id
    },
  },
  methods: {
    async init() {
      this.pageInfo = await this.$API.MerchantProfile.getPartnersPage(
        this.merchantID,
      )
      if (!this.pageInfo.banner) {
        this.pageInfo.banner = {
          text_color: '#FFFFFF',
          background_color: '#000000',
        }
      }
      const socials = ['facebook', 'instagram', 'twitter', 'tiktok']
      if (!this.pageInfo.contact_info.social_medias) {
        this.pageInfo.contact_info.social_medias = socials.map((item) => {
          return {
            name: item,
            url: '',
          }
        })
      } else if (this.pageInfo.contact_info.social_medias.length !== 4) {
        this.pageInfo.contact_info.social_medias = socials.map((social) => {
          const existingIdx =
            this.pageInfo.contact_info.social_medias.findIndex((item) => {
              return item && item.name === social
            })
          if (existingIdx !== -1) {
            return {
              name: this.pageInfo.contact_info.social_medias[existingIdx].name,
              url: this.pageInfo.contact_info.social_medias[existingIdx].url,
            }
          } else {
            return {
              name: social,
              url: '',
            }
          }
        })
      }
    },
    async updateHandler() {
      try {
        await this.$refs.form.validate()
        const bodyData = {
          contact_info: {
            social_medias: this.pageInfo.contact_info.social_medias.filter(
              (item) => item.url,
            ),
          },
          banner: this.pageInfo.banner,
        }
        await this.$API.MerchantProfileAdmin.updateProfile(
          this.merchantID,
          bodyData,
        )
        showSuccessMsg('Страница партнера обновлена!')
        await this.init()
      } catch (e) {
        showErrorMsg(e.message)
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped></style>
