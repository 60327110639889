























import { Component, Prop, Vue } from 'vue-property-decorator'
import OrderHistoryViewer from 'Order/components/StatusesHistory/HistoryModal.vue'
import { showErrorMsg } from 'Core/utils'
import { ORDER_STATUSES_HISTORY } from 'Order/components/StatusesHistory/consts'
import { IStatusHistory } from 'Core/modules/API/@types/Audit.type'
import { ORDER_STATUSES } from 'Core/types/order'

@Component({ components: { OrderHistoryViewer } })
class OrderStatusesHistory extends Vue {
  @Prop()
  status: string
  @Prop()
  orderId: string

  isLoading = false
  events: IStatusHistory[] = []
  ORDER_STATUSES = ORDER_STATUSES

  $refs: {
    orderHistoryViewerRef: OrderHistoryViewer
  }

  async showStatusesHistory() {
    try {
      this.$refs.orderHistoryViewerRef.showModal()
      this.isLoading = true
      await this.fetchOrderStatuses()
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  async fetchOrderStatuses() {
    if (!this.events?.length) {
      const { events } = await this.$API.AuditAdmin.getAuditLogs({
        event_type: 'oms.order_change_status',
        entity_id: this.orderId,
        page: 1,
        limit: 20,
      })
      if (
        ![
          ORDER_STATUSES_HISTORY.canceled.status,
          ORDER_STATUSES_HISTORY.completed.status,
        ].includes(events?.[events?.length - 1]?.meta?.status)
      ) {
        const event: any = { meta: ORDER_STATUSES_HISTORY.completed }
        events.push(event)
      }
      this.events = events.map((e) => {
        const res = ORDER_STATUSES_HISTORY[e.meta.status]
        e.meta = { ...e.meta, ...res }
        return e
      })
    }
  }
}

export default OrderStatusesHistory
