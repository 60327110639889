




























import { showErrorMsg } from '@/Core/utils'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { innerNotificationColumnNames } from 'Client/pages/ClientInfo/constants'
import { INote } from 'Core/modules/API/@types/Note.type'

@Component
class InnerNotifications extends Vue {
  @Prop()
  id: string

  innerNotificationColumnNames = innerNotificationColumnNames
  isLoading = false
  notes: INote[] = []
  pagination = {
    current: 1,
    total: 0,
    defaultPageSize: 10,
  }
  sorter = {
    order: '',
    value: '',
  }

  get payload() {
    return {
      page: this.pagination.current,
      limit: this.pagination.defaultPageSize,
      viewer_id: this.id,
      sorting: this.sorter.value,
    }
  }

  async mounted() {
    await this.getNotifications()
  }

  async getNotifications() {
    try {
      this.isLoading = true
      const { Notes, Count } = await this.$API.Notes.getNotes(this.payload)
      this.notes = Notes
      this.pagination.total = Count
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  onPageChange({ current }, _, sorter) {
    // reset current page to 1 if sort order changes
    if (this.sorter.order != sorter.order) {
      this.pagination.current = 1
    } else {
      this.pagination.current = current
    }
    if (sorter.order) {
      this.sorter.value =
        sorter.order === 'descend'
          ? `${sorter.columnKey || 'created_at'}:desc`
          : `${sorter.columnKey || 'created_at'}:asc`
    } else {
      this.sorter.value = ''
    }
    this.sorter.order = sorter.order
    this.getNotifications()
  }

  generateRedirectLink(orderId) {
    if (!orderId) return
    // eslint-disable-next-line no-unused-vars
    const [_, postfix] = orderId.split('-', 2)
    if (postfix) return `/#/partner/${this.id}/order/${orderId}`
    else return `/#/order/${orderId}`
  }
}

export default InnerNotifications
