<template>
  <div>
    <div class="settings-wrap">
      <h2>Список Промо страниц</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'PromoPageCreate',
            })
        "
      >
        Создать новый
      </a-button>
    </div>

    <a-form-model
      layout="inline"
      ref="form"
      :model="filter"
      @submit.prevent="onSearch"
    >
      <div class="settings-wrap">
        <a-form-model-item label="Тип">
          <a-radio-group
            :default-value="null"
            button-style="solid"
            v-model="filter.type"
            @change="onSearch"
          >
            <a-radio-button :value="null"> Все</a-radio-button>
            <a-radio-button
              v-for="(item, index) in PROMO_TYPES"
              :value="item.value"
              :key="index"
            >
              <a-icon :type="item.icon" />
              {{ item.label }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
      </div>
    </a-form-model>

    <a-table
      :columns="columnNames"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :loading="isLoading"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="id" slot-scope="id">
        <code>{{ id | shortID(4) }}</code>
      </div>
      <div slot="slug" slot-scope="slug">
        <router-link :to="`/promo/${slug}`">
          {{ slug }}
        </router-link>
      </div>
      <div slot="updatedAt" slot-scope="updatedAt">
        {{ updatedAt | formatDate }}
      </div>
      <div slot="isEnabled" slot-scope="isEnabled, record">
        <a-button
          v-if="isEnabled"
          type="primary"
          @click="toggleStatusHandler(record)"
        >
          <a-icon type="check-circle" />
        </a-button>
        <a-button v-else type="danger" @click="toggleStatusHandler(record)">
          <a-icon type="stop" />
        </a-button>
      </div>
    </a-table>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { columnNames } from './constants'
import { PROMO_TYPES } from 'Content/pages/PromoPage/PromoPageCreate/constants'

export default {
  name: 'PromoPageList',
  data() {
    return {
      PROMO_TYPES,
      dataSource: [],
      isLoading: false,
      columnNames,
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 10,
      },
      filter: {
        page: 1,
        limit: 10,
        type: null,
      },
    }
  },
  methods: {
    async initialize() {
      try {
        this.isLoading = true
        await this.getPromoPageList()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async getPromoPageList() {
      const { pages, count } = await this.$API.PPS.getPromoPageList(this.filter)
      this.dataSource = pages
      this.pagination.total = count
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.getPromoPageList()
    },
    async toggleStatusHandler(record) {
      try {
        this.isLoading = true
        record.is_enabled = !record.is_enabled
        await this.$API.PPS.updatePromoPage(record.id, record)
        showSuccessMsg(
          `Страница "${record.title}" ${
            record.is_enabled ? 'активирована' : 'деактивирована'
          }`,
        )
        await this.getPromoPageList()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    onSearch() {
      this.initialize()
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped></style>
