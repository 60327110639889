export const formState = {
  agreement: {
    body: null,
    title: null,
    version: null,
  },
  meta: {
    description: null,
    title: null,
    keywords: null,
  },
  code: null,
  description: null,
  template_id: null,
  valid_days_count: 365,
  verification_required: true,
}

export const rules = {
  description: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  code: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  template_id: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  valid_days_count: {
    type: 'number',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  agreement: {
    body: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
    title: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
  },
}

export const columnNames = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Версия',
    dataIndex: 'version',
    key: 'version',
    scopedSlots: { customRender: 'version' },
  },
  {
    title: 'Заголовок',
    dataIndex: 'title',
    key: 'title',
    scopedSlots: { customRender: 'title' },
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'createdAt' },
  },
  {
    title: 'Просмотр документа',
    dataIndex: 'id',
    key: 'view_action',
    width: '200px',
    align: 'center',
    scopedSlots: { customRender: 'viewAction' },
  },
]
