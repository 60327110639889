




















































































import { showErrorMsg } from 'Core/utils'
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  NOTIFICATION_TYPES,
  notificationsColumnNames,
} from 'Client/pages/ClientInfo/constants'
import { INotification } from 'Core/modules/API/@types/Notifier.type'
import { skipEmptyValues } from 'Core/modules/helpers'

@Component
class OuterNotifications extends Vue {
  @Prop({ type: String, required: true })
  id: string

  notifications: any[] = []
  notificationsColumnNames = notificationsColumnNames
  NOTIFICATION_TYPES = NOTIFICATION_TYPES
  isLoading = false
  isDetailsModalVisible = false
  isDetailsLoading = false
  selectedNotification: INotification | null = null

  pagination = {
    current: 1,
    total: 0,
    defaultPageSize: 20,
  }
  filter = {
    type: null,
    address: null,
  }
  sorter = {
    order: '',
    value: '',
  }

  get payload() {
    return {
      tdid: this.id,
      page: this.pagination.current,
      limit: this.pagination.defaultPageSize,
      type: this.filter.type,
      sorting: this.sorter.value,
    }
  }

  async getNotifications() {
    try {
      this.isLoading = true
      const { data, count } = await this.$API.Notifier.getNotifications(
        skipEmptyValues(this.payload),
      )
      this.pagination.total = count
      this.notifications = data
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  onPageChange({ current }, _, sorter) {
    // reset current page to 1 if sort order changes
    if (this.sorter.order != sorter.order) {
      this.pagination.current = 1
    } else {
      this.pagination.current = current
    }

    if (sorter.order) {
      this.sorter.value =
        sorter.order === 'descend'
          ? `${sorter.columnKey || 'created_at'}:desc`
          : `${sorter.columnKey || 'created_at'}:asc`
    } else {
      this.sorter.value = ''
    }
    this.sorter.order = sorter.order
    this.getNotifications()
  }

  onSearch() {
    this.pagination.current = 1
    return this.getNotifications()
  }

  async onOpenNotification(notification: INotification) {
    try {
      this.toggleModal(true)
      if (notification.id === this.selectedNotification?.id) return
      this.selectedNotification = null
      this.isDetailsLoading = true
      this.selectedNotification = await this.$API.Notifier.getNotificationById(
        notification.id,
      )
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isDetailsLoading = false
    }
  }

  toggleModal(isVisible: boolean) {
    this.isDetailsModalVisible = isVisible
  }

  mounted() {
    this.getNotifications()
  }
}

export default OuterNotifications
