import { format, addMinutes, parseJSON } from 'date-fns'

export default {
  formatDate(value) {
    return value
      ? format(parseJSON(value), 'dd.MM.yyyy HH:mm:ss')
      : `Неизвестня дата: [${value}]`
  },
  formatDateNoTime(value) {
    return value
      ? format(parseJSON(value), 'dd.MM.yyyy')
      : `Неизвестня дата: [${value}]`
  },
  formatDateRaw(value) {
    const date = new Date(value)
    return value
      ? format(
          addMinutes(date, date.getTimezoneOffset()),
          'dd.MM.yyyy HH:mm:ss',
        )
      : ''
  },
  formatTime(value) {
    return format(new Date(value), 'HH:mm')
  },
  formatDateISO(value) {
    return value ? format(new Date(value), 'dd.MM.yyyy') : ''
  },
}
