export const columnNames = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'slug',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Клиент',
    dataIndex: 'customer',
    key: 'customer',
    scopedSlots: { customRender: 'customer' },
  },
  {
    title: 'Товары',
    dataIndex: 'products',
    key: 'products',
    scopedSlots: { customRender: 'products' },
    width: '330px',
  },
  {
    title: 'В работе у',
    dataIndex: 'employee',
    key: 'employee',
    scopedSlots: { customRender: 'employee' },
  },
  {
    title: 'Город',
    dataIndex: 'city_id',
    key: 'city_id',
    scopedSlots: { customRender: 'city_id' },
  },
  {
    title: 'Дата создания заказа',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'Дата последнего изменения',
    dataIndex: 'updated_at',
    key: 'updated_at',
    scopedSlots: { customRender: 'updated_at' },
  },
]
