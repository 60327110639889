export const columnNames = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    scopedSlots: { customRender: 'sku' },
  },
  {
    title: 'Название',
    dataIndex: 'title.ru',
    key: 'title',
    scopedSlots: { customRender: 'titleLink' },
  },
  {
    title: 'Количество',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Цена за единицу',
    dataIndex: 'price',
    key: 'price',
    scopedSlots: { customRender: 'price' },
  },
  {
    title: 'Общая цена',
    dataIndex: 'total_price',
    key: 'total_price',
    scopedSlots: { customRender: 'total_price' },
  },
]
