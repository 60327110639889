<template>
  <a-spin :spinning="isLoading">
    <div class="settings-wrap">
      <h2>Регистрация партнера</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'MerchantList',
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <a-form-model
      ref="form"
      :model="formState"
      :rules="createPartnerRules"
      @submit="createUser"
    >
      <phone-input
        :label="'Номер телефона'"
        :prop="'phone'"
        v-model="formState.phone"
      />
      <a-row type="flex">
        <a-col :flex="0" class="form-margin">
          <a-form-model-item prop="logo_url" label="Логотип">
            <upload-files
              :validations="createPartnerRules.logo_url"
              @getImageParams="getImageParams"
            ></upload-files>
          </a-form-model-item>
        </a-col>
        <a-col :flex="1">
          <a-form-model-item prop="category" label="Категория">
            <a-input v-model="formState.category" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-form-model-item prop="store_name" label="Название компании">
        <a-input v-model="formState.store_name" />
      </a-form-model-item>
      <a-row type="flex">
        <a-col :flex="0" class="form-margin">
          <a-form-model-item prop="ownership" label="Тип компании">
            <a-radio-group v-model="formState.ownership">
              <a-radio-button value="ТОО"> ТОО</a-radio-button>
              <a-radio-button value="ИП"> ИП</a-radio-button>
              <a-radio-button value="АО"> АО</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :flex="8">
          <a-form-model-item
            prop="company_name"
            label="Юридическое название компании"
          >
            <a-input v-model="formState.company_name" />
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-form-model-item prop="first_name" label="Имя">
        <a-input
          v-model="formState.first_name"
          @focusout="capitalize('first_name')"
        />
      </a-form-model-item>
      <a-form-model-item prop="last_name" label="Фамилия">
        <a-input
          v-model="formState.last_name"
          @focusout="capitalize('last_name')"
        />
      </a-form-model-item>
      <a-form-model-item prop="patronymic" label="Отчество">
        <a-input
          v-model="formState.patronymic"
          @focusout="capitalize('patronymic')"
        />
      </a-form-model-item>
      <a-form-model-item prop="email" label="Email">
        <a-input v-model="formState.email" />
      </a-form-model-item>
      <a-form-model-item prop="iin" label="БИН">
        <a-input v-model="formState.iin" />
      </a-form-model-item>
      <a-form-model-item prop="account_manager_tdis" label="Аккаунт менеджер">
        <a-auto-complete
          v-model="formState.account_manager_tdid"
          @search="onManagerSearch"
          @select="onManagerSelect"
          :allow-clear="true"
        >
          <template slot="dataSource">
            <a-select-option
              v-for="item in accountManagersList"
              :key="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </template>
        </a-auto-complete>
      </a-form-model-item>
      <a-button type="primary" html-type="submit">
        Зарегистрировать партнера
      </a-button>
    </a-form-model>
  </a-spin>
</template>

<script>
import { createPartnerRules } from 'Partner/pages/Partner/PartnerCreate/constants'
import { createUserByPhoneAPI } from 'User/api/users'
import { showErrorMsg } from 'Core/utils'
import { capitalize } from 'Core/modules/helpers'
import UploadFiles from 'Core/components/Files/UploadFiles.vue'
import Fuse from 'fuse.js'

export default {
  name: 'PartnerCreate',
  components: { UploadFiles },
  data() {
    return {
      isLoading: false,
      createPartnerRules,
      allAccountManagersList: [],
      accountManagersList: [],
      fuse: null,
      fuseConfig: {
        isCaseSensitive: false,
        keys: ['label'],
      },
      formState: {
        tdid: null,
        is_accepted_user_agreement: true,
        phone: null,
        store_name: null,
        category: null,
        logo_url: null,
        first_name: null,
        last_name: null,
        patronymic: null,
        email: null,
        ownership: null,
        company_name: null,
        iin: null,
        account_manager_tdid: null,
      },
    }
  },
  methods: {
    async initialize() {
      try {
        this.isLoading = true
        const { data } = await this.$API.Roles.getEmployeeList({
          page: 1,
          limit: 200,
          roles: 'account_manager',
        })
        this.allAccountManagersList = data.map((item) => {
          return {
            label: `${item.first_name} ${item.last_name}`,
            value: item.tdid,
          }
        })
        this.fuse = new Fuse(this.allAccountManagersList, this.fuseConfig)
      } catch (error) {
        showErrorMsg(error)
      } finally {
        this.isLoading = false
      }
    },
    onManagerSearch(value) {
      if (value) {
        this.accountManagersList = this.fuse.search(value).map((item) => {
          return item.item
        })
      } else {
        this.accountManagersList = this.allAccountManagersList
      }
    },
    onManagerSelect(value) {
      this.formState.account_manager_tdid = value
    },
    capitalize(str1) {
      this.formState[str1] = capitalize(this.formState[str1])
    },
    getImageParams(imgUrl) {
      this.formState.logo_url = imgUrl
    },
    createUser() {
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isLoading = true
            let { id } = await createUserByPhoneAPI(this.formState.phone)
            this.formState.tdid = id
            let { mid } = await this.$API.MerchantProfile.createPartnerByTDID(
              this.formState,
            )
            this.formState = {
              tdid: null,
              is_accepted_user_agreement: true,
              phone: null,
              store_name: null,
              category: null,
              logo_url: null,
              first_name: null,
              last_name: null,
              patronymic: null,
              email: null,
              ownership: null,
              company_name: null,
              iin: null,
            }
            this.$router.push({
              name: 'PartnerInfo',
              query: {
                tab: '2',
              },
              params: {
                id: mid,
              },
            })
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isLoading = false
          }
        }
      })
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
.form-margin {
  margin-right: 24px;
}
</style>
