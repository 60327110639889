






































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { FormModel } from 'ant-design-vue'
import { partnerFormRules } from 'Partner/pages/Partner/PartnerInfo/constants'
import UploadFiles from 'Core/components/Files/UploadFiles.vue'
import DocumentsInfo from 'Partner/pages/Partner/PartnerInfo/PartnersInfoTabs/DocumentsInfo.vue'
import { filterOption, showErrorMsg, showSuccessMsg } from 'Core/utils'
import { capitalize } from 'Core/modules/helpers'
import { IPartnerUpdateRequest } from '@/Core/types/partners'
import { skipEmptyValues } from 'Core/modules/helpers'

@Component({
  components: {
    UploadFiles,
    DocumentsInfo,
  },

  computed: {
    ...mapGetters({
      cityList: 'core/sortedCities',
    }),
  },
})
class PartnerInfoForm extends Vue {
  @Prop({ required: true }) formState: any
  @Prop({ required: true, type: String }) merchantId: string
  @Ref() form: FormModel

  partnerFormRules = partnerFormRules
  isFetching = false
  // @ts-ignore
  localForm: IPartnerUpdateRequest = {}

  filterOption = filterOption

  capitalize(str1, str2) {
    if (this.localForm && this.localForm[str1] && this.localForm[str1][str2]) {
      this.localForm[str1][str2] = capitalize(this.localForm[str1][str2])
    }
  }

  getImageParams(imgUrl) {
    this.localForm.profile.logo_url = imgUrl
  }

  async submitHandler() {
    let valid = false
    try {
      const formValid = await this.form.validate()
      valid = formValid
    } catch (err) {
      showErrorMsg('Форма заполнена некорректно')
      valid = false
    }

    if (!valid) return
    try {
      this.isFetching = true
      const profileObj = {
        ...this.localForm,
      }
      // допинываем нехватахющие параметры
      profileObj.company_info.phone_number = this.localForm.profile.phone
      profileObj.company_info.first_name_ceo = this.localForm.profile.first_name
      profileObj.company_info.last_name_ceo = this.localForm.profile.last_name
      profileObj.company_info.patronymic_ceo = this.localForm.profile.patronymic
      delete profileObj.fileList

      await this.$API.MerchantProfileAdmin.updateProfile(
        this.merchantId,
        skipEmptyValues(profileObj),
      )
      showSuccessMsg('Удачно обновлен!')
    } catch (err) {
      showErrorMsg(err.message)
    } finally {
      this.isFetching = false
    }
  }

  setPropsToData() {
    this.localForm = {
      account_manager_tdid: this.formState.account_manager.tdid,
      status: this.formState.status,
      company_info: this.formState.company_info,
      profile: {
        ...this.formState.contact_info,
        store_name: this.formState.store_name,
        logo_url: this.formState.logo_url,
      },
      address: this.formState.company_info.address,
      payment_requisites: this.formState.company_info.payment_requisites,
      schedule: this.formState.company_info.schedule,
      fileList: [],
    }
  }

  @Watch('formState', { immediate: true })
  formStateWatcher() {
    this.setPropsToData()
  }
}

export default PartnerInfoForm
