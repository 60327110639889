















































import { Component, Vue } from 'vue-property-decorator'
import ModelFinder from 'Product/components/ModelFinder.vue'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { rules, formState } from './constants'
import { FormModel } from 'ant-design-vue'
import UploadedFileInfo from 'Content/pages/MultipleBaseProductCreate/components/UploadedFileInfo.vue'

@Component({ components: { ModelFinder, UploadedFileInfo } })
class UpdateBaseProduct extends Vue {
  isLoading = false
  formState = formState
  rules = rules
  modelState: string = ''
  fileUuid: null | string = null

  $refs: {
    form: FormModel
  }
  onUpdate() {
    this.$refs.form.validate(async (valid) => {
      if (!valid) {
        showErrorMsg('Заполните все поля')
        return
      }

      try {
        this.isLoading = true
        const formData = new FormData()
        const file = this.formState.files[0].originFileObj
        formData.append('file', file, file?.name)
        formData.append('model_id', this.formState.model_id)
        const response =
          await this.$API.ContentDownloader.updateBaseProductsWithFile(formData)
        if (response?.uuid) this.fileUuid = response.uuid
        // this.clearForm()
        showSuccessMsg('Массовое обновление БП в процессе...')
      } catch (e) {
        showErrorMsg(e.message)
      } finally {
        this.isLoading = false
      }
    })
  }

  onSetModel(selectedModel) {
    this.formState.model_id = selectedModel.id
    this.modelState = selectedModel.title.ru
    this.$refs.form.validateField('model_id', () => {})
  }
  handleChange(info) {
    this.formState.files = info.fileList
  }
  previewFile() {
    return false
  }

  onRemoveFile() {
    this.formState.files = []
    return true
  }
}
export default UpdateBaseProduct
