



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { getDeepValue } from 'Core/modules/helpers'

@Component
class TimelineView extends Vue {
  @Prop()
  statuses: any[]
  @Prop()
  currentStatus: string
  @Prop({ type: String, default: 'title.client' })
  titleKey: string
  @Prop({ type: String, default: 'description.client' })
  descriptionKey: string

  $refs: {
    stepRef: any
  }

  get getPointerColor() {
    return (status: { color: string }, i: number) => {
      if (i !== this.activeStatusIndex) return {}
      return {
        borderColor: status.color,
        zIndex: 22,
      }
    }
  }

  get getTitle() {
    return (obj: any) => getDeepValue(obj, this.titleKey)
  }

  get getDescription() {
    return (obj: any) => getDeepValue(obj, this.descriptionKey)
  }

  get getClass() {
    return (i: number) => {
      if (i > this.activeStatusIndex) {
        return {
          'container--disabled': true,
        }
      } else if (i < this.activeStatusIndex) {
        return {
          'container--inactive': true,
        }
      }
    }
  }

  get activeStatusIndex() {
    return this.statuses.findIndex((s) => s?.status === this.currentStatus) ?? 0
  }
}

export default TimelineView
