export const returnInfo = {
  request_id: null,
  request_uuid: null,
  order_id: null,
  created_at: null,
  dividable: true,
  client_info: {
    tdid: null,
    first_name: null,
    last_name: null,
    patronymic: null,
    address: {
      region: null,
      city: null,
      street: null,
      house: null,
      apartment: null,
      floor: null,
      geo: { latitude: 0, longitude: 0 },
    },
    contact: {
      phone: null,
      phones: null,
      email: null,
    },
    desired_date: null,
  },
  merchant_info: {
    shop_name: null,
  },
  products: [],
}
