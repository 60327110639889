<template>
  <a-spin :spinning="isLoading">
    <div class="settings-wrap">
      <h2>Привязка товаров партнера</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'PartnerInfo',
              params: { id: merchantID },
              query: {
                tab: 0,
              },
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <section class="partner-bind">
      <div class="partner-bind__partner-section">
        <a-input
          placeholder="Введите название товара партнера"
          size="large"
          v-model="partnerProductsFilter.search"
        />
        <a-table
          :data-source="partnerProductsList"
          :columns="partnerProductsColumnNames"
          :locale="{ emptyText: 'Данные не найдены ):' }"
          :custom-row="partnerProductRow"
          :row-selection="{
            selectedRowKeys: partnerSelectedRowKey,
            onChange: onPartnerProductSelect,
            hideDefaultSelections: false,
            selections: true,
            type: 'radio',
          }"
          :row-key="({ products: { sku } }) => sku"
          :loading="partnerLoading"
          :pagination="pagination"
          @change="onPageChange"
          bordered
        >
          <div slot="model" slot-scope="products">
            {{ products.model }}
          </div>
        </a-table>
      </div>
      <div class="partner-bind__mapping-section">
        <a-card>
          <a-row type="flex" align="middle" justify="center">
            <img
              class="img-border"
              :src="baseProductSelectedItem.picture"
              alt=""
            />
          </a-row>
          <a-divider></a-divider>
          <a-row type="flex" justify="center" align="middle"> СКУ</a-row>
          <a-row type="flex">
            <a-col flex="4">
              <p class="margin-none">Товар продавца</p>
              <p class="margin-none">{{ partnerProductSelectedItem.sku }}</p>
            </a-col>
            <a-col flex="4">
              <p class="margin-none">Базовый продукт</p>
              <template v-if="baseProductFromStoreFront.sku">
                <p class="margin-none">
                  <a
                    :href="createBaseProductLink(baseProductFromStoreFront.sku)"
                    target="_blank"
                    >{{ baseProductFromStoreFront.sku }}
                  </a>
                </p>
              </template>
              <template v-else>
                <p class="margin-none">-</p>
              </template>
            </a-col>
          </a-row>
          <a-divider></a-divider>
          <a-row type="flex" justify="center" align="middle"> Брэнд</a-row>
          <a-row type="flex">
            <a-col flex="4">
              <p class="margin-none">Товар продавца</p>
              <p class="margin-none">{{ partnerProductSelectedItem.brand }}</p>
            </a-col>
            <a-col flex="4">
              <p class="margin-none">Базовый продукт</p>
              <p class="margin-none">{{ baseProductSelectedItem.brand }}</p>
            </a-col>
          </a-row>
          <a-divider></a-divider>
          <a-row type="flex" justify="center" align="middle">
            <template
              v-if="
                baseProductFromStoreFront.price && partnerProductSelectedItem
              "
            >
              <template v-if="!priceCheck">
                <span class="red">Цена</span>
              </template>
              <template v-else> Цена</template>
            </template>
            <template v-else> Цена</template>
          </a-row>
          <a-row type="flex" justify="center" align="middle">
            <a-col flex="4">
              <p class="margin-none">Товар продавца</p>
              <p class="margin-none">{{ partnerProductSelectedItem.price }}</p>
            </a-col>
            <a-col flex="4">
              <p class="margin-none">Базовый продукт (минимум)</p>
              <p class="margin-none">
                {{
                  baseProductFromStoreFront.price
                    ? baseProductFromStoreFront.price.minimum
                    : ''
                }}
              </p>
            </a-col>
          </a-row>
          <a-divider></a-divider>
          <a-row type="flex" justify="center" align="middle"> Цвет</a-row>
          <a-row type="flex">
            <a-col flex="4">
              <p class="margin-none">Товар продавца</p>
              <p class="margin-none">-</p>
            </a-col>
            <a-col flex="4">
              <p class="margin-none">Базовый продукт</p>
              <template v-if="baseProductFromStoreFront.color">
                <p class="margin-none">
                  {{ baseProductFromStoreFront.color.title.ru }}
                  {{ baseProductFromStoreFront.color.hex }}
                </p>
              </template>
              <template v-else> -</template>
            </a-col>
          </a-row>
          <a-divider></a-divider>
          <a-row class="margin-top">
            <a-button
              block
              type="primary"
              :disabled="canConnect"
              @click="connectProduct"
              >Связать
            </a-button>
          </a-row>
        </a-card>
      </div>
      <div class="partner-bind__search-section">
        <a-input
          v-model="baseProductFilter.search"
          size="large"
          placeholder="Название базового продукта"
        />
        <a-table
          :data-source="baseProductsList"
          :columns="baseProductsColumnNamesRees"
          :pagination="false"
          :locale="{ emptyText: 'Данные не найдены ):' }"
          :custom-row="baseProductRow"
          :row-selection="{
            selectedRowKeys: baseSelectedRowKey,
            onChange: onBaseProductSelect,
            hideDefaultSelections: false,
            selections: true,
            type: 'radio',
          }"
          :row-key="({ id }) => id"
          :loading="baseProductLoading"
          bordered
        >
        </a-table>
      </div>
    </section>
  </a-spin>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import {
  baseProductsColumnNamesRees,
  partnerProductsColumnNames,
} from 'Partner/pages/MerchantProducts/MerchantProductBind/constants'

export default {
  name: 'PartnerProductsBind',
  data() {
    return {
      isLoading: false,

      partnerLoading: false,
      partnerProductsSearchText: '',
      partnerProductsList: [],
      partnerProductSelectedItem: {},
      partnerSelectedRowKey: [],
      partnerProductsFilter: {
        search: '',
        page: 1,
        limit: 10,
        merchant_id: this.$route.params.merchant_id,
      },
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 10,
        pageSize: 10,
      },

      baseProductLoading: false,
      baseProductSearchText: '',
      baseProductsList: [],
      baseProductSelectedItem: {},
      baseProductFromStoreFront: {},
      baseSelectedRowKey: [],
      baseProductFilter: {
        search: '',
      },
      mappingInfoLoading: false,

      partnerProductsColumnNames,
      baseProductsColumnNamesRees,
    }
  },
  watch: {
    'partnerProductsFilter.search': function (oldVal, newVal) {
      if (newVal === '' && oldVal === '') {
        this.partnerProductsFilter.search = null
      }
      if (oldVal !== newVal) {
        this.partnerProductsSearch()
      }
    },
    'baseProductFilter.search': function (newVal, oldVal) {
      if (newVal !== '') {
        if (oldVal !== newVal) {
          this.baseProductSearch()
        }
      }
    },
  },
  computed: {
    merchantID() {
      return this.$route.params.merchant_id
    },
    priceCheck() {
      if (
        this.baseProductFromStoreFront.price &&
        this.partnerProductSelectedItem
      ) {
        let priceConfirm =
          parseInt(this.partnerProductSelectedItem.price) >
          parseInt(this.baseProductFromStoreFront.price.minimum)
        return priceConfirm
      } else {
        return false
      }
    },
    canConnect() {
      if (
        this.baseProductFromStoreFront.price &&
        this.partnerProductSelectedItem
      ) {
        let priceConfirm =
          parseInt(this.partnerProductSelectedItem.price) >
          parseInt(this.baseProductFromStoreFront.price.minimum)
        return !priceConfirm
      } else {
        return true
      }
    },
  },
  methods: {
    async initialize() {
      try {
        this.isLoading = true
        await this.partnerProductsSearch()
      } catch (error) {
        showErrorMsg('Ошибка при инициализации REES46')
      } finally {
        this.isLoading = false
      }
    },
    async onPageChange(pageNumber) {
      this.partnerProductsFilter.page = pageNumber.current
      this.pagination.current = pageNumber.current
      this.partnerSelectedRowKey = []
      this.partnerProductSelectedItem = {}
      await this.partnerProductsSearch()
    },
    async partnerProductsSearch() {
      try {
        this.partnerLoading = true
        const { products, count } =
          await this.$API.MerchantGatewayAdmin.getUnrecognizedMerchantProductsList(
            this.partnerProductsFilter,
          )
        this.pagination.total = count
        this.partnerProductsList = products
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.partnerLoading = false
      }
    },
    onPartnerProductSelect(selectedRowKeys, selectedRows) {
      this.partnerSelectedRowKey = selectedRowKeys
      this.partnerProductSelectedItem = selectedRows[0].products
      this.baseProductFilter.search = this.partnerProductSelectedItem.model
    },
    partnerProductRow(record) {
      return {
        on: {
          click: () => {
            this.partnerProductSelectedItem = record.products
            this.partnerSelectedRowKey = [record.products.sku]
            this.baseProductFilter.search =
              this.partnerProductSelectedItem.model
          },
        },
      }
    },
    async baseProductSearch() {
      try {
        this.baseProductLoading = true
        const { items } = await this.$API.MultiSearch.search({
          id: 12088,
          query: this.baseProductFilter.search,
          categories: 0,
          limit: 10,
          fields: 'name,price,picture',
        })
        this.baseProductsList = items
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.baseProductLoading = false
      }
    },
    async onBaseProductSelect(selectedRowKeys, selectedRows) {
      this.baseSelectedRowKey = selectedRowKeys
      this.baseProductSelectedItem = selectedRows[0]
      await this.searchBaseProductFromStoreFront(
        this.baseProductSelectedItem.id,
      )
    },
    baseProductRow(record) {
      return {
        on: {
          click: async () => {
            this.baseProductSelectedItem = record
            this.baseSelectedRowKey = [record.id]
            await this.searchBaseProductFromStoreFront(record.id)
          },
        },
      }
    },
    async searchBaseProductFromStoreFront(sku) {
      try {
        let { products } =
          await this.$API.Storefront.searchUnconnectedBaseProduct(
            this.merchantID,
            {
              search: '',
              sku: sku,
              page: 1,
              limit: 10,
            },
          )
        if (products.length > 0) {
          this.baseProductFromStoreFront = products[0]
        } else {
          this.baseProductFromStoreFront = {}
        }
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async connectProduct() {
      try {
        this.isLoading = true
        const params = {
          merchant_id: this.merchantID,
          merchant_sku: this.partnerProductSelectedItem.sku,
          store_sku: this.baseProductFromStoreFront.sku,
        }
        await this.$API.MerchantGatewayAdmin.bindMerchantProduct(params)
        await this.partnerProductsSearch()
        this.baseProductFilter.search = ''
        showSuccessMsg('Продукт успешно привязан')
        this.baseProductsList = []
        this.baseProductSelectedItem = {}
        this.baseSelectedRowKey = []
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    createBaseProductLink: function (sku) {
      return `${window.location.protocol}/#/base-products?sku=${sku}`
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
.partner-bind {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__partner-section {
    width: 30%;
  }

  &__search-section {
    width: 35%;
  }

  &__mapping-section {
    width: 35%;
  }

  &__button {
    align-self: flex-end;
    margin-top: 30px;
  }
}

.img-border {
  border: 1px solid #e5e5e5;
  width: 220px;
  height: 220px;
}

.margin-none {
  margin-bottom: 4px;
  text-align: center;
}

.margin-top {
  margin-top: 8px;
}

.red {
  color: red;
}
</style>
