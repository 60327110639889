














































import { Component, Vue, Watch } from 'vue-property-decorator'
import { IUser, IUsersParams } from 'Core/modules/API/@types/SSO'
import ClientListFilter from 'Client/components/ClientList/ClientListFilter.vue'
import {
  CLIENTS_LIST_COLUMNS,
  FILTER_DEFAULT,
  IFilter,
} from 'Client/pages/ClientList/constants'
import { showErrorMsg, showSuccessMsg } from '@/Core/utils'

@Component({ components: { ClientListFilter } })
class ClientList extends Vue {
  $API: any
  users: IUser[] = []
  usersCount = 0
  CLIENTS_LIST_COLUMNS = CLIENTS_LIST_COLUMNS
  isLoading = false
  pagination = {
    current: 1,
    total: 0,
    defaultPageSize: 10,
  }

  sort: any = {
    field: null,
    order: null,
  }

  filter: IFilter = FILTER_DEFAULT

  get payload(): IUsersParams {
    const { search, current: page, defaultPageSize: limit } = this.filter
    const sort = this.sort.field
      ? `${this.sort.field}:${this.formatSortOrder(this.sort.order)}`
      : null

    return {
      page,
      limit,
      sorting: sort,
      ...search,
    }
  }

  onFilterChange({ value }: { value: any }) {
    this.filter.current = 1
    this.filter.search = value
  }

  async recoverClient(id) {
    try {
      this.isLoading = true
      await this.$API.SSO_Admin.recoverClientById(id)
      showSuccessMsg('Вы восстановили пользователя!')
      this.isLoading = false
    } catch (error) {
      showErrorMsg(error.message)
    }
  }

  onPageChange({ current }, _, sort) {
    if (sort?.columnKey != this.sort.field || sort?.order != this.sort.order) {
      this.filter.current = 1
    } else this.filter.current = current

    this.sort.field = sort.order ? sort.columnKey : null
    this.sort.order = sort.order
  }

  @Watch('payload')
  onFilterPayloadChange() {
    this.getUsers()
  }

  mounted() {
    this.getUsers()
  }

  async getUsers() {
    try {
      this.isLoading = true
      const { Users, Count } = await this.$API.SSO.Users(this.payload)
      this.users = Users
      this.usersCount = Count
      this.filter.total = Count
    } catch (error) {
      showErrorMsg(error.message)
    } finally {
      this.isLoading = false
    }
  }

  formatSortOrder(order?: string) {
    switch (order) {
      case 'descend':
        return 'desc'
      case 'ascend':
        return 'asc'
      default:
        return null
    }
  }
}

export default ClientList
