var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"applications-list"}},[_c('h2',[_vm._v("Лиды Регистрации")]),_c('a-space',{attrs:{"direction":"vertical"}},[_c('a-row',{attrs:{"type":"flex","gutter":16}},[_c('a-col',{attrs:{"span":"auto"}},[_c('a-switch',{on:{"change":_vm.getApplicaitonsList},model:{value:(_vm.filter.is_deleted),callback:function ($$v) {_vm.$set(_vm.filter, "is_deleted", $$v)},expression:"filter.is_deleted"}}),_c('span',{staticStyle:{"margin-left":"4px"}},[_vm._v("Показать удаленные")])],1)],1),_c('a-table',{attrs:{"columns":_vm.columnNames,"data-source":_vm.list,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
            var id = ref.id;

            return id;
},"loading":_vm.isLoading,"pagination":_vm.pagination,"bordered":""},on:{"change":_vm.onPageChange},scopedSlots:_vm._u([{key:"id",fn:function(id){return _c('div',{},[_c('code',[_vm._v(_vm._s(_vm._f("shortID")(id,5)))])])}},{key:"created_at",fn:function(created_at){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("formatDate")(created_at))+" ")])}},{key:"updated_at",fn:function(updated_at){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("formatDate")(updated_at))+" ")])}},{key:"action",fn:function(id, item){return _c('div',{},[(!item.is_deleted)?_c('a-popconfirm',{attrs:{"title":"Вы уверены что хотите удалить?","ok-text":"Да","cancel-text":"Нет"},on:{"confirm":function($event){return _vm.removeApplication(id)}}},[_c('a-button',{directives:[{name:"can",rawName:"v-can",value:({
              service: 'merchant-profile',
              permissions: ['merchant-delete'],
            }),expression:"{\n              service: 'merchant-profile',\n              permissions: ['merchant-delete'],\n            }"}],attrs:{"disabled":_vm.loadingItem === id,"loading":_vm.loadingItem === id,"type":"danger","title":"Архивировать"}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1):_vm._e()],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }