<template>
  <div>
    <a-spin :spinning="isFetching">
      <a-descriptions v-show="!isFetching">
        <template v-slot:title>
          <div class="settings-wrap">
            <h2>
              {{ formState.store_name }} <code>#{{ merchantID }}</code>
            </h2>
            <a-button @click="() => $router.push({ name: 'MerchantList' })">
              {{ $t('common.back') }}
            </a-button>
          </div>
        </template>
        <a-descriptions-item label="Статус">
          <status-view
            :status-list="PARTNER_STATUSES"
            :status="formState.status"
          ></status-view>
          <a-tag @click="modalVisible = true" color="#108ee9">
            <a-icon type="edit" />
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Дата создания">
          {{ formState.created | formatDate }}
        </a-descriptions-item>
        <a-descriptions-item label="Дата последнего обновления">
          {{ formState.updated | formatDate }}
        </a-descriptions-item>
        <a-descriptions-item label="Email">
          {{ formState.contact_info.email }}
        </a-descriptions-item>
        <a-descriptions-item label="Номер телефона">
          {{ formState.contact_info.phone }}
        </a-descriptions-item>
        <a-descriptions-item label="Аккаунт менеджер">
          <template v-if="formState.account_manager.tdid">
            {{ formState.account_manager.name }}
            {{ formState.account_manager.surname }}
            <a-tag @click="managerModalVisible = true" color="#108ee9">
              <a-icon type="edit" />
            </a-tag>
          </template>
          <template v-else>
            <a-tag @click="managerModalVisible = true" color="red">
              Назначить менеджера
            </a-tag>
          </template>
        </a-descriptions-item>
      </a-descriptions>
      <a-tabs v-show="!isFetching" v-model="tabKey" type="card">
        <a-tab-pane
          key="0"
          tab="Товары партнера"
          v-can="{
            service: 'storefront',
            permissions: ['merchant-product-view'],
          }"
        >
          <partner-products-list></partner-products-list>
        </a-tab-pane>
        <a-tab-pane key="1" tab="Сервисные точки">
          <partner-pickup-point-list />
        </a-tab-pane>
        <a-tab-pane key="2" tab="Информация о партнере" force-render>
          <partner-info-form
            ref="partnerForm"
            :form-state="formState"
            :merchant-id="merchantID"
          />
        </a-tab-pane>
        <a-tab-pane key="3" tab="Информация о заказах">
          <partner-orders-list />
        </a-tab-pane>
        <a-tab-pane key="4" tab="Действия">
          <a-tabs tab-position="right">
            <a-tab-pane key="0" tab="Загрузка Kaspi логов">
              <partner-kaspi-logs :merchant-id="merchantID" />
            </a-tab-pane>
          </a-tabs>
        </a-tab-pane>
        <a-tab-pane key="5" tab="Комиссии">
          <comissions-info />
        </a-tab-pane>
        <a-tab-pane key="6" tab="Сотрудники">
          <employee-info />
        </a-tab-pane>
        <a-tab-pane key="7" tab="График работы">
          <schedule-info :company-info-prop="scheduleInfo" />
        </a-tab-pane>
        <a-tab-pane key="8" tab="Страница продавца">
          <partners-page :company-info-prop="scheduleInfo" />
        </a-tab-pane>
      </a-tabs>
      <a-modal
        v-model="modalVisible"
        title="Сменить статус партнера"
        @ok="handleOk"
        cancel-text="Отмена"
        :ok-text="`Изменить на: ${
          PARTNER_STATUSES[newStatus] ? PARTNER_STATUSES[newStatus].label : ''
        }`"
        :ok-button-props="{
          props: { disabled: !newStatus },
        }"
      >
        <div>
          <h3>
            Текущий статус:
            <status-view
              :status-list="PARTNER_STATUSES"
              :status="formState.status"
            ></status-view>
          </h3>
          <div class="status-label">
            <p>Выберите новый статус партнера:</p>
            <template v-for="(item, index) in PARTNER_STATUSES">
              <a-tag
                v-if="item.value !== 'new' && formState.status !== item.value"
                :key="index"
                :color="item.variant"
                @click="partnerStatusChange(item.value)"
              >
                <a-icon :type="item.icon" />
                {{ item.label }}
              </a-tag>
            </template>
          </div>
        </div>
      </a-modal>

      <a-modal
        v-model="managerModalVisible"
        title="Прикрепить аккаунт менеджера:"
        @ok="changeManager"
        cancel-text="Отмена"
        ok-text="Прикрепить"
      >
        <div>
          <h3>
            Текущий Менеджер: {{ formState.account_manager.name }}
            {{ formState.account_manager.surname }}
          </h3>
          <div class="status-label">
            <p>Выберите менеджера:</p>
            <a-auto-complete
              v-model="selectedManager"
              @search="onManagerSearch"
              @select="onManagerSelect"
              :allow-clear="true"
            >
              <template slot="dataSource">
                <a-select-option
                  v-for="item in accountManagersList"
                  :key="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </template>
            </a-auto-complete>
          </div>
        </div>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { formState } from './constants'
import { filterOption } from 'Core/utils'

import PartnerProductsList from 'Partner/pages/Partner/PartnerInfo/PartnerProducts/PartnerProductsList'
import PartnerPickupPointList from 'Partner/pages/Partner/PartnerInfo/PartnerPickupPoint/PickupPointList'
import PartnerOrdersList from 'Partner/pages/Partner/PartnerInfo/PartnerOrders/PartnerOrders'
import PartnerKaspiLogs from 'Partner/pages/Partner/PartnerInfo/PartnerActions/PartnerKaspiLogs'
import PartnerInfoForm from 'Partner/pages/Partner/PartnerInfo/PartnerInfoForm/PartnerInfoForm'

import ComissionsInfo from 'Partner/pages/Partner/PartnerInfo/PartnersInfoTabs/ComissionsInfo'
import EmployeeInfo from 'Partner/pages/Partner/PartnerInfo/PartnersInfoTabs/EmployeeInfo'
import ScheduleInfo from 'Partner/pages/Partner/PartnerInfo/PartnersInfoTabs/ScheduleInfo'
import { PARTNER_STATUSES } from 'Core/types/partner'
import Fuse from 'fuse.js'
import PartnersPage from './PartnersInfoTabs/PartnersPage'

export default {
  name: 'PartnerInfo',
  components: {
    PartnersPage,
    PartnerProductsList,
    PartnerPickupPointList,
    PartnerOrdersList,
    PartnerInfoForm,
    ComissionsInfo,
    EmployeeInfo,
    ScheduleInfo,
    PartnerKaspiLogs,
  },
  data: () => ({
    PARTNER_STATUSES,
    formState,
    generalInfo: {},
    aboutInfo: {},
    bankDetails: {},
    addressInfo: {},
    scheduleInfo: {},

    isFetching: true,
    modalVisible: false,
    newStatus: null,

    tabKey: '0',
    subTabKey: '1',

    managerModalVisible: false,
    selectedManager: '',
    allAccountManagersList: [],
    accountManagersList: [],
    fuse: null,
    fuseConfig: {
      isCaseSensitive: false,
      keys: ['label'],
    },
  }),
  methods: {
    filterOption,
    async handleOk() {
      try {
        if (this.newStatus === PARTNER_STATUSES.active.value) {
          await this.$API.MerchantProfile.enabledMerchant(this.merchantID)
        } else if (this.newStatus === PARTNER_STATUSES.disabled.value) {
          await this.$API.MerchantProfile.disabledMerchant(this.merchantID)
        } else {
          await this.$API.MerchantProfileAdmin.updateProfile(this.merchantID, {
            status: this.newStatus,
          })
        }
        await this.getPartnerByID()
        showSuccessMsg('Удачно обновлен!')
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.modalVisible = false
      }
    },
    async changeManager() {
      try {
        await this.$API.MerchantProfileAdmin.updateProfile(this.merchantID, {
          account_manager_tdid: this.selectedManager,
        })
        await this.getPartnerByID()
        showSuccessMsg('Удачно обновлен!')
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.managerModalVisible = false
      }
    },
    onManagerSearch(value) {
      if (value) {
        this.accountManagersList = this.fuse.search(value).map((item) => {
          return item.item
        })
      } else {
        this.accountManagersList = this.allAccountManagersList
      }
    },
    onManagerSelect(value) {
      this.selectedManager = value
    },
    async initialize() {
      try {
        this.isFetching = true
        const { data } = await this.$API.Roles.getEmployeeList({
          page: 1,
          limit: 200,
          roles: 'account_manager',
        })
        this.allAccountManagersList = data.map((item) => {
          return {
            label: `${item.first_name} ${item.last_name}`,
            value: item.tdid,
          }
        })
        this.fuse = new Fuse(this.allAccountManagersList, this.fuseConfig)
        await this.getPartnerByID()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    async getPartnerByID() {
      try {
        this.isFetching = true
        this.formState = await this.$API.MerchantProfile.getPartnerById(
          this.$route.params.id,
        )
        if (!this.formState.account_manager) {
          this.formState.account_manager = {
            tdid: null,
            name: null,
            surname: null,
            patronymic: null,
          }
        }
        this.generalInfo = {
          logo_url: this.formState.logo_url,
          contact_info: this.formState.contact_info,
          store_name: this.formState.store_name,
        }
        this.aboutInfo = this.formState.company_info
        this.bankDetails = this.formState.company_info.payment_requisites
        this.scheduleInfo = this.formState.company_info
        this.formState.company_info.address.country = this.formState
          .company_info.address.country
          ? this.formState.company_info.address.country
          : 'Казахстан'
        this.addressInfo = this.formState.company_info.address
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    getImageParams(imgUrl) {
      this.formState.logo_url = imgUrl
    },
    getTabFromUrl() {
      if (this.$route.query.tab) {
        this.tabKey = this.$route.query.tab
      }
      if (this.$route.query.subTab) {
        this.subTabKey = this.$route.query.subTab
      }
    },

    async partnerStatusChange(status) {
      if (this.formState.status === 'new' && status !== 'disabled') {
        try {
          try {
            await this.$refs.partnerForm.$refs.form.validate()
            this.newStatus = status
          } catch {
            showErrorMsg('Заполните информацию о партнере')
            this.tabKey = '2'
            return
          }
        } catch (err) {
          showErrorMsg(err.message)
        }
      } else {
        this.newStatus = status
      }
    },
  },
  async created() {
    await this.initialize()
    this.getTabFromUrl()
  },
  computed: {
    cityList() {
      return this.$store.getters['core/cities']
    },
    merchantID() {
      return this.$route.params.id
    },
  },
}
</script>
<style scoped lang="scss">
.merchant {
  &__link {
    margin-right: 20px;
  }
}

.status-box {
  display: flex;
  align-items: center;

  & span {
    margin: 0 10px;
  }
}

.status-label {
  & span {
    padding: 6px 16px;
    cursor: pointer;
    font-weight: bold;
  }
}
</style>
