import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис регистрации пожелании
 * <br> Swagger
 * <br> {@link https://admin-api.test.airba.dev/wishlist/swagger/index.html#/} */
export class WishlistService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'wishlist',
    })
  }

  @Api
  async getWishList(params) {
    const response = await this.GET(`/v1/product-requests`, params)
    return response
  }

  @Api
  async unloadList(params) {
    const response = await this.GET(`/v1/product-requests/csv`, params)
    return response
  }
}
