export const promotionFormRules = {
  'title.ru': {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  'title.kz': {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  'title.en': {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  name: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  description: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  payment_types: {
    type: 'array',
    message: 'Выберите методы оплаты',
    required: true,
  },
  lock_sec_count: {
    type: 'number',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  'target.type': {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  'discount.amount': {
    type: 'number',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  ends_at: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  categoriesView: {
    type: 'string',
    message: 'Выберите как минимум одну категорию',
    required: true,
  },
}

export const paymentTypes = [
  {
    name: 'Наличными',
    value: 'cash',
  },
  {
    name: 'Картой курьеру',
    value: 'card_offline',
  },
  {
    name: 'Онлайн картой',
    value: 'card_online',
  },
  {
    name: 'Кредит',
    value: 'loan',
  },
  {
    name: 'Рассрочка',
    value: 'installment',
  },
]
