import { promotionFormRules, paymentTypes } from '../PromocodeEdit/constants'

import 'moment/locale/ru'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import moment from 'moment'
import { showErrorMsg } from 'Core/utils'

export const addEditPromotionMixin = () => ({
  data() {
    return {
      locale,
      paymentTypes,
      promotionFormRules,
      promotionForm: {
        code: null,
        description: null,
        ends_at: null,
        is_active: true,
        lock_sec_count: 1200,
        max_usage_limit: null,
        name: null,
        starts_at: null,
        title: {
          kz: null,
          ru: null,
          en: null,
        },
        user_user_limit: null,
        cart_rules: {
          max_cart_size: null,
          max_cart_sum: null,
          min_cart_size: null,
          min_cart_sum: null,
        },
        discount: {
          amount: 1,
          type: 'percent',
        },
        payment_types: [],
        target: {
          items: [],
          type: 'product',
        },
        categoriesView: '',
        merchantID: null,
        merchant_ids: [],
      },
      categoryParent: [],
      merchantCategories: undefined,
      productSearchParams: {
        search: null,
        limit: 50,
        offset: 1,
      },
      isModalVisible: false,
    }
  },
  computed: {
    promotionID() {
      return this.$route.params.promocode_id
    },
    merchant_ids() {
      return this.promotionForm?.merchant_ids?.map((item) => item.id) ?? []
    },
  },
  watch: {
    'promotionForm.target.items': function (categories) {
      if (this.promotionForm.target.type !== 'category') return

      if (categories?.length) {
        this.promotionForm.categoriesView = `Выбрано категории: ${categories.length}`
      } else this.promotionForm.categoriesView = ''

      if (this.$refs?.categoryRef) {
        this.$refs.categoryRef.validate()
      }
    },
    'promotionForm.merchantID': async function (value) {
      if (!value) {
        this.merchantCategories = undefined
        return
      }
      await this.getMerchantCategories(value)
      if (this.promotionForm.target.type === 'cart') {
        this.promotionForm.target.type = null
      }
    },
  },
  methods: {
    onRangeDatepickerChange(datesMoment) {
      const [start_date, end_date] = datesMoment
      this.promotionForm.starts_at = moment(start_date).toISOString()
      this.promotionForm.ends_at = moment(end_date).toISOString()
    },
    async onChangeTargetType(type) {
      this.promotionForm.target.type = type
      if (type !== 'product') {
        this.promotionForm.target.items = []
      }
      if (type === 'category' && !this.categoryParent.length) {
        await this.getParentCategoryList()
      }
    },
    async getParentCategoryList() {
      this.categoryParent = await this.$API.Storefront.getCategoryList({
        level: 1,
      })
    },
    onCategoriesSelected(categories) {
      this.promotionForm.target.items = categories.map((category) => ({
        id: category?.slug,
        title: category.title,
      }))

      this.$set(this.promotionForm, 'categoriesView', '')
    },
    onSelectMerchant(val) {
      if (!this.promotionForm?.merchant_ids) {
        this.$set(this.promotionForm, 'merchant_ids', [])
      }

      if (!val) this.promotionForm.merchant_ids = []
      else {
        this.promotionForm.merchant_ids = [val]
      }
    },
    async getMerchantCategories(merchantID) {
      try {
        this.merchantCategories = await this.$store.dispatch(
          'config/categories/fetchMerchantCategoryTree',
          merchantID,
        )
      } catch (e) {
        showErrorMsg(e.message)
      }
    },
  },
})
