<template>
  <div>
    <a-tabs>
      <a-tab-pane key="1" tab="Редактирование документа">
        <a-form-model
          ref="form"
          :model="formState"
          :rules="rules"
          @submit="onValidate"
        >
          <a-form-model-item prop="agreement.title" label="Заголовок">
            <a-input v-model="formState.agreement.title" />
          </a-form-model-item>
          <a-form-model-item prop="description" label="Описание">
            <a-input v-model="formState.description" />
          </a-form-model-item>
          <a-form-model-item prop="meta.title" label="Заголовок (meta)">
            <a-input v-model="formState.meta.title" />
          </a-form-model-item>
          <a-form-model-item prop="meta.description" label="Описание (meta)">
            <a-input v-model="formState.meta.description" />
          </a-form-model-item>
          <a-form-model-item prop="meta.keywords" label="Ключевые слова (meta)">
            <a-input v-model="formState.meta.keywords" />
          </a-form-model-item>
          <a-form-model-item prop="agreement.body" label="HTML код">
            <code-editor
              v-model="formState.agreement.body"
              :options="cmOptions"
            />

            <a-button size="small" @click="togglePreview">
              Предпросмотр шаблона
            </a-button>
            <a-drawer
              title="Просмотр шаблона"
              :width="formState.agreement.body ? '80%' : '45%'"
              :visible="isTemplatePreviewVisible"
              :closable="false"
            >
              <div
                v-html="formState.agreement.body"
                v-if="formState.agreement.body"
              ></div>
              <p v-else>Шаблон пустой. Пожалуйста внесите html разметку</p>
              <div class="button-absolute-box">
                <a-button @click="togglePreview"> Закрыть</a-button>
              </div>
            </a-drawer>
          </a-form-model-item>
          <a-form-model-item prop="code" label="Читабельный code">
            <a-input v-model="formState.code" />
          </a-form-model-item>

          <a-form-model-item prop="template_id" label="template_id">
            <a-input v-model="formState.template_id" />
          </a-form-model-item>

          <a-row type="flex" justify="center">
            <a-col span="16">
              <a-row class="mb-24" type="flex" justify="space-between">
                <a-col>
                  <a-form-model-item
                    prop="valid_days_count"
                    label="Количество действительных дней"
                  >
                    <a-input-number
                      v-model="formState.valid_days_count"
                      :min="1"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col>
                  <a-form-model-item
                    prop="verification_required"
                    label="Требуется проверка"
                  >
                    <a-switch
                      checked-children="Да"
                      un-checked-children="Нет"
                      v-model="formState.verification_required"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-col>
          </a-row>

          <a-form-item>
            <a-button type="primary" html-type="submit">Сохранить</a-button>
          </a-form-item>
        </a-form-model>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Все версии">
        <document-versions />
      </a-tab-pane>
      <a-button
        slot="tabBarExtraContent"
        @click="() => $router.push({ name: 'DocumentsList' })"
      >
        {{ $t('common.back') }}
      </a-button>
    </a-tabs>
  </div>
</template>

<script>
import { formState, rules } from './constants'
import { codemirror as CodeEditor } from 'vue-codemirror'

import 'codemirror/mode/htmlembedded/htmlembedded.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/theme/material.css'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

import DocumentVersions from './DocumentVersions'

export default {
  name: 'DocumentsEdit',
  components: {
    CodeEditor,
    DocumentVersions,
  },
  data: () => ({
    formState,
    rules,
    cmOptions: {
      tabSize: 4,
      mode: 'text/html',
      theme: 'material',
      lineNumbers: true,
      line: true,
    },
    isTemplatePreviewVisible: false,
  }),
  computed: {
    documentId() {
      return this.$route.params.id
    },
  },
  methods: {
    async initialize() {
      try {
        this.isFetching = true
        await this.getAgreeDocumentById()
        this.isFetching = false
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    async getAgreeDocumentById() {
      this.formState = await this.$API.Agreement.getAgreeDocumentById(
        this.documentId,
      )
    },
    onValidate(event) {
      event.preventDefault()
      this.validateFields()
    },
    async validateFields() {
      try {
        await this.$refs.form.validate()
        await this.$API.Agreement.updateAgreeDocument(
          this.documentId,
          this.formState,
        )
        showSuccessMsg('Документ сохранен!')
        await this.initialize()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    togglePreview() {
      this.isTemplatePreviewVisible = !this.isTemplatePreviewVisible
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped></style>
