<template>
  <div>
    <a-form-model-item>
      <a-button
        style="margin-left: 15px"
        class="model-select-btn"
        @click="visible = true"
      >
        выбрать из списка
      </a-button>
    </a-form-model-item>
    <a-drawer
      title="Выбрать модель"
      :width="720"
      placement="right"
      :mask-closable="true"
      :visible="visible"
      :keyboard="true"
      @close="onClose"
      :after-visible-change="afterVisibleCallback"
    >
      <a-form-model layout="inline" @submit="onSearch" @submit.native.prevent>
        <a-form-model-item>
          <a-input
            ref="search"
            v-model="searchText"
            placeholder="Найти"
            @keydown.enter="onSearch"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit" :disabled="!searchText">
            Поиск
          </a-button>
        </a-form-model-item>
      </a-form-model>

      <div class="model-list">
        <a-spin :spinning="isLoading">
          <template v-if="dataSource.length">
            <a-button
              v-for="item in dataSource"
              class="model-list__item"
              :key="item.id"
              type="primary"
              @click="onSelect(item)"
            >
              {{ item.title.ru }}
            </a-button>
          </template>
          <p class="info" v-else>Нет данных</p>
        </a-spin>
      </div>

      <div class="button-absolute-box">
        <a-button :style="{ marginRight: '8px' }" @click="onClose">
          Отмена
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
export default {
  name: 'ModelFinder',
  data: () => ({
    visible: false,
    isLoading: false,
    dataSource: [],
    searchText: '',
  }),
  methods: {
    onSearch() {
      if (this.searchText !== '') {
        this.isLoading = true
        this.getModelList()
      }
    },
    async getModelList() {
      try {
        const { models } = await this.$API.Storefront.getModelList({
          search: this.searchText,
          is_enabled: true,
        })
        this.dataSource = models
      } finally {
        this.isLoading = false
      }
    },
    onClose() {
      this.visible = false
      this.searchText = ''
      this.dataSource = []
    },
    afterVisibleCallback() {
      this.$refs.search.$el.focus()
    },
    onSelect(selectedModel) {
      this.$emit('setModel', selectedModel)
      this.visible = false
    },
    onReset() {
      this.dataSource = []
      this.searchText = ''
    },
  },
}
</script>

<style scoped lang="scss">
.model-list {
  margin-top: 20px;
  display: flex;

  &__item {
    margin: 5px 10px 5px 0;
  }
}
</style>
