<template>
  <span>
    <template v-if="haveInList">
      <a-button
        ghost
        :style="`border-color:${statusList[status].variant}; color: ${statusList[status].variant}`"
        v-if="tag === 'button'"
      >
        <a-icon v-if="haveIcon" :type="statusList[status].icon" />
        {{ statusList[status].label }}
      </a-button>
      <a-tag :color="statusList[status].variant" v-else>
        <a-icon v-if="haveIcon" :type="statusList[status].icon" />
        {{ statusList[status].label }}
      </a-tag>
    </template>
    <template v-else>
      Неизвестный статус [{{ status ? status : ' ' }}]
    </template>
  </span>
</template>

<script>
export default {
  name: 'StatusView',
  props: {
    statusList: {
      required: true,
      type: Object,
    },
    status: {
      type: String,
    },
    tag: {
      type: String,
    },
  },
  computed: {
    haveInList() {
      return this.status && this.statusList[this.status]
    },
    haveIcon() {
      return this.statusList[this.status].icon
    },
  },
}
</script>

<style lang="scss" scoped></style>
