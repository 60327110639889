










































import { Component, Vue } from 'vue-property-decorator'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { formState, rules } from './constants'

@Component
class DynamicQRCodeCreate extends Vue {
  formState = formState
  rules = rules
  isLoading = false
  $refs

  async validateFields() {
    try {
      await this.$refs.form.validate()
      await this.createQRCode()
    } catch (error) {
      showErrorMsg(error.message)
    }
  }

  async createQRCode() {
    try {
      this.isLoading = true
      const { id } = await this.$API.QRCode.createDynamicQRCode(this.formState)
      showSuccessMsg('Динамический QR код успешно создан')
      await this.$router.push({
        name: 'DynamicQRCodeEdit',
        params: { id },
      })
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  mounted() {
    this.formState = {
      name: null,
      description: null,
      static_link: null,
      dynamic_link: null,
    }
  }
}

export default DynamicQRCodeCreate
