<template>
  <div>
    <div class="settings-wrap">
      <h2>Заявка на возврат #{{ requestId }}</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'ReturnsList',
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <a-spin :spinning="isLoading">
      <a-descriptions
        :column="7"
        class="description-padding"
        title="Информация о заявке"
        bordered
        layout="vertical"
      >
        <a-descriptions-item label="Номер заявки">
          {{ returnInfo.request_id }}
        </a-descriptions-item>
        <a-descriptions-item label="Номер заказа">
          <router-link
            :to="{ name: 'OrderInfo', params: { id: returnInfo.order_id } }"
          >
            {{ returnInfo.order_id }}
          </router-link>
        </a-descriptions-item>
        <a-descriptions-item label="Делимость заказа">
          {{ returnInfo.dividable ? 'Делимый заказ' : 'Неделимый заказ' }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions
        class="description-padding"
        title="Информация о клиенте"
        bordered
        layout="vertical"
      >
        <a-descriptions-item label="ФИО клиента">
          <router-link
            :to="{
              name: 'ClientInfo',
              params: { tdId: returnInfo.client_info.tdid },
            }"
          >
            {{ returnInfo.client_info.first_name }}
            {{ returnInfo.client_info.last_name }}
            {{ returnInfo.client_info.patronymic }}
          </router-link>
        </a-descriptions-item>
        <a-descriptions-item label="Номер телефона">
          {{ returnInfo.client_info.contact.phone }}
        </a-descriptions-item>
        <a-descriptions-item label="Email">
          {{ returnInfo.client_info.contact.email }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions
        class="description-padding"
        title="Информация о заборе заказа"
        bordered
        layout="vertical"
        :column="2"
      >
        <a-descriptions-item label="Адрес">
          <strong class="bold">Регион:</strong>
          {{ returnInfo.client_info.address.region }}<br />
          <strong class="bold">Город:</strong>
          {{ returnInfo.client_info.address.city }}<br />
          <strong class="bold">Улица:</strong>
          {{ returnInfo.client_info.address.street }}<br />
          <strong class="bold">Дом:</strong>
          {{ returnInfo.client_info.address.house }}<br />
          <strong class="bold">Этаж:</strong>
          {{ returnInfo.client_info.address.floor }}<br />
          <strong class="bold">Квартира:</strong>
          {{ returnInfo.client_info.address.apartment }}<br />
        </a-descriptions-item>
        <a-descriptions-item label="Дата забора возврата">
          {{ returnInfo.client_info.desired_date | formatDate }}
        </a-descriptions-item>
        <a-descriptions-item label="Карта" span="3">
          <strong class="bold">Широта:</strong>
          {{ returnInfo.client_info.address.geo.latitude }}
          <strong class="bold">Долгота:</strong>
          {{ returnInfo.client_info.address.geo.longitude }}
          <yandex-map
            :lat="returnInfo.client_info.address.geo.latitude"
            :long="returnInfo.client_info.address.geo.longitude"
            :zoom="15"
          ></yandex-map>
        </a-descriptions-item>
      </a-descriptions>
      <a-divider>Информация о товарах</a-divider>
      <div v-for="(item, index) in returnInfo.products" :key="index">
        <a-descriptions
          class="description-padding"
          bordered
          layout="vertical"
          :column="5"
        >
          <template slot="title">
            <div class="settings-wrap">
              <span>Товар #{{ index + 1 }}</span>
              <div v-if="returnInfo.dividable">
                <a-button
                  type="primary"
                  @click="openDeliveryModal(item.merchant_info.id)"
                  v-if="canOrderDeliveryForProduct(item.status)"
                  :disabled="!canOrderDeliveryForProduct(item.status)"
                >
                  Заказать доставку
                </a-button>
                <a-popconfirm
                  :title="`Подтвердить возврат ${item.title.ru}?`"
                  ok-text="Да"
                  cancel-text="Отмена"
                  :disabled="!canAcceptProduct(item.status)"
                  @confirm="
                    changeProductStatus(
                      item.uuid,
                      RETURN_STATUSES.in_progress.value,
                    )
                  "
                >
                  <a-button
                    type="primary"
                    :disabled="!canAcceptProduct(item.status)"
                    class="mr-l"
                  >
                    Подтвердить товар
                  </a-button>
                </a-popconfirm>
                <a-popconfirm
                  :title="`Отклонить возврат '${item.title.ru}'?`"
                  ok-text="Да"
                  cancel-text="Отмена"
                  :disabled="!canRejectProduct(item.status)"
                  @confirm="
                    changeProductStatus(
                      item.uuid,
                      RETURN_STATUSES.rejected_by_support.value,
                    )
                  "
                >
                  <a-button
                    type="danger"
                    :disabled="!canRejectProduct(item.status)"
                    class="mr-l"
                  >
                    Отклонить товар
                  </a-button>
                </a-popconfirm>
              </div>
            </div>
          </template>
          <a-descriptions-item span="1" label="SKU">
            <router-link :to="`/base-product/${item.sku}`" target="_blank">
              {{ item.sku }}
            </router-link>
          </a-descriptions-item>
          <a-descriptions-item span="1" label="SKU партнера">
            {{ item.merchant_sku }}
          </a-descriptions-item>
          <a-descriptions-item span="1" label="Продавец">
            <router-link
              :to="{
                name: 'PartnerInfo',
                params: { id: item.merchant_info.id },
              }"
            >
              {{ item.merchant_info.shop_name }}
            </router-link>
          </a-descriptions-item>
          <a-descriptions-item span="1" label="Название товара">
            {{ item.title.ru }}
          </a-descriptions-item>
          <a-descriptions-item span="1" label="Статус">
            <status-view
              :status-list="RETURN_STATUSES"
              :status="item.status"
            ></status-view>
          </a-descriptions-item>
          <a-descriptions-item span="1" label="Цена">
            {{ item.discounted_price | currency }}
          </a-descriptions-item>
          <a-descriptions-item span="1" label="Тип хранения">
            <template v-if="item.storage_type === 'fbo'">
              <p>На складе AIRBA</p>
            </template>
            <template v-else-if="item.storage_type === 'fbs'">
              <p>На складе у партнера</p>
            </template>
            <template v-else>
              <p>
                Не известный тип хранения
                <strong>{{ item.storage_type }}</strong>
              </p>
            </template>
          </a-descriptions-item>
          <a-descriptions-item span="1" label="Причина возврата">
            {{ item.reason }}
          </a-descriptions-item>
          <a-descriptions-item span="2" label="Комментарий к товару">
            {{ item.comment }}
          </a-descriptions-item>
          <a-descriptions-item span="5" label="Фотографии">
            <img
              class="img-size"
              v-for="(photo, index) in item.photos"
              :key="index"
              :src="photo.url"
              @click="showPreview(photo.url)"
            />
          </a-descriptions-item>
        </a-descriptions>
        <a-divider></a-divider>
      </div>
      <div v-if="!returnInfo.dividable">
        <a-button
          type="primary"
          @click="openDeliveryRequestModal()"
          v-if="canOrderDeliveryForRequest()"
          :disabled="!canOrderDeliveryForRequest()"
        >
          Заказать доставку
        </a-button>
        <a-popconfirm
          title="Подтвердить возврат всей заявки?"
          ok-text="Да"
          cancel-text="Отмена"
          :disabled="!canAcceptRequest()"
          @confirm="changeRequestStatus(RETURN_STATUSES.in_progress.value)"
        >
          <a-button class="mr-l" type="primary" :disabled="!canAcceptRequest()">
            Подтвердить заявку
          </a-button>
        </a-popconfirm>
        <a-popconfirm
          title="Подтвердить возврат всей заявки?"
          ok-text="Да"
          cancel-text="Отмена"
          :disabled="!canRejectRequest()"
          @confirm="
            changeRequestStatus(RETURN_STATUSES.rejected_by_support.value)
          "
        >
          <a-button type="danger" class="mr-l" :disabled="!canRejectRequest()">
            Отклонить заявку
          </a-button>
        </a-popconfirm>
      </div>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImg" />
      </a-modal>

      <a-modal
        :visible="deliveryModalVisible"
        @cancel="deliveryModalClose"
        @ok="
          orderDeliveryProduct(
            deliveryProducts[selectedMerchant],
            selectedMerchant,
            selectedPickupPoint,
          )
        "
        cancel-text="Отмена"
        ok-text="Заказать"
      >
        <h1>Заказать доставку</h1>
        <p>
          Внимание доставка будет заказана для всех товаров данного продавца:
        </p>
        <ul v-if="selectedMerchant !== ''">
          <li
            v-for="(item, index) in deliveryProducts[selectedMerchant]"
            :key="index"
          >
            {{ item.title }}
          </li>
        </ul>
        <a-form>
          <a-form-item label="Сервисная точка">
            <a-select
              v-model="selectedPickupPoint"
              :options="pickupPoints"
            ></a-select>
          </a-form-item>
        </a-form>
      </a-modal>

      <a-modal
        :visible="deliveryRequestModalVisible"
        @cancel="closeDeliveryRequestModal"
        @ok="orderDeliveryRequest(deliveryProducts, allSelectedPickupPoints)"
        cancel-text="Отмена"
        ok-text="Заказать"
      >
        <h1>Заказать доставку</h1>
        <p>Внимание доставка будет заказана для всех партнеров</p>
        <a-form>
          <a-form-item
            :label="`Сервисная точка для ${item.merchant_title}`"
            v-for="(item, index) in allSelectedPickupPoints"
            :key="index"
          >
            <a-select
              v-model="item.pickup_point_id"
              :options="allPickupPoints[item.merchant_id]"
            ></a-select>
          </a-form-item>
        </a-form>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
import YandexMap from 'Core/components/Maps/YandexMaps/YandexMap'
import { returnInfo } from 'Order/pages/ReturnInfo/constants'
import { RETURN_STATUSES } from 'Core/types/return'

export default {
  name: 'ReturnInfo',
  components: {
    YandexMap,
  },
  data() {
    return {
      returnInfo,
      previewImg: '',
      isLoading: false,
      previewVisible: false,
      deliveryModalVisible: false,
      deliveryProducts: [],
      pickupPoints: [],
      selectedMerchant: '',
      selectedPickupPoint: null,

      deliveryRequestModalVisible: false,
      allSelectedPickupPoints: [],
      allPickupPoints: [],

      RETURN_STATUSES,
    }
  },
  computed: {
    requestId() {
      return this.$route.params.id
    },
  },
  methods: {
    async initialize() {
      try {
        this.isLoading = true
        this.returnInfo = await this.$API.RMS.getReturnRequestInfo(
          this.requestId,
        )
        this.compareProductsByPartner(this.returnInfo.products)
      } catch (error) {
        showErrorMsg(error)
      } finally {
        this.isLoading = false
      }
    },
    async getPickUpPoints(merchantId) {
      try {
        let resoponse = await this.$API.MerchantProfile.getPickupPointList(
          merchantId,
        )
        this.pickupPoints = resoponse.map((item) => {
          return {
            label: item.name,
            value: item.store_id,
          }
        })
        this.selectedPickupPoint = this.pickupPoints[0].value
      } catch (error) {
        showErrorMsg(error)
      }
    },
    async getAllPickupPoints(merchantIdList) {
      try {
        merchantIdList.forEach(async (item) => {
          let response = await this.$API.MerchantProfile.getPickupPointList(
            item.id,
          )
          this.allPickupPoints[item.id] = response.map((subitem) => {
            return {
              label: subitem.name,
              value: subitem.store_id,
            }
          })
          this.allSelectedPickupPoints.push({
            merchant_id: item.id + '',
            pickup_point_id: this.allPickupPoints[item.id][0].value,
            merchant_title: item.name,
          })
        })
      } catch (error) {
        showErrorMsg(error)
      }
    },
    compareProductsByPartner(products) {
      products.forEach((item) => {
        if (!this.deliveryProducts[item.merchant_info.id]) {
          this.deliveryProducts[item.merchant_info.id] = []
        }
        this.deliveryProducts[item.merchant_info.id].push({
          uuid: item.uuid,
          status: this.RETURN_STATUSES.waiting_for_delivery.value,
          title: item.title.ru,
          merchant_title: item.merchant_info.shop_name,
        })
      })
    },
    canAcceptRequest() {
      // Если делимый и статус on_moderation
      return (
        !this.returnInfo.dividable &&
        this.returnInfo.products[0].status ===
          this.RETURN_STATUSES.on_moderation.value
      )
    },
    canRejectRequest() {
      // Если делимый и статус created или on_moderation
      return (
        !this.returnInfo.dividable &&
        (this.returnInfo.products[0].status ===
          this.RETURN_STATUSES.on_moderation.value ||
          this.returnInfo.products[0].status ===
            this.RETURN_STATUSES.created.value)
      )
    },
    canOrderDeliveryForRequest() {
      return (
        !this.returnInfo.dividable &&
        this.returnInfo.products[0].status ===
          this.RETURN_STATUSES.in_progress.value
      )
    },
    canAcceptProduct(status) {
      return (
        this.returnInfo.dividable &&
        status === this.RETURN_STATUSES.on_moderation.value
      )
    },
    canRejectProduct(status) {
      return (
        this.returnInfo.dividable &&
        (status === this.RETURN_STATUSES.on_moderation.value ||
          status === this.RETURN_STATUSES.created.value)
      )
    },
    canOrderDeliveryForProduct(status) {
      return (
        this.returnInfo.dividable &&
        status === this.RETURN_STATUSES.in_progress.value
      )
    },
    async changeProductStatus(uuid, status) {
      try {
        this.isLoading = true
        await this.$API.RMS.changeReturnProductStatus({
          uuid: this.returnInfo.request_uuid,
          products: [
            {
              uuid: uuid,
              status: status,
            },
          ],
        })
        await this.initialize()
      } catch (error) {
        showErrorMsg(error)
      } finally {
        this.isLoading = false
      }
    },
    async changeRequestStatus(status) {
      try {
        this.isLoading = true
        const products = this.returnInfo.products.map((item) => {
          return {
            uuid: item.uuid,
            status: status,
          }
        })
        await this.$API.RMS.changeReturnProductStatus({
          uuid: this.returnInfo.request_uuid,
          products: products,
        })
        await this.initialize()
      } catch (error) {
        showErrorMsg(error)
      } finally {
        this.isLoading = false
      }
    },
    async orderDeliveryProduct(products, id, pickupPoint) {
      try {
        await this.$API.RMS.orderDelivery(
          {
            is_delivery_requested: true,
            merchant_pickup_points: [
              {
                merchant_id: id + '',
                pickup_point_id: pickupPoint,
              },
            ],
          },
          this.returnInfo.request_uuid,
        )
        await this.$API.RMS.changeReturnProductStatus({
          uuid: this.returnInfo.request_uuid,
          products: this.deliveryProducts[id],
        })
        await this.initialize()
        this.deliveryModalClose()
      } catch (error) {
        showErrorMsg(error)
      }
    },

    async orderDeliveryRequest(products, pickupPoints) {
      try {
        await this.$API.RMS.orderDelivery(
          {
            is_delivery_requested: true,
            merchant_pickup_points: pickupPoints,
          },
          this.returnInfo.request_uuid,
        )
        await this.$API.RMS.changeReturnProductStatus({
          uuid: this.returnInfo.request_uuid,
          products: products.flat(),
        })
        await this.initialize()
        this.closeDeliveryRequestModal()
      } catch (error) {
        showErrorMsg(error)
      }
    },

    async openDeliveryModal(merchantId) {
      this.selectedMerchant = merchantId
      await this.getPickUpPoints(merchantId)
      this.deliveryModalVisible = true
    },
    deliveryModalClose() {
      this.selectedMerchant = ''
      this.deliveryModalVisible = false
      this.pickupPoints = []
    },
    async openDeliveryRequestModal() {
      this.deliveryRequestModalVisible = true
      let merchantList = this.deliveryProducts.map((item, index) => {
        if (item) {
          return {
            id: index,
            name: item[0].merchant_title,
          }
        }
      })
      await this.getAllPickupPoints(merchantList)
    },
    closeDeliveryRequestModal() {
      this.deliveryRequestModalVisible = false
    },
    showPreview(url) {
      this.previewVisible = true
      this.previewImg = url
    },
    handleCancel() {
      this.previewImg = ''
      this.previewVisible = false
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
.description-padding {
  margin-bottom: 16px;
}
.mr-l {
  margin-left: 4px;
}
.img-size {
  width: 100px;
  height: 100px;
  margin: 4px;
  &:hover {
    cursor: pointer;
  }
}
</style>
