import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'
import {
  IGetOrderStatusHistoryResponse,
  IProductStatusChange,
} from 'Core/modules/API/@types/Audit.type'

/**
 *  Сервис хранения логов
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/audit/swagger/admin/index.html} */
export class AuditAdminService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'audit',
    })
  }

  @Api
  async getAuditLogs(params): Promise<IGetOrderStatusHistoryResponse> {
    const response = await this.GET<IGetOrderStatusHistoryResponse>(
      `/v1/events`,
      params,
    )
    return response
  }

  @Api
  async getProductStatusesChanges(
    orderId: string,
  ): Promise<IProductStatusChange[]> {
    const response = await this.GET<IProductStatusChange[]>(
      `/v1/events/status/products/${orderId}`,
    )
    return response
  }
}
