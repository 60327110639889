















import { Component, Vue } from 'vue-property-decorator'
import ModelReport from 'Product/pages/DownloadReport/Tabs/ModelReport.vue'
import GeneralReport from 'Product/pages/DownloadReport/Tabs/GeneralReport.vue'
import CategoryReport from 'Product/pages/DownloadReport/Tabs/CategoryReport.vue'

@Component({ components: { ModelReport, GeneralReport, CategoryReport } })
class DownloadReport extends Vue {}

export default DownloadReport
