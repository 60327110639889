export const formState = {
  css: null,
  html: null,
  js: null,
  meta: {
    description: null,
    keywords: null,
    title: null,
  },
  title: null,
  slug: null,
  is_active: false,
  type: null,
}

export const rules = {
  title: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  slug: {
    type: 'string',
    required: true,
    message: 'Поле обязательно для заполнения',
  },
  html: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  css: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  type: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
}

export const PROMO_TYPES = {
  news: {
    value: 'news',
    label: 'Новости',
  },
  promo: {
    value: 'promo',
    label: 'Промо',
  },
  info: {
    value: 'info',
    label: 'Инфо',
  },
}
