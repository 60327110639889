<template>
  <div>
    <div class="settings-wrap">
      <h2>Заявки на возврат</h2>
    </div>
    <a-form-model
      layout="inline"
      ref="form"
      :model="filter"
      @submit.prevent="onFilter"
    >
      <div class="settings-wrap">
        <a-form-model-item>
          <a-radio-group
            :default-value="null"
            button-style="solid"
            v-model="filter.status"
            @change="onFilter"
          >
            <a-radio-button :value="null"> Все</a-radio-button>
            <a-radio-button
              v-for="(item, index) in RETURN_STATUSES_FILTER"
              :value="item.value"
              :key="index"
            >
              <a-icon :type="item.icon" />
              {{ item.label }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="Поиск">
          <a-input v-model="filter.search" />
        </a-form-model-item>
        <a-form-item>
          <a-button-group>
            <a-button type="primary" html-type="submit">Поиск</a-button>
            <a-button type="dashed" @click="dropFilter"> сбросить</a-button>
          </a-button-group>
        </a-form-item>
      </div>
    </a-form-model>
    <a-table
      :columns="columnNames"
      :data-source="returnsList"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="request_id" slot-scope="request_id">
        <router-link :to="{ name: 'ReturnInfo', params: { id: request_id } }">
          {{ request_id }}
        </router-link>
      </div>
      <div slot="order_id" slot-scope="order_id">
        <router-link :to="{ name: 'OrderInfo', params: { id: order_id } }">
          {{ order_id }}
        </router-link>
      </div>
      <div slot="status" slot-scope="status">
        <status-view
          :status-list="RETURN_STATUSES"
          :status="status"
        ></status-view>
      </div>
      <div slot="payment_type" slot-scope="payment_type">
        <template v-if="PaymentTypes[payment_type]">
          <a-icon :type="PaymentTypes[payment_type].icon"></a-icon>
          {{ PaymentTypes[payment_type].label }}
        </template>
        <template v-else>
          Неизвестный способ оплаты [{{ payment_type }}]
        </template>
      </div>
      <div slot="title" slot-scope="title">
        {{ title.ru }}
      </div>
      <div slot="created_at" slot-scope="created_at">
        {{ created_at | formatDate }}
      </div>
    </a-table>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils/notifications'
import { columnNames, RETURN_STATUSES_FILTER } from './constants'
import { PaymentTypes } from 'Core/constants'
import { RETURN_STATUSES } from 'Core/types/return'
import { skipEmptyValues } from 'Core/modules/helpers'
export default {
  name: 'ReturnsList',
  data() {
    return {
      isLoading: false,
      returnsList: [],
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 10,
      },
      filter: {
        page: 1,
        limit: 10,
        search: '',
        status: null,
        sort_by: 'created_at',
        sort_dir: 'asc',
      },
      columnNames,
      PaymentTypes,
      RETURN_STATUSES,
      RETURN_STATUSES_FILTER,
    }
  },
  methods: {
    async initialize() {
      try {
        this.isLoading = true
        await this.getReturnList()
      } catch (error) {
        showErrorMsg(error)
      } finally {
        this.isLoading = false
      }
    },
    async getReturnList() {
      try {
        this.isLoading = true
        const { items, total } = await this.$API.RMS.getReturnRequestList(
          skipEmptyValues(this.filter),
        )
        this.returnsList = items
        this.pagination.total = total
      } catch (error) {
        showErrorMsg(error)
      } finally {
        this.isLoading = false
      }
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.getReturnList()
    },
    dropFilter() {
      this.filter = {
        page: 0,
        limit: 20,
        search: '',
        status: null,
        sort_by: 'created_at',
        sort_dir: 'asc',
      }
      this.getReturnList()
    },
    async onFilter() {
      try {
        this.filter.page = 1
        await this.getReturnList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
