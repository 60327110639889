export const formState = {
  title: {
    kz: null,
    ru: null,
  },
  about: {
    kz: null,
    ru: null,
  },
  about_page: {
    kz: null,
    ru: null,
  },
  brand: null,
  model_id: null,
  color: null,
  minimum_price: 100,
  type: 'REGULAR',
  categories: null,
  dimensions: [
    {
      package: {
        depth: 1,
        height: 1,
        width: 1,
      },
      weight: 1,
    },
  ],
  meta: {
    tags: [],
    type_prefix: [],
    keywords: [],
    title: '',
    description: '',
  },
}

export const rules = {
  title: {
    ru: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
    kz: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
  },
  color: {
    type: 'string',
    message: 'Обязательное поле',
    required: true,
  },
  minimum_price: {
    type: 'number',
    message: 'Обязательное поле',
    required: true,
  },
  categories: {
    message: 'Обязательное поле',
    required: true,
  },
  model_id: {
    message: 'Обязательное поле',
    required: true,
  },
  brand: {
    message: 'Обязательное поле',
    required: true,
  },
  dimensions: [
    {
      package: {
        depth: {
          type: 'number',
          message: 'Поле обязательно для заполнения',
          required: true,
        },
        height: {
          type: 'number',
          message: 'Поле обязательно для заполнения',
          required: true,
        },
        width: {
          type: 'number',
          message: 'Поле обязательно для заполнения',
          required: true,
        },
      },
      weight: {
        type: 'number',
        message: 'Поле обязательно для заполнения',
        required: true,
      },
    },
  ],
}
