















































































































import { showErrorMsg } from '@/Core/utils'
import { Component, Vue } from 'vue-property-decorator'
import {
  IS_ACTIVE_FIELDS,
  IS_LINKED_FIELDS,
  categoryRules,
} from 'Product/pages/DownloadReport/consts'
import BrandSelector from 'Product/components/BrandSelector.vue'
import { IColor } from 'Core/modules/API/@types/ConfigDiscovery.type'
import { skipEmptyValues } from 'Core/modules/helpers'
import { downloadCSVFile } from 'Core/modules/helpers'
import CategorySelector from 'Product/components/CategorySelector.vue'

@Component({ components: { BrandSelector, CategorySelector } })
class CategoryReport extends Vue {
  isLoading = false
  IS_LINKED_FIELDS = IS_LINKED_FIELDS
  IS_ACTIVE_FIELDS = IS_ACTIVE_FIELDS
  colors: IColor[] = []
  rules = categoryRules
  categoriesView = ''
  filter: any = {
    is_linked: null,
    is_active: null,
    category: [],
    brands: [],
    colors: [],
  }

  brand: any[] = []

  $refs: any

  get categoryParent() {
    const categoryList = this.$store.getters['core/categoryParent']
    return categoryList.length
      ? categoryList
      : this.$store.dispatch('core/getCategoryParent')
  }

  async getReport() {
    try {
      this.isLoading = true
      const response: any = await this.$API.Storefront.getCategoryReport(
        skipEmptyValues(this.filter),
      )
      downloadCSVFile('category-report', response)
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  setCategory(category) {
    this.filter.category = category.map((item) => item.slug)
    this.categoriesView = `${category[0].title.ru} > ${category[1].title.ru} > ${category[2].title.ru}`
    this.$refs.form.validateField('category')
  }

  async getColors() {
    try {
      this.isLoading = true
      this.colors = await this.$API.ConfigDiscovery.getColorList()
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  setBrandHandler({ slug }) {
    if (!this.filter.brands.includes(slug)) {
      this.filter.brands.push(slug)
    }
  }

  onReset() {
    this.filter = {
      is_linked: null,
      is_active: null,
      category: [],
      brands: [],
      colors: [],
    }
    this.categoriesView = ''
    this.$refs.form.clearValidate()
  }

  async onSubmit() {
    this.$refs.form.validate(async (valid) => {
      if (!valid) {
        showErrorMsg('Выберите категорию')
        return
      }
      await this.getReport()
    })
  }

  onBrandDropdownOpen() {
    this.$refs.brandSelectorRef?.showDrawer()
  }

  mounted() {
    this.getColors()
  }
}

export default CategoryReport
