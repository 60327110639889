



































import { Component, Prop, Vue } from 'vue-property-decorator'
import { IOrderProduct } from 'Core/modules/API/@types/OMS'
import { IProductStatusChange } from 'Core/modules/API/@types/Audit.type'
import { showErrorMsg } from 'Core/utils'
import { PRODUCT_STATUSES } from 'Core/types/order'
import {
  PRODUCT_DELIVERY_ORDER,
  PRODUCT_STATUSES_HISTORY,
  PRODUCTS_STATUSES_GROUPED,
} from 'Order/components/StatusesHistory/consts'
import OrderHistoryViewer from 'Order/components/StatusesHistory/HistoryModal.vue'

@Component({ components: { OrderHistoryViewer } })
class ProductStatusesHistory extends Vue {
  @Prop()
  orderId: string
  @Prop()
  omsProducts: IOrderProduct[]

  products: IProductStatusChange[] = []
  isLoading = false
  PRODUCT_STATUSES = PRODUCT_STATUSES
  activeEventIndex = 0
  activeEventStatus = ''

  $refs: {
    orderHistoryViewerRef: OrderHistoryViewer
  }

  get columns() {
    return [
      {
        title: 'SKU',
        dataIndex: 'sku',
        key: 'sku',
        scopedSlots: { customRender: 'sku' },
      },
      {
        title: 'Название',
        dataIndex: 'title.ru',
        key: 'title.ru',
      },
      {
        title: 'Продавец',
        dataIndex: 'merchant_title.ru',
        key: 'merchant_title.ru',
      },
      {
        title: 'Количество',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        scopedSlots: { customRender: 'status' },
      },
      {
        title: 'Действия',
        dataIndex: 'actions',
        key: 'actions',
        scopedSlots: { customRender: 'actions' },
      },
    ]
  }

  async fetchProductStatuses() {
    try {
      this.isLoading = true
      const products =
        (await this.$API.AuditAdmin.getProductStatusesChanges(this.orderId)) ??
        []
      this.products = products.map((p) => {
        const omsProduct = this.omsProducts.find(
          (omsProduct) =>
            omsProduct.sku === p.sku &&
            omsProduct.merchant_id === p.merchant_id,
        )
        const status = p.events?.[p?.events.length - 1].meta.status
        p.events = this.getEventsWithStatusDescriptor(p)
        return {
          ...p,
          title: omsProduct?.title,
          merchant_title: omsProduct?.merchant_title,
          status,
        }
      })
    } catch (e) {
      showErrorMsg('Ошибка при получении истории статусов: ' + e.message)
    } finally {
      this.isLoading = false
    }
  }

  /**
   * Возвращает новый список events. Новый список содержит метаданные по статусу(такие, как:
   * название, описание, цвет и т.д). Если последний статус товара !== [отменен, завершен, возвращен] список будет дополнен
   * новыми значениями, которые указывают на какой сейчас стадии товар, и какие стадии остались
   * @param {IProductStatusChange} product
   */
  getEventsWithStatusDescriptor(product: IProductStatusChange) {
    const eventsMapped = product.events.map((e) => {
      const meta = PRODUCT_STATUSES_HISTORY[e.meta.status]
      return { ...e, meta: { ...e.meta, ...meta } }
    })
    const currentStatus = eventsMapped?.[eventsMapped?.length - 1]?.meta?.status
    if (!currentStatus) return eventsMapped

    // если товар в процессе возврата
    if (currentStatus === PRODUCT_STATUSES_HISTORY.returning.status) {
      const addedItems: any[] = [{ meta: PRODUCT_STATUSES_HISTORY.returned }]
      eventsMapped.push(...addedItems)
      // если товар ещё не доставлен
    } else if (
      ![
        PRODUCT_STATUSES_HISTORY.canceled.status,
        PRODUCT_STATUSES_HISTORY.delivered.status,
        PRODUCT_STATUSES_HISTORY.returned.status,
      ].includes(currentStatus)
    ) {
      const indexOfStatusInDeliveryFlow = this.getDeliveryOrder(currentStatus)
      if (!indexOfStatusInDeliveryFlow) return eventsMapped
      const addedItems: any[] = PRODUCT_DELIVERY_ORDER.slice(
        indexOfStatusInDeliveryFlow + 1,
      ).map((e) => ({ meta: e }))
      eventsMapped.push(...addedItems)
    }
    return eventsMapped
  }

  /**
   * Возвращает индекс переданного статуса в массиве PRODUCT_DELIVERY_ORDER. Если такого элемента там нет, ищет
   * по его синонимам
   * @param status
   */
  getDeliveryOrder(status: string) {
    let findInd = (k) => PRODUCT_DELIVERY_ORDER.findIndex((p) => p.status === k)

    const index = findInd(status)
    if (index === -1) {
      const synonym = Object.keys(PRODUCTS_STATUSES_GROUPED).find((key) =>
        PRODUCTS_STATUSES_GROUPED?.[key]?.includes(status),
      )
      if (!synonym || synonym === PRODUCT_STATUSES_HISTORY.canceled.status) {
        return null
      }
      return findInd(synonym)
    } else return index
  }

  showHistory(event: { status: string }, i: number) {
    this.activeEventIndex = i
    this.activeEventStatus = event.status
    this.$refs.orderHistoryViewerRef.showModal()
  }

  async mounted() {
    await this.fetchProductStatuses()
  }
}

export default ProductStatusesHistory
