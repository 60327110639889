export const columnNames = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Заголовок',
    dataIndex: 'title.ru',
    key: 'title.ru',
  },
  {
    title: 'Группа',
    dataIndex: 'group_id',
    key: 'group_id',
    scopedSlots: { customRender: 'groupId' },
  },
  {
    title: 'Параметры',
    dataIndex: 'is_required',
    key: 'is_required',
    scopedSlots: { customRender: 'isRequired' },
    width: '170px',
    align: 'center',
  },
  {
    title: 'Участвует в фильтрации',
    dataIndex: 'is_filter',
    key: 'is_filter',
    align: 'center',
    width: '170px',
    scopedSlots: { customRender: 'is_filter' },
  },
  {
    title: 'Приоритетность',
    dataIndex: 'weight',
    key: 'weight',
    align: 'center',
    width: '170px',
    scopedSlots: { customRender: 'weight' },
  },
  {
    title: '',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    width: '70px',
    align: 'center',
  },
]
