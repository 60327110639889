<template>
  <div>
    <h2>Категории</h2>
    <a-spin :spinning="isLoading">
      <div class="category-wrap">
        <div class="category-wrap__main">
          <div v-for="(item, idx) in categoryTree" :key="idx + 1">
            <p
              :class="{ isActive: activeLabel === item.slug }"
              @click="setMainLabel(item)"
            >
              {{ item.title.ru }}
              <span @click="editHandler(item)">
                <a-icon type="edit" />
              </span>
            </p>
          </div>
        </div>
        <div class="submenu-wrap">
          <div class="submenu-wrap__grid">
            <div v-for="lvl_2 in subMenu" :key="lvl_2.slug">
              <div>
                <p class="sub-title">
                  {{ lvl_2.title.ru }}
                  <span @click="editHandler(lvl_2, true)">
                    <a-icon type="edit" />
                  </span>
                </p>
                <div v-if="lvl_2.children.length">
                  <p
                    class="sub-title__child"
                    v-for="lvl_3 in lvl_2.children"
                    :key="lvl_3.slug"
                  >
                    <a-icon
                      v-if="lvl_3['filter-exclude']"
                      type="exception"
                    ></a-icon>
                    {{ lvl_3.title.ru }}
                    <span @click="editHandler(lvl_3, true, true)">
                      <a-icon type="edit" />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
    <category-edit-modal
      @onCancel="onCancel"
      :modal-visible="modalVisible"
      :active-label="activeLabel"
      :selected-item="selectedItem"
      :category-tree="categoryTree"
      :show-category="showCategory"
      :is-third-category="isThirdCategory"
    />
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
import CategoryEditModal from './CategoryEditModal'

export default {
  name: 'Categories',
  components: {
    CategoryEditModal,
  },
  data() {
    return {
      categoryTree: [],
      isLoading: true,
      modalVisible: false,
      showCategory: false,
      activeLabel: '',
      subMenu: [],
      selectedItem: null,
      isThirdCategory: false,
    }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async initialize() {
      try {
        this.isLoading = true
        this.categoryTree = await this.$API.Storefront.getCategoryTree()
        this.categoryTree = this.sortByTitle(this.categoryTree)
        this.activeLabel = this.categoryTree[0].slug
        this.subMenu = this.sortByTitle(this.categoryTree[0].children)
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    sortByTitle(list) {
      return list.sort((a, b) => {
        if (a.title.ru < b.title.ru) {
          return -1
        }
        if (a.title.ru > b.title.ru) {
          return 1
        }
        return 0
      })
    },
    setMainLabel(menu) {
      this.activeLabel = menu.slug
      this.subMenu = this.sortByTitle(menu.children)
    },
    editHandler(val, showCategory = false, isThirdCategory = false) {
      this.modalVisible = true
      this.showCategory = showCategory
      this.selectedItem = val
      this.isThirdCategory = isThirdCategory
    },
    onCancel(shouldRefresh) {
      this.modalVisible = false
      if (shouldRefresh) {
        this.initialize()
      }
    },
  },
}
</script>

<style scoped>
.category-wrap {
  display: flex;
}
.category-wrap__main {
  min-width: 260px;
}

.isActive {
  background: #e6f7ff !important;
  color: #1890ff;
}
.category-wrap__main p {
  margin: 0 10px 10px 0;
  padding: 5px 12px;
  cursor: pointer;
}

.category-wrap__main p:hover {
  background: #f3fbff;
  color: #1890ff;
}

.submenu-wrap {
  width: 100%;
  overflow-y: auto;
  padding: 10px;
  background: lightcyan;
}
.submenu-wrap__grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-flow: row dense;
  gap: 1rem;
}

.sub-title {
  font-weight: bold;
}
.sub-title span {
  cursor: pointer;
}
.sub-title__child {
  background: white;
  margin: 7px;
  padding: 7px 12px;
  border-radius: 5px;
}
.sub-title__child span {
  cursor: pointer;
}
</style>
