export const ColumnNames = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    scopedSlots: { customRender: 'sku' },
  },
  {
    title: 'Название товара',
    dataIndex: 'title.ru',
    key: 'title.ru',
  },
  {
    title: 'Ошибка',
    dataIndex: 'errors',
    key: 'errors',
    scopedSlots: { customRender: 'errors' },
  },
]
