import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Админский сервис управления единой точкой входа
 * <br> Swagger
 * <br> {@link https://admin-api.test.airba.dev/sso/swagger/index.html#/} */
export class SSO_AdminService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'sso',
    })
  }

  @Api
  async recoverClientById(id) {
    const response = await this.PUT(`/v1/users/${id}/enable`)
    return response
  }
}
