






























































import { columnNames } from './constants'
import { Component, Vue } from 'vue-property-decorator'
import { IApplication } from 'Core/modules/API/@types/Applications.type'
import { skipEmptyValues } from '@/Core/modules/helpers'
import { showErrorMsg } from 'Core/utils'
@Component
class ApplicationsList extends Vue {
  list: IApplication[] = []
  columnNames = columnNames
  pagination = {
    current: 1,
    total: 0,
    defaultPageSize: 10,
  }
  filter = {
    page: 1,
    limit: 10,
    is_deleted: false,
  }
  isLoading = false
  loadingItem = ''

  onPageChange({ current }) {
    this.filter.page = current
    this.pagination.current = current
    this.getApplicaitonsList()
  }

  async removeApplication(id: string) {
    this.loadingItem = id

    try {
      await this.$API.MerchantProfileAdmin.removeApplication(id)
    } catch (err) {
      showErrorMsg(err.message)
    }

    await this.getApplicaitonsList()

    // если удалили последний элемент на стрнанице таблицы
    if (!this.list.length && this.pagination.current > 1) {
      this.onPageChange({ current: this.pagination.current - 1 })
    }

    this.loadingItem = ''
  }

  /**
   * @desc
   * на случай если кончились лиды
   */
  // async generateApplications(startFrom: number = 1, prefix: any = '') {
  //   let promises: any = []
  //   for (let i = startFrom; i < 10; i++) {
  //     promises.push(
  //       this.$API.axios.post('merchant-profile/api/v1/applications/', {
  //         bin: `00000000000${prefix}${i}`,
  //         email: `testemail${prefix}${i}@email.asd`,
  //         name: `testName${prefix}${i}`,
  //         phone: '87071231234',
  //         store_name: `testStoreName${prefix}${i}`,
  //       }),
  //     )
  //   }
  // }

  async getApplicaitonsList() {
    try {
      this.isLoading = true
      const res: any = await this.$API.MerchantProfileAdmin.getApplicationsList(
        skipEmptyValues(this.filter),
      )
      this.pagination.total = res.count
      this.list = res.applications
    } catch (err) {
      showErrorMsg(err.message)
    } finally {
      this.isLoading = false
    }
  }

  initialize() {
    this.getApplicaitonsList()
  }

  mounted() {
    this.initialize()
  }
}

export default ApplicationsList
