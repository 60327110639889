export const ORDER_STATUSES_HISTORY = {
  not_paid: {
    color: '#FFAA47',
    title: 'Ожидает оплаты',
    description: 'Ждём, когда вы закончите оформление заказа',
    status: 'not_paid',
  },
  order_created: {
    color: '#ffaa47',
    title: 'Ожидает оформления',
    description: 'Клиент не закончил оформление заказа',
    status: 'order_created',
  },
  in_process: {
    color: '#60B3F6',
    title: 'В работе',
    description: 'Собираем, упаковываем, доставляем',
    status: 'in_process',
  },
  completed: {
    color: '#1ACE37',
    title: 'Выполнен',
    description: 'Доставили и надеемся, что всё понравилось',
    status: 'completed',
  },
  canceled: {
    color: '#F15515',
    title: 'Отменен',
    description: 'Нам очень жаль',
    status: 'canceled',
  },
}

export const PRODUCT_STATUSES_HISTORY = {
  waiting_confirmation: {
    color: '#60B3F6',
    title: {
      client: 'На подтверждении',
      partner: 'На подтверждении',
    },
    description: {
      client: 'Продавец проверяет наличие товара',
      partner:
        'Партнёр должен проверить наличие товара и подтвердить заказ у себя — позвоните партнеру и напомните о заказе',
    },
    status: 'waiting_confirmation',
  },
  courier_appointed: {
    color: '#1cceb9',
    title: {
      client: 'Курьер назначен',
      partner: 'Готов к выдаче',
    },
    description: {
      client: 'Курьер скоро отправится за заказом к продавцу',
      partner: 'Курьер скоро отправится за заказом к партнёру',
    },
    status: 'courier_appointed',
  },
  courier_goes_to_pickup: {
    color: '#1cceb9',
    title: {
      client: 'Курьер едет за заказом',
      partner: 'Готов к выдаче',
    },
    description: {
      client: 'Курьер едет за заказом к продавцу',
      partner: 'Курьер едет за заказом к партнеру',
    },
    status: 'courier_goes_to_pickup',
  },
  courier_arrived_for: {
    color: '#1cceb9',
    title: {
      client: 'Курьер забирает заказ',
      partner: 'Готов к выдаче',
    },
    description: {
      client: 'Курьер прибыл к продавцу',
      partner: 'Курьер прибыл к месту выдачи',
    },
    status: 'courier_arrived_for',
  },
  courier_checked_and_took: {
    color: '#1cceb9',
    title: {
      client: 'Курьер забрал заказ',
      partner: 'В доставке',
    },
    description: {
      client: 'Курьер проверил и забрал товар',
      partner: 'Курьер проверил и забрал товар',
    },
    status: 'courier_checked_and_took',
  },
  on_assembly: {
    color: '#5BDAEB',
    title: {
      client: 'Сборка',
      partner: 'В сборке',
    },
    description: {
      client: 'Продавец готовит товар к доставке',
      partner: 'Партнер упаковывает товар и готовит к передаче курьеру',
    },
    status: 'on_assembly',
  },
  reassembly: {
    color: '#5bdbea',
    title: {
      client: 'Сборка',
      partner: 'В сборке',
    },
    description: {
      client: 'Продавец заново упаковывает заказ и готовит к передаче курьеру',
      partner: 'Партнёр заново упаковывает заказ и готовит к передаче курьеру',
    },
    status: 'reassembly',
  },
  assembled: {
    color: '#1ACEB8',
    title: {
      client: 'Сборка',
      partner: 'Готов к выдаче',
    },
    description: {
      client: 'Продавец подготовил товар к доставке',
      partner: 'Партнер готов передать заказ курьеру',
    },
    status: 'assembled',
  },
  courier_went_to_deliver: {
    color: '#1ACEB8',
    title: {
      client: 'Курьер в пути',
      partner: 'В доставке',
    },
    description: {
      client: 'Уже едет к вам с товаром',
      partner: 'Курьер едет с товаром к покупателю ',
    },
    status: 'courier_went_to_deliver',
  },
  courier_arrived_at_buyer: {
    color: '#1cceb9',
    title: {
      client: 'Курьер прибыл к покупателю',
      partner: 'В доставке',
    },
    description: {
      client: 'Курьер прибыл к вам',
      partner: 'Курьер прибыл к покупателю',
    },
    status: 'courier_arrived_at_buyer',
  },
  delivered: {
    color: '#1ACE37',
    title: {
      client: 'Доставлен',
      partner: 'Завершен',
    },
    description: {
      client: 'Вы приняли товар',
      partner:
        'Курьер вручил товар покупателю, позвоните покупателю и получите обратную связь',
    },
    status: 'delivered',
  },
  canceled: {
    color: '#F15515',
    title: {
      client: 'Отменен',
      partner: 'Отменен',
    },
    description: {
      client: 'Нам очень жаль',
      partner: 'Заказ отменён',
    },
    status: 'canceled',
  },
  canceled_by_merchant: {
    color: '#F15515',
    title: {
      client: 'Отменён по решению продавца',
      partner: 'Отменён по решению партнера',
    },
    description: {
      client: 'Продавец отменил заказ',
      partner: 'Партнер отменил заказ',
    },
    status: 'canceled_by_merchant',
  },
  canceled_by_buyer: {
    color: '#F15515',
    title: {
      client: 'Отменён по решению покупателя',
      partner: 'Отменён по решению покупателя',
    },
    description: {
      client: 'Вы отменили заказ',
      partner: 'Покупатель отменил заказ',
    },
    status: 'canceled_by_buyer',
  },
  canceled_by_cls: {
    color: '#F15515',
    title: {
      client: 'Отменен по решению курьера',
      partner: 'Отменен по решению курьера',
    },
    description: {
      client: 'Логистика отменила заказ',
      partner: 'Логистика отменила заказ',
    },
    status: 'canceled_by_cls',
  },
  merchant_did_not_confirm: {
    color: '#F15515',
    title: {
      client: 'Не подтверждён вовремя',
      partner: 'Не подтверждён вовремя',
    },
    description: {
      client: 'Заказ отменён, потому что продавец не успел подтвердить его',
      partner: 'Заказ отменён, потому что партнер не успел подтвердить его',
    },
    status: 'merchant_did_not_confirm',
  },
  merchant_did_not_assembly: {
    color: '#F15515',
    title: {
      client: 'Не собран вовремя',
      partner: 'Не собран вовремя',
    },
    description: {
      client:
        'Заказ отменен, потому что продавец не успел подготовить его для выдачи курьеру',
      partner:
        'Заказ отменен, потому что партнер не успел подготовить его для выдачи курьеру',
    },
    status: 'merchant_did_not_assembly',
  },
  returning: {
    color: '#ffaa47',
    title: {
      client: 'В процессе возврата',
      partner: 'В процессе возврата',
    },
    status: 'returning',
    description: {
      client: 'Товар в процессе возврата',
      partner: 'Товар в процессе возврата',
    },
  },
  returned: {
    color: '#f15515',
    title: {
      client: 'Возвращён',
      partner: 'Возвращён',
    },
    status: 'returned',
    description: {
      client: 'Вы вернули товар',
      partner: 'Покупатель вернул товар — позвоните продавцу и узнайте причину',
    },
  },
}

export const PRODUCTS_STATUSES_GROUPED: { [key: string]: string[] } = {
  assembled: [
    PRODUCT_STATUSES_HISTORY.on_assembly.status,
    PRODUCT_STATUSES_HISTORY.reassembly.status,
    PRODUCT_STATUSES_HISTORY.courier_appointed.status,
    PRODUCT_STATUSES_HISTORY.courier_checked_and_took.status,
    PRODUCT_STATUSES_HISTORY.courier_goes_to_pickup.status,
    PRODUCT_STATUSES_HISTORY.courier_arrived_for.status,
  ],
  canceled: [
    PRODUCT_STATUSES_HISTORY.merchant_did_not_confirm.status,
    PRODUCT_STATUSES_HISTORY.canceled_by_merchant.status,
    PRODUCT_STATUSES_HISTORY.canceled_by_buyer.status,
    PRODUCT_STATUSES_HISTORY.canceled_by_cls.status,
    PRODUCT_STATUSES_HISTORY.merchant_did_not_assembly.status,
  ],
  courier_went_to_deliver: [
    PRODUCT_STATUSES_HISTORY.courier_arrived_at_buyer.status,
  ],
}

export const PRODUCT_DELIVERY_ORDER = [
  PRODUCT_STATUSES_HISTORY.waiting_confirmation,
  PRODUCT_STATUSES_HISTORY.on_assembly,
  PRODUCT_STATUSES_HISTORY.assembled,
  PRODUCT_STATUSES_HISTORY.courier_went_to_deliver,
  PRODUCT_STATUSES_HISTORY.delivered,
]
