import { RouteConfig } from 'vue-router'
import ApplicationsList from 'Partner/pages/Application/ApplicationsList/ApplicationsList.vue'

export default [
  {
    path: '/applications',
    name: 'ApplicationsList',
    component: ApplicationsList,
    meta: {
      permission: {
        service: 'merchant-profile',
        permissions: ['merchant-view'],
      },
      search: {
        name: 'Лиды Регистрации',
        icon: 'team',
      },
      failPath: '/censor',
    },
  },
] as RouteConfig[]
