import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'
import { IColor } from 'Core/modules/API/@types/ConfigDiscovery.type'

/**
 *  Сервис управления коммисиями
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/config-discovery/swagger/index.html} */
export class ConfigDiscoveryService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'config-discovery',
    })
  }

  @Api
  async getFaqList(type, params) {
    const response = await this.GET(`/v1/faqs/${type}/pages`, params)
    return response
  }

  @Api
  async getFaqInfo(type, id) {
    const response = await this.GET(`/v1/faqs/${type}/pages/${id}`)
    return response
  }

  @Api
  async createFaq(type, params) {
    const response = await this.POST(`/v1/faqs/${type}/pages`, params)
    return response
  }

  @Api
  async updateFaq(type, id, params) {
    const response = await this.PUT(`/v1/faqs/${type}/pages/${id}`, params)
    return response
  }

  @Api
  async deleteFaq(type, id) {
    const response = await this.DELETE(`/v1/faqs/${type}/pages/${id}`)
    return response
  }

  @Api
  async getCities(params) {
    const response = await this.GET(`/v1/cities`, params)
    return response
  }

  @Api
  async getColorList(params?): Promise<IColor[]> {
    const response = await this.GET<IColor[]>(`/v1/colors`, params)
    return response
  }

  @Api
  async getColorInfoById(id) {
    const response = await this.GET(`/v1/colors/${id}`)
    return response
  }

  @Api
  async createColor(params) {
    const response = await this.POST(`/v1/colors`, params)
    return response
  }

  @Api
  async updateColor(id, payload) {
    const response = await this.PUT(`/v1/colors/${id}`, payload)
    return response
  }

  @Api
  async deleteColor(id) {
    const response = await this.DELETE(`/v1/colors/${id}`)
    return response
  }

  @Api
  async getPlatformList(params) {
    const response = await this.GET(`/v1/platforms`, params)
    return response
  }

  @Api
  async getPlatformInfoById(id) {
    const response = await this.GET(`/v1/platforms/${id}`)
    return response
  }

  @Api
  async createPlatform(params) {
    const response = await this.POST(`/v1/platforms`, params)
    return response
  }

  @Api
  async updatePlatform(id, payload) {
    const response = await this.PUT(`/v1/platforms/${id}`, payload)
    return response
  }

  @Api
  async deletePlatform(id) {
    const response = await this.DELETE(`/v1/platforms/${id}`)
    return response
  }

  @Api
  async getPaymentList(params) {
    const response = await this.GET(`/v1/payments`, params)
    return response
  }

  @Api
  async getPaymentInfoById(id) {
    const response = await this.GET(`/v1/payments/${id}`)
    return response
  }

  @Api
  async createPayment(params) {
    const response = await this.POST(`/v1/payments`, params)
    return response
  }

  @Api
  async updatePayment(id, payload) {
    const response = await this.PUT(`/v1/payments/${id}`, payload)
    return response
  }

  @Api
  async deletePayment(id) {
    const response = await this.DELETE(`/v1/payments/${id}`)
    return response
  }

  @Api
  async getContactList(type) {
    const response = await this.GET(`/v2/contacts?user_type=${type}`)
    return response
  }

  @Api
  async getContactTypes() {
    const response = await this.GET(`/v1/contacts/types`)
    return response
  }

  @Api
  async getContactInfoById(id) {
    const response = await this.GET(`/v1/contacts/${id}`)
    return response
  }

  @Api
  async createContact(params) {
    const response = await this.POST(`/v1/contacts`, params)
    return response
  }

  @Api
  async updateContact(id, payload) {
    const response = await this.PUT(`/v2/contacts/${id}`, payload)
    return response
  }

  @Api
  async deleteContact(id) {
    const response = await this.DELETE(`/v1/contacts/${id}`)
    return response
  }

  @Api
  async getBrokers() {
    const response = await this.GET(`/v1/broker-partners`)
    return response
  }

  @Api
  async createBroker(payload) {
    const response = await this.POST(`/v1/broker-partners`, payload)
    return response
  }

  @Api
  async getBrokerById(id) {
    const response = await this.GET(`/v1/broker-partners/${id}`)
    return response
  }

  @Api
  async updateBroker(id, payload) {
    const response = await this.PUT(`/v1/broker-partners/${id}`, payload)
    return response
  }

  @Api
  async deleteBroker(id) {
    const response = await this.DELETE(`/v1/broker-partners/${id}`)
    return response
  }
}
