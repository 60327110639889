<template>
  <a-modal
    :visible="modalVisible"
    @cancel="onCancel"
    title="Обновление категории"
    cancel-text="Отмена"
    :footer="null"
  >
    <a-tabs default-active-key="1" v-if="selectedItem">
      <a-tab-pane key="1" tab="Переименование">
        <a-form-model
          ref="form"
          :model="title"
          :rules="rules"
          @submit.prevent="validateFields"
        >
          <a-form-model-item label="Название категории (ru)" prop="ru">
            <a-input
              :max-length="60"
              :placeholder="selectedItem.title.ru"
              v-model="title.ru"
            />
          </a-form-model-item>
          <a-form-model-item label="Название категории (kz)" prop="kz">
            <a-input
              :max-length="60"
              :placeholder="selectedItem.title.kz"
              v-model="title.kz"
            />
          </a-form-model-item>
          <a-button type="primary" html-type="submit"> Сохранить </a-button>
        </a-form-model>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Перенос" v-if="showCategory">
        <a-form-model @submit.prevent="onUpdateCategory">
          <div class="dd-wrap">
            <a-select
              style="width: 240px"
              @change="handleParentCategoryChange"
              v-model="lvl_1"
            >
              <a-select-option
                v-for="item in categoryTree"
                :key="item.slug"
                :value="item.slug"
              >
                {{ item.title.ru }}
              </a-select-option>
            </a-select>

            <template v-if="isThirdCategory">
              <div><a-icon type="right" /></div>
              <a-select v-model="lvl_2" style="width: 240px">
                <a-select-option
                  v-for="item in lvl_2_list"
                  :key="item.slug"
                  :value="item.slug"
                >
                  {{ item.title.ru }}
                </a-select-option>
              </a-select>
            </template>
          </div>
          <a-form-model-item>
            <a-button type="primary" html-type="submit">Сохранить</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Управление фильтрацией" v-if="isThirdCategory">
        <a-form-model
          ref="categoryFilterForm"
          :model="categoryFilterForm"
          @submit="changeCategoryFilters"
        >
          <a-form-model-item label="Исключенные фильтры">
            <a-checkbox-group
              v-model="categoryFilterForm.filter_names"
              :options="filterNamesList"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" html-type="submit">Сохранить</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  name: 'CategoryEditModal',
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    activeLabel: {
      type: String,
    },
    showCategory: {
      type: Boolean,
      default: false,
    },
    selectedItem: {
      type: Object,
    },
    categoryTree: {
      type: Array,
    },
    isThirdCategory: {
      type: Boolean,
    },
  },
  watch: {
    modalVisible() {
      this.title.ru = this.selectedItem.title.ru
      this.title.kz = this.selectedItem.title.kz
      if (this.showCategory) {
        if (this.isThirdCategory) {
          this.lvl_1 = this.activeLabel
          this.lvl_2 = this.selectedItem.parent.slug
          this.lvl_2_list = this.getLvl2List(this.lvl_2)
          this.categoryFilterForm.filter_names = this.selectedItem[
            'filter-exclude'
          ]
            ? this.selectedItem['filter-exclude']
            : []
          this.categoryFilterForm.slug = this.selectedItem.slug
        } else {
          this.lvl_1 = this.selectedItem.parent.slug
        }
      }
    },
  },
  data() {
    return {
      lvl_1: '',
      lvl_2: '',
      title: {
        ru: '',
        kz: '',
      },
      rules: {
        ru: {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          min: 2,
          max: 60,
        },
        kz: {
          type: 'string',
          message: 'Заполните поле корректно',
          required: true,
          min: 2,
          max: 60,
        },
      },
      lvl_2_list: [],
      categoryFilterForm: {
        entity: 'category',
        filter_names: [],
        slug: '',
      },
      filterNamesList: [
        {
          label: 'Цвет',
          value: 'color',
        },
      ],
    }
  },
  methods: {
    onCancel() {
      this.$emit('onCancel')
    },
    async validateFields() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            await this.onUpdate()
          } catch (error) {
            showErrorMsg(error.message)
          }
        } else {
          showErrorMsg('Заполните все обязательные поля')
          return false
        }
      })
    },
    async onUpdate() {
      try {
        await this.$API.Storefront.updateCategoryName(
          this.selectedItem.slug,
          this.title,
        )
        this.$emit('onCancel', true)
      } catch (e) {
        showErrorMsg(e.message)
      } finally {
        this.$emit('onCancel')
      }
    },
    async onUpdateCategory() {
      try {
        let current = []
        let target = []

        current.push(this.activeLabel)
        target.push(this.lvl_1)

        if (this.isThirdCategory) {
          current.push(this.selectedItem.parent.slug)
          current.push(this.selectedItem.slug)
          target.push(this.lvl_2)
        } else {
          current.push(this.selectedItem.slug)
        }

        await this.$API.Storefront.categoryTransfer({
          current: current,
          target: target,
        })
        this.$emit('onCancel', true)
      } catch (e) {
        showErrorMsg(e.message)
      } finally {
        this.$emit('onCancel')
      }
    },
    handleParentCategoryChange() {
      const list = this.categoryTree.find(
        ({ slug }) => slug === this.lvl_1,
      ).children
      this.lvl_2_list = list
      this.lvl_2 = list[0].slug
    },
    async changeCategoryFilters(event) {
      event.preventDefault()
      try {
        this.isLoading = true
        await this.$API.Storefront.excludeFilter(this.categoryFilterForm)
        showSuccessMsg('Фильтрация успешно изменена')
        this.$emit('onCancel', true)
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    getLvl2List() {
      return this.categoryTree.find(({ slug }) => slug === this.activeLabel)
        .children
    },
  },
}
</script>

<style scoped>
.dd-wrap {
  display: flex;
  align-items: center;
}
</style>
