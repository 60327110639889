var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"settings-wrap"},[_c('h2',[_vm._v("Список баннеров")]),_c('a-button',{on:{"click":_vm.gotoCreatePage}},[_vm._v("Создать новый баннер")])],1),_c('a-form-model',{ref:"form",attrs:{"layout":"inline","model":_vm.queryParams},on:{"submit":function($event){$event.preventDefault();return _vm.getBannersList.apply(null, arguments)}}},[_c('div',{staticClass:"settings-wrap"},[_c('a-form-model-item',{attrs:{"label":"Позиция"}},[_c('a-radio-group',{attrs:{"default-value":null,"button-style":"solid"},on:{"change":_vm.getBannersList},model:{value:(_vm.queryParams.position),callback:function ($$v) {_vm.$set(_vm.queryParams, "position", $$v)},expression:"queryParams.position"}},_vm._l((_vm.positionTypes),function(item,index){return _c('a-radio-button',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),_c('a-form-model-item',{attrs:{"label":"Активный"}},[_c('a-radio-group',{attrs:{"default-value":true,"button-style":"solid"},on:{"change":_vm.getBannersList},model:{value:(_vm.queryParams.active),callback:function ($$v) {_vm.$set(_vm.queryParams, "active", $$v)},expression:"queryParams.active"}},[_c('a-radio-button',{attrs:{"value":null}},[_vm._v("Все")]),_c('a-radio-button',{attrs:{"value":true}},[_vm._v("Активный")]),_c('a-radio-button',{attrs:{"value":false}},[_vm._v("Неактивный")])],1)],1)],1)]),_c('a-table',{attrs:{"columns":_vm.bannersColumns,"data-source":_vm.bannerList,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
        var id = ref.id;

        return id;
},"pagination":_vm.pagination,"loading":_vm.isLoading,"bordered":""},on:{"change":_vm.onPageChange},scopedSlots:_vm._u([{key:"id",fn:function(id){return _c('div',{},[_c('router-link',{attrs:{"to":{
          name: 'BannerEdit',
          params: {
            id: id,
          },
        }}},[_vm._v(" "+_vm._s(_vm._f("shortID")(id,5))+" ")])],1)}},{key:"info",fn:function(info, record){return _c('div',{},[_c('h4',[_vm._v(_vm._s(info))]),_c('p',{staticClass:"info"},[_vm._v(_vm._s(record.info.text))])])}},{key:"position",fn:function(position){return _c('div',{},[_vm._v(" "+_vm._s(_vm.$t(("common." + position)))+" ")])}},{key:"image",fn:function(image, banner){return _c('div',{},[_c('img',{staticClass:"img-prev",attrs:{"src":image,"alt":banner.alt,"width":"170"}})])}},{key:"type",fn:function(type){return _c('div',{},_vm._l((type),function(item){return _c('a-tag',{key:item},[_vm._v(_vm._s(item))])}),1)}},{key:"active",fn:function(active, record){return _c('div',{},[(active)?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.statusToggleHandler(record, active)}}},[_c('a-icon',{attrs:{"type":"check-circle"}})],1):_c('a-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.statusToggleHandler(record, active)}}},[_c('a-icon',{attrs:{"type":"stop"}})],1)],1)}},{key:"url",fn:function(url){return _c('div',{},[_c('a',{attrs:{"href":url,"target":"_blank"}},[_vm._v(_vm._s(_vm._f("shortID")(url,10)))])])}},{key:"weight",fn:function(weight, banner){return _c('div',{},[_c('a-input-number',{attrs:{"id":banner.id,"value":weight,"min":0},on:{"change":function (value) { return _vm.onWeightChange(value, banner); }}}),_c('a-button',{staticClass:"form__button",attrs:{"type":"primary"},on:{"click":function($event){return _vm.confirmWeightChange(banner)}}},[_c('a-icon',{attrs:{"type":"save"}})],1)],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }