export const columnNames = [
  {
    title: 'ID партнера',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
    align: 'right',
  },
  {
    title: 'Контактное лицо',
    dataIndex: 'contact_info',
    key: 'contact_info',
    width: '250px',
    scopedSlots: { customRender: 'fullName' },
  },
  {
    title: 'Номер телефона',
    dataIndex: 'contact_info.phone',
    key: 'contact_info.phone',
  },
  {
    title: 'Название Юр. лица',
    dataIndex: 'company_info.company_name',
    width: '250px',
    key: 'company_info.company_name',
  },
  {
    title: 'Название Магазина',
    dataIndex: 'store_name',
    width: '250px',
    key: 'store_name',
  },
  {
    title: 'ИИН / Бин Юр. лица',
    dataIndex: 'company_info.payment_requisites.bin',
    key: 'company_info.payment_requisites.bin.bin',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Менеджер',
    dataIndex: 'account_manager',
    key: 'account_manager',
    scopedSlots: { customRender: 'account_manager' },
  },
  {
    title: 'Дата регистрации',
    dataIndex: 'created',
    key: 'created',
    scopedSlots: { customRender: 'createdAt' },
  },
]
