<template>
  <div class="requester">
    <div v-if="shouldShowSpinner" class="requester__spin-wrapper">
      <a-spin size="large" spinning />
    </div>
    <slot v-else />
  </div>
</template>

<script>
import cookie from 'js-cookie'
import { showErrorMsg } from 'Core/utils'
import './requester.scss'

export default {
  name: 'Requester',
  data() {
    return {
      isFetching: false,
    }
  },
  props: {
    isInnerPage: Boolean,
  },
  computed: {
    shouldShowSpinner() {
      return this.isInnerPage && this.isFetching
    },
  },
  methods: {
    async initialize() {
      try {
        this.isFetching = true
        const isLoggedIn = cookie.get('accessToken')
        await this.$store.dispatch('core/updateCities')
        if (isLoggedIn) {
          await Promise.all([
            this.$store.dispatch('user/updateProfile'),
            this.$store.dispatch('core/updateCities'),
            this.$store.dispatch('user/updatePermissions'),
            this.$store.dispatch('config/colors/fetchColors'),
            this.$store.dispatch('config/platforms/fetchPlatforms'),
            this.$store.dispatch('config/contacts/fetchContacts', 'user'),
            this.$store.dispatch('config/payments/fetchPayments'),
            this.$store.dispatch('config/brokers/fetchBrokers'),
            this.$store.dispatch('config/categories/fetchCategoryTree'),
          ])
        }
      } catch (error) {
        showErrorMsg(error?.message || error)
      } finally {
        this.isFetching = false
      }
    },
  },

  mounted() {
    if (this.isInnerPage) {
      this.initialize()
    }
  },
}
</script>
