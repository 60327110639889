<template>
  <div class="list-def-wrap" style="display: flex; align-content: flex-start">
    <slot></slot>
    <div>
      <template v-if="nodes">
        <vnode-renderer :nodes="nodes" />
      </template>
      <template v-else>
        {{ title }}
      </template>
      <slot name="post-title"></slot>
    </div>
    <div v-if="hasChild" class="ml-auto">
      <a-icon type="right" />
    </div>
  </div>
</template>

<script>
import VnodeRenderer from 'Core/components/VNodeRenderer/VnodeRenderer'
export default {
  name: 'ListItem',
  components: {
    VnodeRenderer,
  },
  props: {
    hasChild: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    nodes: {
      type: Object,
    },
  },
}
</script>

<style scoped lang="scss">
.list-def-wrap {
  display: flex;
  //justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 2px solid blanchedalmond;

  &:hover {
    color: #73be6f;
  }
}

.ml-auto {
  margin-left: auto;
}
</style>
