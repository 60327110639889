import { AxiosResponse } from 'axios'
import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис работы с пользовательским профилем
 * <br> Swagger
 * <br> {@link https://admin-api.test.airba.dev/merchant-profile/swagger/index.html#/application } */
export class MerchantProfileAdminService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'merchant-profile',
    })
  }

  @Api
  async getApplicationsList(payload) {
    const response = await this.GET(`/v1/applications`, payload)
    return response
  }
  @Api
  async removeApplication(applicationId: string) {
    const response = await this.DELETE(`/v1/applications/${applicationId}`)
    return response
  }
  @Api
  async updateProfile(id, params) {
    const response = await this.PATCH(`/v1/merchants/${id}`, params)
    return response
  }

  @Api
  async getEmployeeDocumentList(merchant_id) {
    const response = await this.GET(`/v1/merchants/${merchant_id}/documents`)
    return response
  }
}
