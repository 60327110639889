



































import { Component, Vue } from 'vue-property-decorator'
import { rules, formState } from './constants'
import { FormModel } from 'ant-design-vue'
import { showErrorMsg, showSuccessMsg } from '@/Core/utils'

@Component
class UploadSKU extends Vue {
  formState = formState
  rules = rules
  isLoading = false
  $API: any

  $refs: {
    form: FormModel
  }

  handleChange(info) {
    this.formState.files = info.fileList
  }

  onRemoveFile() {
    this.formState.files = []
    return true
  }

  async onUpload() {
    this.$refs.form.validate(async (valid) => {
      if (!valid) {
        showErrorMsg('Заполните все поля')
        return
      }

      try {
        this.isLoading = true
        const formData = new FormData()
        const file = this.formState.files[0].originFileObj
        formData.append('file', file, file?.name)

        const results = await Promise.allSettled([
          this.uploadFileToTDStealer(file.name, formData),
          this.uploadFileToKaspiStealer(file.name, formData),
          this.uploadFileToOzonStealer(file.name, formData),
          this.uploadFileToMarwinStealer(file.name, formData),
        ])

        const errors: string[] = results
          .filter((p) => p.status === 'rejected')
          .map((p: any) => p.reason?.message)

        if (errors?.length) {
          errors.forEach((e) => showErrorMsg(e))
        }
        this.onRemoveFile()
      } catch (e) {
        showErrorMsg(e.message)
      } finally {
        this.isLoading = false
      }
    })
  }

  async uploadFileToTDStealer(fileName: string, formData: FormData) {
    await this.$API.TDStealer.uploadFileTD(fileName, formData)
    showSuccessMsg('Файл успешно загружен в Technodom stealer')
  }

  async uploadFileToKaspiStealer(fileName: string, formData: FormData) {
    await this.$API.KaspiStealer.uploadFileKaspi(fileName, formData)
    showSuccessMsg('Файл успешно загружен в Kaspi stealer')
  }

  async uploadFileToOzonStealer(fileName: string, formData: FormData) {
    await this.$API.OzonStealer.uploadFileOzon(fileName, formData)
    showSuccessMsg('Файл успешно загружен в Ozon stealer')
  }

  async uploadFileToMarwinStealer(fileName: string, formData: FormData) {
    await this.$API.MarwinStealer.uploadFileMarwin(fileName, formData)
    showSuccessMsg('Файл успешно загружен в Marwin stealer')
  }
}

export default UploadSKU
