import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис маппинга товаров с Marwin
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/marwin-stealer/swagger/index.html} */
export class MarwinStealerService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'marwin-stealer',
    })
  }

  @Api
  async uploadFileMarwin(fileName: string, file) {
    const response = await this.POST(
      `/v1/marwin-stealer/market-file/${fileName}`,
      file,
    )
    return response
  }
}
