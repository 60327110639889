import { POST } from 'Main/api/REST'

const baseUrl = process.env.VUE_APP_API_URL
export const saveImageAPI = async (formData) => {
  //todo more elegant way to define folder name for test/prod/stage modes
  const folderName = baseUrl.includes('api.airba.kz')
    ? 'f3-airba-marketplace-prod'
    : 'test-marketplace-f3'
  const { success, data } = await POST(
    // слэш в конце урла обязателен
    `${baseUrl}/f3/api/v1/${folderName}/`,
    formData,
  )
  if (!success) {
    throw new Error(`Ошибка при сохранении: ${data?.error?.message}`)
  }
  return data
}

export const uploadJSONAPI = async (imageObject) => {
  const folderName = baseUrl.includes('api.airba.kz')
    ? 'f3-airba-marketplace-prod'
    : 'test-marketplace-f3'
  const { success, data } = await POST(
    // слэш в конце урла обязателен
    `${baseUrl}/f3/api/v1/${folderName}/parse/`,
    imageObject,
  )
  if (!success) {
    throw new Error(`Ошибка при сохранении: ${data?.error?.message}`)
  }
  return data
}
