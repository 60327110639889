<template>
  <div>
    <div class="settings-wrap">
      <a-steps v-model="current" type="navigation">
        <a-step title="Выбор базовых продуктов" />
        <a-step :disabled="!list.length" title="Выбор категории" />
      </a-steps>
    </div>
    <template v-if="current === 0">
      <a-form-model ref="form" @submit.prevent="handleValue">
        <a-form-model-item label="Вставьте ссылку/ссылки товара">
          <a-textarea
            style="width: 700px"
            allow-clear
            v-model="localValue"
            :auto-size="{ minRows: 6, maxRows: 12 }"
          />
        </a-form-model-item>
        <a-button html-type="submit" type="primary"> Добавить </a-button>
      </a-form-model>

      <div class="settings-wrap">
        <div class="list-wrap">
          <div v-for="(sku, idx) in list" :key="sku">
            <a-tag closable @close="removeFromList(idx)">
              ску: <code class="list-wrap__item">{{ sku }}</code>
            </a-tag>
          </div>
        </div>
      </div>
      <a-button v-if="list.length" @click="current = 1">Следующий шаг</a-button>
    </template>

    <template v-if="current === 1">
      <a-form-model-item prop="categories" label="Категория">
        <a-input v-model="categories" disabled></a-input>
      </a-form-model-item>
      <category-selector
        :category-parent="categoryParent"
        @setCategory="setCategory"
        :show-create-new="false"
      />
      <a-button v-if="categoryState.length" @click="saveCategoryHandler">
        Сохранить
      </a-button>
    </template>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import CategorySelector from 'Product/components/CategorySelector'

export default {
  name: 'CategoryUpdate',
  data() {
    return {
      current: 0,
      localValue: '',
      categories: '',
      list: [],
      categoryParent: [],
      categoryState: [],
    }
  },
  components: {
    CategorySelector,
  },
  methods: {
    async initialize() {
      this.categoryParent = await this.$API.Storefront.getCategoryList({
        level: 1,
      })
    },
    handleValue() {
      const linkList = this.localValue.split('\n')
      linkList.forEach((link) => {
        const tempArr = link.split('-')
        const sku = tempArr[tempArr.length - 1]
        if (Number(sku) && sku) {
          if (this.list.indexOf(sku) === -1) {
            this.list.push(sku)
            showSuccessMsg(`${sku} добавлен в список обновлении.`)
          }
        } else {
          showErrorMsg(`${link} не валидна. Не удалось получить ску товара`)
        }
      })
      this.localValue = ''
    },
    removeFromList(idx) {
      this.list.splice(idx, 1)
    },
    setCategory(category) {
      this.categoryState = category
      this.categories = `${category[0].title.ru} > ${category[1].title.ru} > ${category[2].title.ru}`
    },
    async saveCategoryHandler() {
      try {
        const categories = this.categoryState.map(({ slug }) => slug)
        await this.$API.Storefront.updateCategoryForSkuList({
          skus: this.list,
          categories: categories,
        })
        showSuccessMsg('Категории обновлены!')
        this.current = 0
        this.list = []
        this.categoryParent = []
        this.categoryState = []
        this.categories = ''
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped>
.list-wrap {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
}
.ant-tag {
  margin: 0 5px 5px 0;
  padding: 5px 7px;
}
.list-wrap__item {
  font-weight: bold;
  font-size: 1rem;
}
</style>
