<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col span="20">
        <a-row class="mb-24" type="flex" justify="space-between">
          <a-col>
            <h2>Создание атрибута</h2>
          </a-col>
          <a-col>
            <a-button
              @click="
                () =>
                  $router.push({
                    name: 'AttributeList',
                    params: { id: modelId },
                  })
              "
            >
              {{ $t('common.back') }}
            </a-button>
          </a-col>
        </a-row>
        <a-form-model
          ref="form"
          :model="formState"
          :rules="rules"
          @submit="onCreate"
        >
          <a-form-model-item
            prop="description.ru"
            label="Описание атрибута (RU)"
          >
            <a-input v-model="formState.description.ru" />
          </a-form-model-item>
          <a-form-model-item
            prop="description.kz"
            label="Описание атрибута (KZ)"
          >
            <a-input v-model="formState.description.kz" />
          </a-form-model-item>
          <a-row type="flex" justify="space-between">
            <a-form-item label="Участвует в фильтре">
              <a-switch v-model="formState.is_filter" />
            </a-form-item>
            <a-form-item label="У атрибута несколько значении">
              <a-switch v-model="formState.is_collection" />
            </a-form-item>
            <a-form-item label="В short description">
              <a-switch v-model="formState.is_description" />
            </a-form-item>
            <a-form-item label="Обязательный атрибут">
              <a-switch v-model="formState.is_required" />
            </a-form-item>
            <a-form-item label="Вариативный атрибут">
              <a-switch v-model="formState.is_variable" />
            </a-form-item>
          </a-row>
          <a-form-model-item prop="group_id" label="Группа">
            <a-select v-model="formState.group_id">
              <a-select-option
                v-for="(group, index) in groupList"
                :value="group.id"
                :key="index"
              >
                {{ group.title.ru }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="title.ru" label="Название атрибута (RU)">
            <a-input v-model="formState.title.ru" />
          </a-form-model-item>
          <a-form-model-item prop="title.kz" label="Название атрибута (KZ)">
            <a-input v-model="formState.title.kz" />
          </a-form-model-item>
          <a-form-model-item label="Тип значения">
            <a-radio-group v-model="formState.type">
              <a-radio-button value="string"> Текстовое поле</a-radio-button>
              <a-radio-button value="integer"> Целые числа</a-radio-button>
              <a-radio-button value="decimal"> Дробные числа</a-radio-button>
              <a-radio-button value="url"> Ссылка</a-radio-button>
              <a-radio-button value="multiline">
                Строка в несколько строк
              </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            prop="weight"
            label="Вес атрибута (для приоритетности)"
          >
            <a-input-number v-model="formState.weight" :min="0" />
          </a-form-model-item>

          <a-form-item label="Значение атрибута выбирается из списка">
            <a-switch v-model="formState.has_options" />
          </a-form-item>
          <template v-if="formState.has_options">
            <a-divider>Заполните возможные опции</a-divider>
            <div v-for="(item, index) in optionsState" :key="index">
              <a-form-model-item label="Описание опции для контент-менеджера">
                <a-input v-model="item.description" />
              </a-form-model-item>
              <a-row :gutter="16">
                <a-col :span="10">
                  <a-form-model-item label="Название атрибута (RU)">
                    <a-input v-model="item.value.ru" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="10">
                  <a-form-model-item label="Название атрибута (KZ)">
                    <a-input v-model="item.value.kz" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="2">
                  <a-button
                    v-if="optionsState.length > 1"
                    type="danger"
                    shape="circle"
                    @click="deleteOptionByIndex(index)"
                  >
                    <a-icon type="close" />
                  </a-button>
                </a-col>
              </a-row>
              <a-divider></a-divider>
            </div>
            <a-form-model-item>
              <a-button type="link" @click="enlargeOptions()">
                Добавить еще
              </a-button>
            </a-form-model-item>
          </template>
          <a-form-item>
            <a-button type="primary" html-type="submit">Создать</a-button>
          </a-form-item>
        </a-form-model>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { formState, rules, optionsState } from './constants'
import { showErrorMsg } from 'Core/utils'

export default {
  name: 'AttributeCreate',
  data: () => ({
    formState,
    optionsState,
    rules,
    groupList: [],
    isInUse: false,
  }),
  methods: {
    enlargeOptions() {
      this.optionsState.push({
        description: null,
        value: {
          ru: null,
          kz: null,
        },
      })
    },
    deleteOptionByIndex(index) {
      this.optionsState.splice(index, 1)
    },
    onCreate(event) {
      event.preventDefault()
      this.validateFields()
    },
    async validateFields() {
      try {
        await this.$refs.form.validate()
        await this.createAttributeHandler()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async createAttributeHandler() {
      if (this.formState.has_options) {
        this.formState = { ...this.formState, options: this.optionsState }
      }
      const { id } = await this.$API.Storefront.createAttribute(
        this.modelId,
        this.formState,
      )
      await this.$router.push({
        name: 'AttributeEdit',
        params: { modelId: this.modelId, id: id },
      })
    },
    async initialize() {
      try {
        this.isInUse = !!(
          await this.$API.Storefront.getModelDependencyCount(this.modelId)
        ).count
        this.groupList = await this.$API.Storefront.getModelAttributeGroupList(
          this.modelId,
        )
        this.formState.group_id = this.groupList[0]?.id
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
  computed: {
    modelId() {
      return this.$route.params.id
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped></style>
