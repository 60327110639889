import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'
import {
  ICancelProductParams,
  IUnconfirmedOrdersParams,
  IUnconfirmedOrdersResponse,
} from 'Core/modules/API/@types/OMS'

/**
 *  Сервис управления заказами
 *   <br> Swagger
 *   <br> {@link https://api.mp-test.airba.dev/oms/swagger/buyer/index.html}
 *   <br> {@link https://api.mp-test.airba.dev/oms/swagger/merchant/index.html}
 *   <br> {@link https://api.mp-test.airba.dev/oms/swagger/admin/index.html}
 *   <br> {@link https://api.mp-test.airba.dev/oms/swagger/thirdparty/index.html} */
export class OMSService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'oms',
    })
  }

  @Api
  async getMerchantOrderList(params) {
    const response = await this.GET(`/v1/admin/orders/merchant-view`, params)
    return response
  }

  @Api
  async getMerchantOrderItemInfo(id) {
    const response = await this.GET(`/v1/admin/orders/merchant-view/${id}`)
    return response
  }

  @Api
  async setMerchantOrderStatus(mid, id, solution) {
    const response = await this.POST(`/v1/admin/orders/${mid}/${id}`, solution)
    return response
  }

  @Api
  async getOrderList(params) {
    const response = await this.GET(`/v2/admin/orders`, params)
    return response
  }
  @Api
  async unloadOrders(params) {
    const response = await this.GET(`/v2/admin/orders/csv`, params)
    return response
  }
  @Api
  async getOrderItemInfo(id) {
    const response = await this.GET(`/v2/admin/orders/${id}`)
    return response
  }

  @Api
  async cancelProducts(id: string | number, payload: ICancelProductParams) {
    const response = await this.PUT(
      `/v1/admin/orders/${id}/products/cancel`,
      payload,
    )
    return response
  }

  @Api
  async getReportInfoList(filter) {
    const response = await this.GET(`/v1/admin/orders/reports`, filter)
    return response
  }

  @Api
  async setStatusToDelivered(id: string | number) {
    const response = await this.PUT(`/v1/admin/orders/${id}/products/delivered`)
    return response
  }

  @Api
  async getUnconfirmedOrders(
    params?: IUnconfirmedOrdersParams,
  ): Promise<IUnconfirmedOrdersResponse> {
    const response = await this.GET<IUnconfirmedOrdersResponse>(
      `/v1/admin/orders/merchant-view/unconfirmed`,
      params,
    )
    return response
  }

  @Api
  async linkOrderToEmployee(orderId: string) {
    const response = await this.PUT(
      `/v1/admin/orders/${orderId}/link-to-employee`,
    )
    return response
  }
}
