import { render, staticRenderFns } from "./AttributeEdit.vue?vue&type=template&id=1b19a4e7&scoped=true&"
import script from "./AttributeEdit.vue?vue&type=script&lang=js&"
export * from "./AttributeEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b19a4e7",
  null
  
)

export default component.exports