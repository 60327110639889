import { URL } from 'Core/modules/validators/url'
import { ALPHA } from 'Core/modules/validators/alpha'

export const formState: any = {
  _id: null,
  name: null,
  description: null,
  static_link: null,
  dynamic_link: null,
  data: null,
}

export const rules = {
  name: [
    {
      fullField: 'Наименование',
      required: true,
    },
    {
      validator: ALPHA,
      fullField: 'Наименование',
      langs: ['en'],
      numeric: true,
      specials: ['_'],
    },
  ],
  description: {
    type: 'string',
    required: true,
  },
  dynamic_link: [
    {
      fullField: 'Динамическая ссылка',
      required: true,
    },
    {
      validator: URL,
      fullField: 'Динамическая ссылка',
    },
  ],
}
