export const columnNames = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Номер заявки',
    dataIndex: 'request_id',
    key: 'request_id',
    scopedSlots: { customRender: 'request_id' },
  },
  {
    title: 'Номер заказа',
    dataIndex: 'order_id',
    key: 'order_id',
    scopedSlots: { customRender: 'order_id' },
  },
  {
    title: 'Название товара',
    dataIndex: 'title',
    key: 'title',
    scopedSlots: { customRender: 'title' },
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'slug',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Имя клиента',
    dataIndex: 'client_name',
    key: 'client_name',
    scopedSlots: { customRender: 'client_name' },
  },
  {
    title: 'Причина',
    dataIndex: 'reason',
    key: 'reason',
    scopedSlots: { customRender: 'reason' },
  },
  {
    title: 'Дата создания заявки',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'Тип платежа',
    dataIndex: 'payment_type',
    key: 'payment_type',
    scopedSlots: { customRender: 'payment_type' },
  },
]

export const RETURN_STATUSES_FILTER = {
  created: {
    value: 'created',
    label: 'На подтверждении',
    variant: 'blue',
    icon: 'thunderbolt',
  },
  on_moderation: {
    value: 'on_moderation',
    label: 'На модерации',
    variant: 'red',
    icon: 'search',
  },
  in_progress: {
    value: 'in_progress',
    label: 'На проверке у партнёра',
    variant: 'red',
    icon: 'question-circle',
  },
  waiting_for_delivery: {
    value: [
      'waiting_for_delivery',
      'delivery_to_client',
      'on_stock',
      'delivery_to_partner',
    ],
    label: 'Заказана доставка',
    variant: 'orange',
    icon: 'car',
  },
  finished: {
    value: 'finished',
    label: 'Возвращён',
    variant: 'red',
    icon: 'check',
  },
  rejected: {
    value: [
      'rejected_by_support',
      'rejected_by_merchant',
      'rejected_by_courier',
    ],
    label: 'Отклонён',
    variant: 'red',
    icon: 'stop',
  },
}
