














































































import { Component, Vue } from 'vue-property-decorator'
import {
  IUnconfirmedOrder,
  IUnconfirmedOrdersParams,
} from 'Core/modules/API/@types/OMS'
import { showErrorMsg } from '@/Core/utils'
import {
  durations,
  headers,
} from 'Order/pages/UnconfirmedOrders/UnconfirmedOrdersList/constants'
import formatDistance from 'date-fns/formatDistance'
import ruLocale from 'date-fns/locale/ru'

@Component
class UnconfirmedOrdersList extends Vue {
  orders: IUnconfirmedOrder[] = []
  isLoading = false
  durations = durations
  headers = headers
  intervalId: any = null

  filter: IUnconfirmedOrdersParams = {
    id: undefined,
    skipDuration: undefined,
    contactPhones: undefined,
  }

  pagination = {
    defaultPageSize: 10,
  }

  async getUnconfirmedOrders() {
    try {
      this.isLoading = true
      const { orders } = await this.$API.OMS.getUnconfirmedOrders(this.filter)
      this.orders = orders
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  dropFilter() {
    this.filter = {
      id: undefined,
      skipDuration: undefined,
      contactPhones: undefined,
    }
    this.getUnconfirmedOrders()
  }

  generateRedirectLink(order: IUnconfirmedOrder) {
    const isMerchant = order.id.split('-', 2).length > 1
    if (isMerchant) return `/#/partner/${order.merchant_id}/order/${order.id}`
    else return `/#/order/${order.id}`
  }

  getDifference(time) {
    return formatDistance(new Date(), new Date(time), {
      locale: ruLocale,
    })
  }

  mounted() {
    this.getUnconfirmedOrders()
    this.intervalId = setInterval(() => {
      this.getUnconfirmedOrders()
    }, 30000)
  }

  beforeDestroy() {
    if (this.intervalId) clearInterval(this.intervalId)
  }
}

export default UnconfirmedOrdersList
