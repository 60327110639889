import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'
import {AxiosInstance} from "axios";

export class MultisearchService{
    axios: AxiosInstance;
    constructor(axios) {
        this.axios = axios;
    }

    async search(params) {
        try{
            let response = await this.axios({
                url: '/',
                method: 'GET',
                params,
            });
            return response.data.results;
        }catch (error){
            throw new Error(
                `Произошла ошибка при поиске Multisearch`,
            )
        }
    }
}
