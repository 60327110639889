<template>
  <div class="parse-info">
    <div class="attr-info">
      <p class="title-label" v-clipboard:copy="title">
        {{ title }} <a-icon type="copy" />
      </p>
      <p class="parse-info__title">Общая заполненность:</p>
      <span :style="getInfoStyle">{{ fullnessPercent }}%</span>
      <code>{{
        ` (${this.attrListStateFullness.fulfilled}/${this.attrListStateFullness.length})`
      }}</code>
      <a-divider />
    </div>
    <div v-if="info.total > 0">
      <p class="parse-info__title">Результаты парсинга:</p>
      <div class="parse-info__total-info">
        <span class="parse-info__parsed">Получено: {{ info.total }};</span>
        <span class="parse-info__mapped">
          Сопоставлено: {{ info.mapped }} ({{ mappedPercent }}%)
        </span>
      </div>
      <ul class="parse-info__unmapped-list">
        <li
          class="parse-info__unmapped-item"
          v-for="(item, index) in info.items"
          :key="index"
        >
          <p class="parse-info__unmapped-title">{{ item.name }}</p>
          <template v-if="isMultipleValue(item.value)">
            <a-tag
              v-for="(spliceItem, spliceIndex) in item.value.split(',')"
              :key="spliceIndex"
              v-clipboard:copy="spliceItem"
              class="parse-info__margin"
            >
              <span class="parse-info__unmapped-value">
                {{ spliceItem }}
              </span>
            </a-tag>
          </template>
          <a-tag
            v-clipboard:copy="item.value"
            :color="isMultipleValue(item.value) ? 'blue' : ''"
          >
            <span class="parse-info__unmapped-value" :title="item.value">
              {{ item.value }}
            </span>
          </a-tag>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParsingInfo',
  props: {
    info: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
    },
    attrListStateFullness: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullnessPercent() {
      return (
        (this.attrListStateFullness.fulfilled * 100) /
        this.attrListStateFullness.length
      ).toFixed(0)
    },
    mappedPercent() {
      if (this.info.mapped && this.info.total) {
        return ((this.info.mapped * 100) / this.info.total).toFixed(1)
      } else {
        return ''
      }
    },

    getInfoStyle() {
      let color = ''
      const fullness = this.fullnessPercent
      switch (true) {
        case fullness < 30:
          color = 'darkred'
          break
        case fullness < 50:
          color = 'red'
          break
        case fullness < 70:
          color = 'orange'
          break
        case fullness < 80:
          color = 'green'
          break
        default:
          color = 'darkgreen'
          break
      }
      return {
        color: color,
      }
    },
  },
  methods: {
    isMultipleValue(value) {
      return value.indexOf(',') > -1
    },
  },
}
</script>

<style lang="scss" scoped>
.parse-info {
  background: #f1e9e9;
  position: fixed;
  right: 40px;
  top: 320px;
  width: 190px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 8px;

  &__title {
    margin-bottom: 4px;
    font-weight: bold;
  }

  &__parsed {
    color: #1f82b3;
    display: block;
  }

  &__mapped {
    color: #00805f;
    display: block;
  }

  &__total-info {
    margin-bottom: 12px;
  }

  &__unmapped-list {
    padding: 0;
    height: 400px;
    max-height: 400px;
    list-style: none;
    overflow: hidden;
    overflow-y: visible;
  }

  &__unmapped-item {
    margin-bottom: 8px;
  }

  &__unmapped-title {
    margin-bottom: 4px;
    font-weight: bold !important;
  }

  &__unmapped-value {
    margin-bottom: 0;
    font-weight: bold;
  }

  &__margin {
    margin-bottom: 4px;
  }
}
.title-label {
  cursor: pointer;
  &:hover {
    color: #161616;
  }
}
</style>
