import { render, staticRenderFns } from "./PromocodeEdit.vue?vue&type=template&id=5ad15762&scoped=true&"
import script from "./PromocodeEdit.vue?vue&type=script&lang=js&"
export * from "./PromocodeEdit.vue?vue&type=script&lang=js&"
import style0 from "./PromocodeEdit.vue?vue&type=style&index=0&id=5ad15762&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ad15762",
  null
  
)

export default component.exports