

































import { Component, Prop, Watch } from 'vue-property-decorator'
import MultipleBPCreationMixin from 'Content/mixins/MultipleBPCreation.mixin'
import { IContentDownloaderParams } from 'Core/modules/API/@types/ContentDownloader.type'
import { showErrorMsg } from '@/Core/utils'

@Component
class UploadedFileInfo extends MultipleBPCreationMixin {
  @Prop()
  uuid: string

  @Prop({
    type: String,
    default: 'create',
  })
  type: 'create' | 'update'

  errorsCount = 0
  successCount = 0
  queuedCount = 0

  isLoading = false

  @Watch('uuid')
  onFileUUIDChange(uuid) {
    if (!uuid) return
    this.initialize()
  }

  getPayload(state): IContentDownloaderParams {
    return {
      state,
      type: this.type,
      file_uuid: this.uuid,
    }
  }

  get readyToListing() {
    return this.uuid && this.queuedCount === 0
  }

  get totalCount() {
    return this.successCount + this.errorsCount + this.queuedCount
  }

  async initialize() {
    try {
      this.isLoading = true
      const queue = await this.getUploadsQueue(this.getPayload('in_queue'))
      if (queue) this.queuedCount = queue.total

      const success = await this.getCreatedProducts(this.getPayload('finished'))
      if (success) this.successCount = success.total

      const error = await this.getFailedProducts(this.getPayload('error'))
      if (error) this.errorsCount = error.total
    } catch (err) {
      showErrorMsg(err.message)
    } finally {
      this.isLoading = false
    }
  }

  openErrorPageList() {
    this.$router.push({
      name: 'InfoOnParsingList',
      params: { uuid: this.uuid },
    })
  }
}

export default UploadedFileInfo
