export const navRoutes = [
  {
    path: '/clients',
    name: 'Клиенты',
    icon: 'team',
    permission: {
      service: 'clients',
      permissions: ['client-view'],
    },
  },
  {
    path: '/partners',
    name: 'Партнеры',
    icon: 'solution',
    subMenu: [
      {
        path: '/partners',
        name: 'Все Партнеры',
        icon: 'solution',
        permission: {
          service: 'merchant-profile',
          permissions: ['merchant-view'],
        },
      },
      {
        path: '/applications',
        name: 'Лиды Регистрации',
        icon: 'team',
        permission: {
          service: 'merchant-profile',
          permissions: ['merchant-view'],
        },
      },
    ],
  },
  {
    name: 'Меню',
    icon: 'database',
    subMenu: [
      {
        path: '/fast_links',
        name: 'Группа быстрых ссылок',
        icon: 'link',
        permission: {
          service: 'menu',
          permissions: ['menu-view'],
        },
      },
      {
        path: '/nested-menu',
        name: 'Вложенное меню',
        icon: 'unordered-list',
        permission: {
          service: 'menu',
          permissions: ['menu-view'],
        },
      },
      {
        path: '/categories',
        name: 'Категории',
        icon: 'appstore',
        permission: {
          service: 'menu',
          permissions: ['menu-view'],
        },
      },
    ],
  },
  {
    name: 'Товары',
    icon: 'barcode',
    subMenu: [
      {
        path: '/models',
        name: 'Модели',
        icon: 'database',
        permission: {
          service: 'storefront',
          permissions: ['model-view'],
        },
      },
      {
        path: '/base-products',
        name: 'Базовые продукты',
        icon: 'book',
        permission: {
          service: 'storefront',
          permissions: ['base-product-view'],
        },
      },
      {
        path: '/base-product-groups',
        name: 'Группы базовых продуктов',
        icon: 'appstore',
        permission: {
          service: 'storefront',
          permissions: ['group-view'],
        },
      },
      {
        path: '/commission',
        name: 'Комиссии',
        icon: 'dollar',
        permission: {
          service: 'storefront',
          permissions: ['group-view'],
        },
      },
      {
        path: '/multiple-base-product-create',
        name: 'Массовое создание/обновление',
        icon: 'ordered-list',
        permission: {
          service: 'storefront',
          permissions: ['group-view'],
        },
      },
      {
        path: '/download-report',
        name: 'Выгрузка отчетов',
        icon: 'download',
        permission: {
          service: 'storefront',
          permissions: ['group-view'],
        },
      },
      {
        path: '/matched-sku',
        name: 'Сопоставленные sku',
        icon: 'block',
        permission: {
          service: 'storefront',
          permissions: ['group-view'],
        },
      },
    ],
  },
  {
    name: 'Маркетинг',
    icon: 'profile',
    subMenu: [
      {
        path: '/banner',
        name: 'Баннеры',
        icon: 'picture',
        permission: {
          service: 'banner',
          permissions: ['banner-view'],
        },
      },
      {
        path: '/promo',
        name: 'Промо страницы',
        icon: 'rocket',
        permission: {
          service: 'banner',
          permissions: ['banner-view'],
        },
      },
      {
        path: '/promocode',
        name: 'Промокоды',
        icon: 'container',
        permission: {
          service: 'promocode',
          permissions: ['specification-view'],
        },
      },
      {
        path: '/installment',
        name: 'Рассрочки',
        icon: 'idcard',
        permission: {
          service: 'payplan',
          permissions: ['installment-view'],
        },
      },
      {
        path: '/mailing-template',
        name: 'Шаблоны рассылок',
        icon: 'mail',
        permission: {
          service: 'jam',
          permissions: ['template-view'],
        },
      },
    ],
  },
  {
    name: 'Справочник',
    icon: 'solution',
    subMenu: [
      {
        path: '/config-colors',
        name: 'Настройка цветов',
        icon: 'picture',
        //temp privileges
        permission: {
          service: 'banner',
          permissions: ['banner-view'],
        },
      },
      {
        path: '/config-platforms',
        name: 'Список платформ',
        icon: 'picture', //temp privileges
        permission: {
          service: 'banner',
          permissions: ['banner-view'],
        },
      },
      {
        path: '/config-contacts',
        name: 'Настройка способов связи',
        icon: 'contacts',
        //temp privileges
        permission: {
          service: 'banner',
          permissions: ['banner-view'],
        },
      },
      {
        path: '/config-payment',
        name: 'Настройка оплаты',
        icon: 'credit-card',
        //temp privileges
        permission: {
          service: 'banner',
          permissions: ['banner-view'],
        },
      },
      {
        path: '/config-broker-partners',
        name: 'Список брокеров-партнеров',
        icon: 'credit-card',
        //temp privileges
        permission: {
          service: 'banner',
          permissions: ['banner-view'],
        },
      },
    ],
  },
  {
    name: 'Заказы',
    icon: 'shopping',
    subMenu: [
      {
        path: '/all-orders',
        name: 'Все заказы',
        icon: 'shopping-cart',
        permission: {
          service: 'oms',
          permissions: ['merchant-order-view'],
        },
      },
      {
        path: '/orders-by-click',
        name: 'Заказы в один клик',
        icon: 'coffee',
        permission: {
          service: 'oms',
          permissions: ['merchant-order-view'],
        },
      },
      {
        path: '/orders',
        name: 'Заказы',
        icon: 'shopping',
        permission: {
          service: 'oms',
          permissions: ['merchant-order-view'],
        },
      },
      {
        path: '/unconfirmed-orders',
        name: 'Ожидают подтверждения',
        icon: 'clock-circle',
        permission: {
          service: 'oms',
          permissions: ['merchant-order-view'],
        },
      },
      {
        path: '/returns',
        name: 'Заявки на возврат',
        icon: 'fall',
        permission: {
          service: 'oms',
          permissions: ['merchant-order-view'],
        },
      },
    ],
  },
  {
    name: 'QR коды',
    icon: 'qrcode',
    permission: {
      service: 'oms',
      permissions: ['merchant-order-view'],
    },
    subMenu: [
      {
        path: '/static-qr-codes',
        name: 'Статичные',
        icon: 'qrcode',
        permission: {
          service: 'storefront',
          permissions: ['model-view'],
        },
      },
      {
        path: '/dynamic-qr-codes',
        name: 'Динамические',
        icon: 'qrcode',
        permission: {
          service: 'storefront',
          permissions: ['model-view'],
        },
      },
    ],
  },
  {
    path: '/finances',
    name: 'Финансы',
    icon: 'dollar',
    permission: {
      service: 'oms',
      permissions: ['merchant-order-view'],
    },
  },
  {
    path: '/documents',
    name: 'Документы',
    icon: 'file-done',
    permission: {
      service: 'agree',
      permissions: ['document-view'],
    },
  },
  {
    name: 'FAQ(вопрос-ответ)',
    icon: 'alert',
    subMenu: [
      {
        path: '/faq/merchant',
        name: 'У партнеров',
        icon: 'link',
        permission: {
          service: 'menu',
          permissions: ['menu-view'],
        },
      },
      {
        path: '/faq/user',
        name: 'У клиентов',
        icon: 'unordered-list',
        permission: {
          service: 'menu',
          permissions: ['menu-view'],
        },
      },
    ],
  },
  {
    path: '/reviews',
    name: 'Отзывы',
    icon: 'form',
    permission: {
      service: 'feedback',
      permissions: ['product-review-view'],
    },
  },
  {
    name: 'Заявки',
    icon: 'file',
    permission: {
      service: 'feedback',
      permissions: ['product-review-view'],
    },
    subMenu: [
      {
        path: '/suggestion',
        name: 'Не нашли товар',
        icon: 'meh',
        permission: {
          service: 'storefront',
          permissions: ['model-view'],
        },
      },
      {
        path: '/price-cuts',
        name: 'Ожидающие снижение цен',
        icon: 'fall',
        permission: {
          service: 'storefront',
          permissions: ['model-view'],
        },
      },
      {
        path: '/notify-good-arrive',
        name: 'Сообщить о поступлении товара',
        icon: 'alert',
        permission: {
          service: 'storefront',
          permissions: ['model-view'],
        },
      },
    ],
  },
  {
    name: 'Настройки',
    icon: 'setting',
    subMenu: [
      {
        path: '/roles',
        name: 'Роли',
        icon: 'key',
        permission: {
          service: 'role',
          permissions: ['role-assign'],
        },
      },
      {
        path: '/users',
        name: 'Пользователи админки',
        icon: 'team',
        permission: {
          service: 'role',
          permissions: ['role-assign'],
        },
      },
    ],
  },
]
