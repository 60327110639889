


















import { Component, Vue } from 'vue-property-decorator'
import UploadCsv from 'Content/pages/MultipleBaseProductCreate/Tabs/UploadCSV/UploadCSV.vue'
import UpdateBaseProduct from 'Content/pages/MultipleBaseProductCreate/Tabs/UpdateBaseProduct/UpdateBaseProduct.vue'
import UploadSku from 'Content/pages/MultipleBaseProductCreate/Tabs/UploadSKU/UploadSKU.vue'

@Component({ components: { UploadCsv, UploadSku, UpdateBaseProduct } })
class MultipleBaseProductCreate extends Vue {}
export default MultipleBaseProductCreate
