<template>
  <div>
    <div class="settings-wrap">
      <h2>Список рассрочек</h2>
      <router-link
        :to="{ name: 'InstallmentCreate' }"
        v-can="{
          service: 'payplan',
          permissions: ['installment-create'],
        }"
      >
        <a-button>Создать новую рассрочку</a-button>
      </router-link>
    </div>
    <a-table
      :columns="installmentColumns"
      :data-source="installments"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :pagination="pagination"
      :loading="isLoading"
      @change="onPageChange"
      bordered
    >
      <div slot="naming" slot-scope="naming, record">
        <router-link
          :to="{
            name: 'InstallmentEdit',
            params: {
              installment_id: record.id,
            },
          }"
        >
          <template v-if="record.admin_title">
            {{ record.admin_title }}
          </template>
          <template v-else> {{ naming }} [old record] </template>
        </router-link>
      </div>
      <div slot="duration" slot-scope="duration">
        {{ duration }} месяца/месяцев
      </div>
      <div slot="commission" slot-scope="commission">
        {{ commission / 100 }}%
      </div>
      <div slot="start_at" slot-scope="start_at">
        {{ start_at && start_at | formatDate }}
      </div>
      <div slot="end_at" slot-scope="end_at">
        {{ end_at | formatDate }}
      </div>
      <div slot="isActive" slot-scope="isActive, record">
        <a-tag v-if="isOutdated(record.end_at)" color="red">
          Завершена ({{ record.end_at | formatDateISO }})
        </a-tag>
        <a-tag v-else-if="isActive" color="darkgreen">Запущена</a-tag>
        <a-tag v-else color="orange">Не активна</a-tag>
      </div>
    </a-table>
  </div>
</template>

<script>
import { showErrorMsg } from 'Core/utils'
import { installmentColumns } from './constants'
import isAfter from 'date-fns/isAfter'

export default {
  data() {
    return {
      installmentColumns,
      installments: [],
      isLoading: false,
      params: {
        limit: 10,
        page: 1,
      },
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 10,
      },
    }
  },
  created() {
    this.getInstallmentList()
  },
  methods: {
    async getInstallmentList() {
      try {
        this.isLoading = true
        const { data, total } = await this.$API.Payplan.getInstallmentList(
          this.params,
        )
        this.installments = data
        this.pagination.total = total
        this.isLoading = false
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    isOutdated(endDate) {
      return isAfter(new Date(), new Date(endDate))
    },
    async onPageChange({ current }) {
      this.params.page = this.pagination.current = current
      await this.getInstallmentList()
    },
  },
}
</script>

<style lang="scss" scoped></style>
