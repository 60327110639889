export const tableColumns = [
  {
    title: 'id',
    dataIndex: '_id',
    key: '_id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Описание',
    dataIndex: 'abbrev',
    key: 'abbrev',
    scopedSlots: { customRender: 'info' },
  },
  {
    title: 'Статичная ссылка',
    dataIndex: 'static_link',
    key: 'static_link',
    scopedSlots: { customRender: 'static_link' },
  },
  {
    title: 'Динамическая ссылка',
    dataIndex: 'dynamic_link',
    key: 'dynamic_link',
    scopedSlots: { customRender: 'dynamic_link' },
  },
  {
    title: 'QR',
    dataIndex: 'data',
    key: 'data',
    scopedSlots: { customRender: 'dataSrc' },
  },
]
