<template>
  <div>
    <a-form-model-item :label="label">
      <a-form-model ref="SynonymForm" @submit.prevent="addValueHandler">
        <a-input-group compact>
          <a-input v-model="inputText" style="width: 40%" />
          <a-button html-type="submit" type="primary" :disabled="!inputText">
            Добавить
          </a-button>
        </a-input-group>
        <div class="list-wrap">
          <div v-for="(tag, index) in localList" :key="index">
            <a-tag
              class="list-wrap__item"
              closable
              @close.prevent="removeValueHandler(index)"
            >
              {{ tag }}
            </a-tag>
          </div>
        </div>
      </a-form-model>
    </a-form-model-item>
  </div>
</template>

<script>
export default {
  name: 'UniqueList',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: [],
  },
  data() {
    return {
      localList: this.value,
      inputText: '',
    }
  },
  methods: {
    addValueHandler() {
      const value = this.inputText.trim()
      const idx = this.localList.indexOf(value)
      if (idx === -1) {
        this.localList.push(value)
        this.$emit('input', this.localList)
      }
      this.inputText = ''
    },
    removeValueHandler(idx) {
      this.localList.splice(idx, 1)
      this.$emit('input', this.localList)
    },
  },
}
</script>

<style scoped>
.list-wrap {
  display: flex;
  margin: 15px 0;
  flex-wrap: wrap;
}
.list-wrap__item {
  padding: 5px 12px;
  font-size: 1rem;
  margin: 0 10px 10px 0;
}
</style>
