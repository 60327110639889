export const common = {
  status: 'Статус',
  title: 'Название',
  country: 'Страна',
  coordinates: 'Координаты',
  latitude: 'Широта',
  longitude: 'Долгота',
  lastUpdate: 'Последнее обновление',
  actions: 'Действия',
  back: 'Назад',
  create: 'Создать',
  update: 'Обновить',
  areYouSure: 'Вы уверены?',
  yes: 'Да',
  no: 'Нет',
  search: 'Поиск',
  notFound: 'Страница, на которую вы пытаетесь попасть, не существует',
  toMain: 'На главную',
  top: 'На верху',
  bottom: 'Внизу',
  middle: 'По середине',
  menu: 'В категориях(меню)',
  left: 'Слева',
  right: 'Справа',
}
