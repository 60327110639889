<template>
  <div>
    <div class="settings-wrap">
      <h2>Информация о заказе #{{ orderInfo.id }}</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'OrdersList',
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <a-spin :spinning="OrderInfoIsLoading">
      <a-descriptions
        :column="8"
        class="description-padding"
        title="Информация о заказе"
        bordered
        layout="vertical"
      >
        <a-descriptions-item label="Номер заказа">
          {{ orderInfo.id }}
        </a-descriptions-item>
        <a-descriptions-item label="Заказы продавцов">
          <ul>
            <li v-for="(item, index) in orderInfo.sub_orders" :key="index">
              <router-link
                :to="{
                  name: 'PartnerOrderInfo',
                  params: { id: item.merchant_id, orderId: item.id },
                }"
              >
                {{ item.merchant_title }} - {{ item.id }}
              </router-link>
            </li>
          </ul>
        </a-descriptions-item>
        <a-descriptions-item label="Тип заказа">
          <template v-if="orderInfo.dividable"> Делимый заказ</template>
          <template v-else> Неделимый заказ</template>
        </a-descriptions-item>
        <a-descriptions-item label="Статус заказа">
          <history-order :status="orderInfo.status" :order-id="orderId" />
        </a-descriptions-item>

        <a-descriptions-item label="Исполнитель">
          <div v-if="orderInfo.employee && orderInfo.employee.full_name">
            {{ orderInfo.employee.full_name }}
          </div>
          <a-button v-else @click="onLinkOrderToEmployee">
            Взять в работу
          </a-button>
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions
        :column="8"
        class="description-padding"
        title="Информация об оплате"
        bordered
        layout="vertical"
      >
        <a-descriptions-item label="ID Airba Pay">
          <div>{{ orderInfo.payment_id }}</div>
        </a-descriptions-item>
        <a-descriptions-item label="Способ оплаты">
          <template v-if="PaymentTypes[orderInfo.payment_type]">
            <a-icon :type="PaymentTypes[orderInfo.payment_type].icon"></a-icon>
            {{ PaymentTypes[orderInfo.payment_type].label }}
          </template>
          <template v-else>
            Неизвестный способ оплаты [{{ orderInfo.payment_type }}]
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="Статус оплаты">
          <status-view
            :status-list="PAYMENT_STATUSES"
            :status="orderInfo.payment_status"
          ></status-view>
        </a-descriptions-item>
        <a-descriptions-item label="Сумма заказа">
          {{ orderInfo.total_price | currency }}
        </a-descriptions-item>
        <template v-if="orderInfo.PromoCodeDiscountPrice > 0">
          <a-descriptions-item label="Промокод">
            {{ orderInfo.PromoCodeDiscountPrice | currency }}
          </a-descriptions-item>
          <a-descriptions-item label="Итого">
            {{ orderInfo.DiscountedTotalPrice | currency }}
          </a-descriptions-item>
        </template>
      </a-descriptions>
      <a-collapse class="description-padding">
        <a-collapse-panel key="1" header="Информация о клиенте">
          <a-descriptions bordered layout="vertical">
            <a-descriptions-item label="ФИО клиента">
              {{ orderInfo.customer.full_name }}
            </a-descriptions-item>
            <a-descriptions-item label="Email">
              {{ orderInfo.customer.email }}
            </a-descriptions-item>
            <a-descriptions-item label="Контактные номера">
              {{ orderInfo.customer.phone }}
            </a-descriptions-item>
          </a-descriptions>
        </a-collapse-panel>
        <a-collapse-panel key="2" header="Информация о доставке">
          <a-descriptions bordered layout="vertical">
            <a-descriptions-item label="ФИО получателя" span="2">
              {{ orderInfo.recipient.full_name }}
            </a-descriptions-item>
            <a-descriptions-item label="Телефон получателя">
              {{ orderInfo.recipient.phone }}
            </a-descriptions-item>
            <a-descriptions-item label="Адрес" span="3">
              <p>{{ orderInfo.address }}</p>
            </a-descriptions-item>
            <a-descriptions-item span="3" label="Комментарий">
              <p class="order-comment">{{ orderInfo.comment }}</p>
            </a-descriptions-item>
          </a-descriptions>
        </a-collapse-panel>
        <a-collapse-panel
          key="3"
          header="Информация об изменений статусов продуктов"
        >
          <history-products
            :oms-products="orderInfo.products"
            :order-id="orderId"
          />
        </a-collapse-panel>
      </a-collapse>

      <div v-if="!OrderInfoIsLoading">
        <a-table
          v-if="isDividable"
          class="description-padding"
          :columns="columnNames"
          :data-source="orderInfo.products"
          :scroll="{ x: true }"
          :locale="{ emptyText: 'Данные не найдены ):' }"
          row-key="id"
          :pagination="false"
          :row-selection="rowSelection"
          bordered
        >
          <div slot="sku" slot-scope="sku">
            <router-link :to="`/base-product/${sku}`" target="_blank">
              {{ sku }}
            </router-link>
          </div>
          <div slot="storage_type" slot-scope="storage_type">
            <template v-if="storage_type === 'fbo'">
              <p>На складе AIRBA</p>
            </template>
            <template v-else-if="storage_type === 'fbs'">
              <p>На складе у партнера</p>
            </template>
            <template v-else>
              <p>
                Не известный тип хранения <strong>{{ storage_type }}</strong>
              </p>
            </template>
          </div>
          <div slot="title" slot-scope="title, sku">
            <template v-if="sku && sku.sku">
              <a :href="`${showcaseUrl}product/${sku.sku}`" target="_blank">
                {{ title.ru }}
                <a-icon type="link" />
              </a>
            </template>
          </div>
          <div slot="merchant_title" slot-scope="merchant_title, item">
            <div
              v-if="merchant_title && item && item.merchant_id"
              class="merchant-links"
            >
              <router-link :to="`/partner/${item.merchant_id}`">
                <template v-if="merchant_title.ru">
                  {{ merchant_title.ru }} [ RU ]
                </template>
                <template v-else-if="merchant_title.kz">
                  {{ merchant_title.kz }} [ KZ ]
                </template>
                <template v-else-if="merchant_title.en">
                  {{ merchant_title.en }} [ EN ]
                </template>
              </router-link>
              <router-link :to="`/partner/${item.merchant_id}?tab=2&subTab=6`">
                Сотрудники
              </router-link>
            </div>
            <template v-else>
              Название продавца отсутсвует
              <a-icon style="color: #ff4d4f" type="warning" />
            </template>
          </div>
          <div slot="delivery_dates" slot-scope="delivery_dates">
            <p v-for="(item, index) in delivery_dates" :key="index">
              <span v-if="item === ''"> Дата не указана </span>
              <span v-else>
                {{ item | formatDateNoTime }}
              </span>
            </p>
          </div>
          <div slot="pickup_point_id" slot-scope="pickup_point_id, item">
            <router-link
              :to="`/pickup-point/merchant=${item.merchant_id}/id=${pickup_point_id}`"
              target="_blank"
            >
              {{ pickup_point_id }}
            </router-link>
          </div>
          <div slot="price" slot-scope="price">
            {{ price | currency }}
          </div>
          <div slot="total_price" slot-scope="total_price">
            {{ total_price | currency }}
          </div>
          <div slot="status" slot-scope="status">
            <status-view
              :status-list="PRODUCT_STATUSES"
              :status="status"
            ></status-view>
          </div>
        </a-table>
        <a-table
          v-else
          class="description-padding"
          :columns="columnNames"
          :data-source="orderInfo.products"
          :scroll="{ x: true }"
          :locale="{ emptyText: 'Данные не найдены ):' }"
          row-key="id"
          :pagination="false"
          bordered
        >
          <div slot="sku" slot-scope="sku">
            <router-link :to="`/base-product/${sku}`">
              <code>{{ sku }}</code>
            </router-link>
          </div>
          <div slot="storage_type" slot-scope="storage_type">
            <template v-if="storage_type === 'fbo'">
              <p>На складе AIRBA</p>
            </template>
            <template v-else-if="storage_type === 'fbs'">
              <p>На складе у партнера</p>
            </template>
            <template v-else>
              <p>
                Не известный тип хранения <strong>{{ storage_type }}</strong>
              </p>
            </template>
          </div>
          <div slot="title" slot-scope="title, sku">
            <template v-if="sku && sku.sku">
              <a :href="`${showcaseUrl}product/${sku.sku}`" target="_blank">
                {{ title.ru }}
                <a-icon type="link" />
              </a>
            </template>
          </div>
          <div slot="merchant_title" slot-scope="merchant_title, item">
            <div
              v-if="merchant_title && item && item.merchant_id"
              class="merchant-links"
            >
              <router-link :to="`/partner/${item.merchant_id}`">
                <template v-if="merchant_title.ru">
                  {{ merchant_title.ru }} [ RU ]
                </template>
                <template v-else-if="merchant_title.kz">
                  {{ merchant_title.kz }} [ KZ ]
                </template>
                <template v-else-if="merchant_title.en">
                  {{ merchant_title.en }} [ EN ]
                </template>
              </router-link>
              <router-link :to="`/partner/${item.merchant_id}?tab=2&subTab=6`">
                Сотрудники
              </router-link>
            </div>
            <template v-else>
              Название продавца отсутсвует
              <a-icon style="color: #ff4d4f" type="warning" />
            </template>
          </div>
          <div slot="delivery_dates" slot-scope="delivery_dates">
            <p v-for="(item, index) in delivery_dates" :key="index">
              <span v-if="item === ''"> Дата не указана </span>
              <span v-else>
                {{ item | formatDateNoTime }}
              </span>
            </p>
          </div>
          <div slot="pickup_point_id" slot-scope="pickup_point_id, item">
            <router-link
              :to="`/pickup-point/merchant=${item.merchant_id}/id=${pickup_point_id}`"
              target="_blank"
            >
              {{ pickup_point_id }}
            </router-link>
          </div>
          <div slot="price" slot-scope="price">
            {{ price | currency }}
          </div>
          <div slot="total_price" slot-scope="total_price">
            {{ total_price | currency }}
          </div>
          <div slot="status" slot-scope="status">
            <status-view
              :status-list="PRODUCT_STATUSES"
              :status="status"
            ></status-view>
          </div>
        </a-table>
      </div>

      <div class="settings-wrap">
        <div>
          <a-button-group class="mt--2" style="display: flex">
            <a-button
              v-if="isCompleded && isDividable"
              type="danger"
              @click="openReturnDrawer()"
              :disabled="returnForm.products.length === 0"
            >
              Вернуть
            </a-button>
            <a-button
              v-else-if="isCompleded && !isDividable"
              :disabled="isReturned"
              type="danger"
              @click="openReturnDrawer(orderInfo.products)"
            >
              Вернуть
            </a-button>
            <a-button
              v-if="orderInfo.dividable"
              :disabled="!canChangeSolutions || selectedRowKeys.length === 0"
              @click="showRejectModal(selectedRowKeys)"
              type="primary"
              class="ml--1"
            >
              Отменить
            </a-button>
            <a-button
              v-else
              :disabled="!canChangeSolutions"
              @click="showRejectModal(orderInfo.products)"
              type="primary"
              class="ml--1"
            >
              Отменить
            </a-button>
          </a-button-group>
        </div>
        <a-popconfirm
          v-if="canDeliver"
          title="Вы собираетесь ВРУЧНУЮ присвоить статус Доставлено?"
          ok-text="Да"
          cancel-text="Отмена"
          @confirm="changeStatusDelivered"
          v-can="{
            service: 'oms',
            permissions: ['order-delivered'],
          }"
        >
          <a-button type="primary">
            Доставлено
            <a-icon type="car" />
          </a-button>
        </a-popconfirm>
      </div>

      <a-drawer
        title="Заявка на возврат товара"
        width="50%"
        placement="right"
        :closable="false"
        :mask-closable="true"
        :keyboard="true"
        @close="onClose"
        :destroy-on-close="true"
        :visible="drawerVisible"
      >
        <a-divider>Информация о товарах</a-divider>
        <a-list>
          <a-list-item
            v-for="(item, index) in returnForm.products"
            :key="index"
          >
            <a-form-model
              :model="item"
              ref="returnFormProducts"
              :rules="returnFormProductRules"
            >
              <a-descriptions
                bordered
                layout="vertical"
                :column="3"
                class="maximum-width"
              >
                <a-descriptions-item :label="`Товар #${index + 1}`">
                  <a
                    :href="`${showcaseUrl}product/${item.sku}`"
                    target="_blank"
                  >
                    {{ item.title }}
                    <a-icon type="link" />
                  </a>
                </a-descriptions-item>
                <a-descriptions-item label="SKU">
                  <router-link
                    :to="`/base-product/${item.sku}`"
                    target="_blank"
                  >
                    {{ item.sku }}
                  </router-link>
                </a-descriptions-item>
                <a-descriptions-item>
                  <template v-slot:label>
                    <a-button
                      v-if="isDividable"
                      type="danger"
                      block
                      @click="removeFromReturn(index)"
                    >
                      Удалить
                    </a-button>
                  </template>
                  <a-form-model-item>
                    {{ item.quantity }}
                  </a-form-model-item>
                </a-descriptions-item>
                <a-descriptions-item :span="1">
                  <a-form-model-item label="Причина возврата">
                    <a-select
                      v-model="item.reason"
                      :options="returnReasonList"
                    ></a-select>
                  </a-form-model-item>
                </a-descriptions-item>
                <a-descriptions-item :span="2">
                  <a-form-model-item label="Комментарий к товару">
                    <a-textarea v-model="item.comment"></a-textarea>
                  </a-form-model-item>
                </a-descriptions-item>
                <a-descriptions-item :span="3">
                  <upload-files
                    v-model="item.photos"
                    multiple
                    prop="photos"
                    placeholder-text="Загрузить картину"
                    draggable
                  >
                  </upload-files>
                </a-descriptions-item>
              </a-descriptions>
            </a-form-model>
          </a-list-item>
        </a-list>
        <a-divider>Информация о логистике</a-divider>
        <a-form-model
          ref="returnFormAddress"
          :model="returnForm"
          :rules="returnFormRules"
          @submit="handleOnReturn"
        >
          <a-row :gutter="8">
            <a-col :span="6">
              <a-form-model-item prop="desired_date" label="Дата">
                <a-date-picker
                  :disabled-date="disabledDate"
                  @change="onDesiredDateChange"
                >
                </a-date-picker>
              </a-form-model-item>
            </a-col>

            <a-col :span="6">
              <a-form-model-item prop="address.region" label="Регион">
                <a-input v-model="returnForm.address.region"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item prop="address.city" label="Город">
                <a-input v-model="returnForm.address.city"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item prop="address.street" label="Улица">
                <a-input v-model="returnForm.address.street"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="8">
            <a-col :span="8">
              <a-form-model-item prop="address.house" label="Дом">
                <a-input v-model="returnForm.address.house"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item prop="address.floor" label="Этаж">
                <a-input v-model="returnForm.address.floor"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item prop="address.apartment" label="Квартира">
                <a-input v-model="returnForm.address.apartment"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="8">
            <a-col :span="6">
              <a-form-model-item prop="address.geo.latitude" label="Широта">
                <a-input-number
                  v-model="returnForm.address.geo.latitude"
                ></a-input-number>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item prop="address.geo.longitude" label="Долгота">
                <a-input-number
                  v-model="returnForm.address.geo.longitude"
                ></a-input-number>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <yandex-map
              :lat="returnForm.address.geo.latitude"
              :long="returnForm.address.geo.longitude"
              :zoom="
                returnForm.address.geo.latitude &&
                returnForm.address.geo.longitude
                  ? 15
                  : 12
              "
              @onCoordsChange="onCoordsChange"
            ></yandex-map>
          </a-row>
          <a-form-model-item>
            <a-button
              type="primary"
              @click="handleOnReturn"
              :disabled="returnForm.products.length === 0"
            >
              Создать заявку
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-drawer>
      <a-modal
        :visible="rejectModalVisible"
        :title="isDividable ? 'Частичная отмена заказа' : 'Отмена заказа'"
        @ok="orderItemSolution('reject', selectedProducts, reason)"
        @cancel="closeModal"
        @close="closeModal"
      >
        <a-form-item label="Причина отмены">
          <a-select v-model="reason" style="width: 100%">
            <a-select-option
              v-for="(item, index) in CANCEL_REASONS"
              :key="index"
              :value="item.label"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-table
          :data-source="selectedProducts"
          :columns="solutionColumnsName"
          :pagination="false"
          row-key="id"
          bordered
        >
          <div slot="quantity" slot-scope="quantity, product">
            <template v-if="orderInfo.dividable">
              <a-input-number
                :default-value="product.max"
                :min="1"
                :max="product.max"
                v-model="product.quantity"
              />
            </template>
            <template v-else>
              {{ quantity }}
            </template>
          </div>
        </a-table>
        <template slot="footer">
          <a-button key="back" @click="closeModal"> Отмена</a-button>
          <a-button
            key="submit"
            type="danger"
            @click="orderItemSolution('reject', selectedProducts, reason)"
          >
            Отменить выбранные
          </a-button>
        </template>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

import {
  orderInfo,
  columnNames,
  returnForm,
  returnFormRules,
  returnColumnNames,
  CANCEL_REASONS,
  returnFormProductRules,
} from 'Order/pages/OrderInfo/constants'
import { solutionColumnsName } from 'Partner/pages/Partner/PartnerOrderInfo/constants'
import { PaymentTypes } from 'Core/constants'
import {
  ORDER_STATUSES,
  PRODUCT_STATUSES,
  PAYMENT_STATUSES,
} from 'Core/types/order'
import YandexMap from 'Core/components/Maps/YandexMaps/YandexMap'
import UploadFiles from 'Core/components/Files/UploadFiles'
import moment from 'moment'
import HistoryOrder from 'Order/components/StatusesHistory/OrderStatusesHistory'
import HistoryProducts from 'Order/components/StatusesHistory/ProductStatusesHistory'

export default {
  components: {
    UploadFiles,
    YandexMap,
    HistoryProducts,
    HistoryOrder,
  },
  data() {
    return {
      ORDER_STATUSES,
      PAYMENT_STATUSES,
      PRODUCT_STATUSES,
      CANCEL_REASONS,
      PaymentTypes,
      columnNames,
      orderInfo,
      returnForm,
      returnFormRules,
      returnFormProductRules,
      returnColumnNames,
      solutionColumnsName,
      OrderInfoIsLoading: true,
      returnModal: false,
      selectedRows: [],
      selectedRowKeys: [],
      selectedProducts: [],
      showcaseUrl: process.env.VUE_APP_SHOWCASE_URL,
      rejectModalVisible: false,
      reason: '',
      returnReasonList: [
        {
          label: 'Не соответствует характеристикам',
          value: 'Не соответствует характеристикам',
        },
        {
          label: 'Не подходит по габаритам',
          value: 'Не подходит по габаритам',
        },
        {
          label: 'Поврежденный товар',
          value: 'Поврежденный товар',
        },
        {
          label: 'Необдуманная покупка',
          value: 'Необдуманная покупка',
        },
      ],
      drawerVisible: false,
    }
  },
  computed: {
    orderId() {
      return this.$route.params.id
    },
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.returnForm.products = []
          selectedRows.forEach((item) => {
            if (item.quantity > 1) {
              for (let i = 0; i < item.quantity; i++) {
                this.returnForm.products.push({
                  title: item.title.ru,
                  merchant_id: item.merchant_id,
                  quantity: 1,
                  sku: item.sku,
                  comment: '',
                  reason: 'Не соответствует характеристикам',
                  photos: [],
                })
              }
            } else {
              this.returnForm.products.push({
                title: item.title.ru,
                merchant_id: item.merchant_id,
                quantity: item.quantity,
                sku: item.sku,
                comment: '',
                reason: 'Не соответствует характеристикам',
                photos: [],
              })
            }
          })
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled:
              this.cancelStatuses.includes(record.status) ||
              this.returnStatuses.includes(record.status),
          },
        }),
      }
    },
    canDeliver() {
      return (
        this.orderInfo.status !== 'completed' &&
        this.orderInfo.status !== 'canceled'
      )
    },
    isDividable() {
      return this.orderInfo?.dividable
    },
    canChangeSolutions() {
      // если заказ не оплачен и тип оплаты == картой, кнопки должны быть disabled(требования бизнеса)
      if (
        this.orderInfo.payment_type === PaymentTypes.card_online.value &&
        this.orderInfo.payment_status === PAYMENT_STATUSES.not_paid.value
      ) {
        return false
      }
      return !this.cancelStatuses.includes(this.orderInfo.status)
    },
    cancelStatuses() {
      return [
        ORDER_STATUSES.canceled.value,
        ORDER_STATUSES.canceled_by_cls.value,
        ORDER_STATUSES.canceled_by_buyer.value,
        ORDER_STATUSES.canceled_by_merchant.value,
        ORDER_STATUSES.completed.value,
        ORDER_STATUSES.delivering.value,
      ]
    },
    returnStatuses() {
      return ['returning', 'returned']
    },
    isCompleded() {
      return this.orderInfo?.status === ORDER_STATUSES.completed.value
    },
    isReturned() {
      return this.returnStatuses.includes(this.orderInfo.products[0].status)
    },
  },
  methods: {
    compareSelectedProducts(productsList) {
      const products = this.orderInfo.products.map((item) => ({
        sku: item.sku,
        name: item.title.ru,
        quantity: item.quantity,
        max: item.quantity,
        merchant_id: item.merchant_id,
        id: item.id,
      }))
      if (this.orderInfo.dividable) {
        return products.filter((item) => productsList.indexOf(item.id) > -1)
      }
      return products
    },
    async initialize() {
      try {
        this.OrderInfoIsLoading = true
        this.orderInfo = await this.$API.OMS.getOrderItemInfo(this.orderId)
        this.reason = CANCEL_REASONS['changedDecision'].label
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.OrderInfoIsLoading = false
      }
    },
    onRowSelect(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    returnHandler() {
      this.returnModal = true
      this.returnForm.order_id = this.orderId
      if (!this.isDividable || this.returnForm.items.length === 0) {
        this.returnForm.items = this.orderInfo.products
      }
      this.returnForm.items = this.returnForm.items.map((item) => {
        return {
          ...item,
          max_amount: item.quantity,
        }
      })
    },
    showRejectModal(products) {
      this.selectedProducts = this.compareSelectedProducts(products, 'reject')
      this.rejectModalVisible = true
    },
    async orderItemSolution(solution, products, reason) {
      try {
        this.isLoading = true
        const payload = products?.map((item) => ({
          sku: item.sku,
          quantity: item.quantity,
          merchant_id: item.merchant_id,
        }))
        await this.$API.OMS.cancelProducts(this.orderId, {
          products: payload,
          reason,
        })
        this.closeModal()
        showSuccessMsg(`Отмена заказа #${this.orderId} была успешно совершена`)
        await this.initialize()
      } catch (e) {
        showErrorMsg(e.message)
      } finally {
        this.isLoading = false
      }
    },
    closeModal() {
      this.selectedProducts = []
      this.ruleList = {}
      this.rejectModalVisible = false
    },
    async changeStatusDelivered() {
      try {
        this.isLoading = true
        await this.$API.OMS.setStatusToDelivered(this.orderId)
        showSuccessMsg('Статус обновлен!')
        await this.initialize()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async onLinkOrderToEmployee() {
      try {
        this.isLoading = true
        const order = this.orderInfo
        await this.$API.OMS.linkOrderToEmployee(order.id)
        showSuccessMsg(`Вы взяли в работу заказ #${order.id}`)
        this.initialize()
      } catch (e) {
        showErrorMsg(e.message)
      } finally {
        this.isLoading = false
      }
    },

    onClose() {
      this.drawerVisible = false
    },
    openReturnDrawer(products) {
      if (!this.isDividable) {
        this.returnForm.products = []
        products.forEach((item) => {
          if (item.quantity > 1) {
            for (let i = 0; i < item.quantity; i++) {
              this.returnForm.products.push({
                title: item.title.ru,
                merchant_id: item.merchant_id,
                quantity: 1,
                sku: item.sku,
                comment: '',
                reason: 'Не соответствует характеристикам',
                photos: [],
              })
            }
          } else {
            this.returnForm.products.push({
              title: item.title.ru,
              merchant_id: item.merchant_id,
              quantity: item.quantity,
              sku: item.sku,
              comment: '',
              reason: 'Не соответствует характеристикам',
              photos: [],
            })
          }
        })
      }
      this.drawerVisible = true
    },
    removeFromReturn(index) {
      this.returnForm.products.splice(index, 1)
    },
    onDesiredDateChange(date) {
      this.returnForm.desired_date = moment(date).format('YYYY-MM-DDT00:00:00Z')
    },
    onCoordsChange({ lat, long }) {
      this.returnForm.address.geo.latitude = parseFloat(lat)
      this.returnForm.address.geo.longitude = parseFloat(long)
    },
    isReturnFormProductsValid() {
      const forms = this.$refs.returnFormProducts
      if (!forms?.length) return true

      return forms.reduce((prev, curr) => {
        let isFormValid = true
        curr.validate((isValid) => (isFormValid = isValid && prev))
        return isFormValid
      }, true)
    },
    async handleOnReturn() {
      try {
        const isProductsValid = this.isReturnFormProductsValid()
        this.$refs.returnFormAddress.validate(async (valid) => {
          if (valid && isProductsValid) {
            const returnForm = this.returnForm
            returnForm.order_id = this.orderId
            returnForm.address.geo.longitude = parseFloat(
              returnForm.address.geo.longitude,
            )
            returnForm.address.geo.latitude = parseFloat(
              returnForm.address.geo.latitude,
            )
            let { id } = await this.$API.RMS.createReturnRequest(returnForm)
            this.$router.push({ name: 'ReturnInfo', params: { id: id } })
            showSuccessMsg('Заявка на возврат создана')
          }
        })
      } catch (error) {
        showErrorMsg(error)
      }
    },
    disabledDate(current) {
      return current && current < moment(new Date())
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
::v-deep .ant-collapse-content-box {
  padding: 0 !important;
}

.description-padding {
  margin-top: 32px;
}

.return-modal {
  max-height: 60vh;
  overflow-y: scroll;
}

.merchant-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.grid-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: baseline;
  gap: 20px;
}

.maximum-width {
  width: 100%;
}

.order-comment {
  white-space: pre;
}
</style>
