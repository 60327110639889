<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col span="16">
        <a-row class="mb-24" type="flex" justify="space-between">
          <a-col>
            <h2>Создание Базового продукта</h2>
          </a-col>
          <a-col>
            <a-button
              @click="
                () =>
                  $router.push({
                    name: 'BaseProductList',
                  })
              "
            >
              {{ $t('common.back') }}
            </a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-spin :spinning="isFetching">
      <a-form-model
        ref="form"
        :model="formState"
        :rules="rules"
        @submit="onCreate"
      >
        <a-tabs default-active-key="1" tab-position="right" v-model="activeKey">
          <a-tab-pane key="1" tab="Основная информация">
            <a-form-model-item prop="title.ru" label="Название">
              <a-input v-model="formState.title.ru" />
            </a-form-model-item>
            <a-form-model-item prop="categories" label="Категория">
              <a-input v-model="formState.categories" disabled></a-input>
            </a-form-model-item>
            <category-selector
              :category-parent="categoryParent"
              @setCategory="setCategory"
            />
            <a-row type="flex" align="bottom">
              <a-col flex="250px">
                <a-form-model-item prop="model_id" label="Модель">
                  <a-input v-model="modelState" disabled></a-input>
                </a-form-model-item>
              </a-col>
              <a-col>
                <model-finder @setModel="onSetModel" />
              </a-col>
            </a-row>

            <template v-if="attributeListState.length">
              <attributes-form
                :attribute-list-state="attributeListState"
                :model-id="formState.model_id"
                @createBaseProductHandler="createModelAttributeGroupHandler"
              />
            </template>

            <a-row type="flex" align="bottom">
              <a-col flex="300px">
                <a-form-model-item prop="brand" label="Бренд">
                  <a-input v-model="formState.brand" disabled></a-input>
                </a-form-model-item>
              </a-col>
              <a-col>
                <brand-selector @setBrandHandler="setBrandHandler" />
              </a-col>
            </a-row>

            <a-form-model-item label="Цвет" prop="color">
              <a-select v-model="formState.color" style="width: 100%">
                <a-select-option
                  v-for="{ title, id, hex, slug } in colorList"
                  :key="id"
                  :value="slug"
                >
                  <div class="color-label">
                    <div
                      class="color-label-box"
                      :style="{ backgroundColor: hex }"
                    ></div>
                    <div>{{ title.ru }}</div>
                  </div>
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-divider />
            <a-form-model-item
              prop="minimum_price"
              label="Минимальная допустимая цена"
            >
              <a-input-number
                style="width: 150px"
                v-model="formState.minimum_price"
                :min="100"
              />
            </a-form-model-item>
            <!--          <a-form-model-item label="Тип фильтра">-->
            <!--            <a-radio-group v-model="formState.type">-->
            <!--              <a-radio-button value="REGULAR"> Regular </a-radio-button>-->
            <!--              <a-radio-button value="DIGITAL"> Digital </a-radio-button>-->
            <!--            </a-radio-group>-->
            <!--          </a-form-model-item>-->
            <template v-if="formState.type === 'REGULAR'">
              <a-divider>Параметры</a-divider>
              <div v-for="(item, index) in formState.dimensions" :key="index">
                <div class="dimension box-wrap">
                  <a-divider>
                    Размер коробки(в мм)
                    <b v-if="formState.dimensions.length > 1">
                      №{{ index + 1 }}
                    </b>
                  </a-divider>
                  <a-row :gutter="16">
                    <a-col :span="5">
                      <a-form-model-item
                        :prop="`dimensions[${index}].package.depth`"
                        label="Глубина(мм)"
                      >
                        <a-input-number v-model="item.package.depth" :min="1" />
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="5">
                      <a-form-model-item
                        :prop="`dimensions[${index}].package.height`"
                        label="Высота(мм)"
                      >
                        <a-input-number
                          v-model="item.package.height"
                          :min="1"
                        />
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="5">
                      <a-form-model-item
                        :prop="`dimensions[${index}].package.width`"
                        label="Ширина(мм)"
                      >
                        <a-input-number v-model="item.package.width" :min="1" />
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="5">
                      <a-form-model-item
                        :prop="`dimensions[${index}].weight`"
                        label="Вес товара(г)"
                      >
                        <a-input-number v-model="item.weight" :min="1" />
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="4">
                      <a-button
                        v-if="formState.dimensions.length > 1"
                        type="danger"
                        shape="circle"
                        @click="deleteBoxHandler(index)"
                      >
                        <a-icon type="close" />
                      </a-button>
                    </a-col>
                  </a-row>
                </div>
              </div>
              <a-form-item>
                <a-button @click="addBoxHandler">
                  Добавить еще параметр
                </a-button>
              </a-form-item>
            </template>
          </a-tab-pane>
          <a-tab-pane key="2" tab="Доп. информация">
            <a-form-model-item>
              <unique-list
                v-model="formState.meta.tags"
                label="Синонимы для поисковика (Rees)"
              />
            </a-form-model-item>
            <a-form-model-item>
              <unique-list
                v-model="formState.meta.type_prefix"
                label="Трансляция для поисковика (Rees)"
              />
            </a-form-model-item>
            <a-form-model-item label="Заголовок (SEO title)">
              <a-input v-model="formState.meta.title" />
            </a-form-model-item>
            <a-form-model-item label="Описание (SEO description)">
              <a-input v-model="formState.meta.description" />
            </a-form-model-item>
            <a-form-model-item>
              <unique-list
                v-model="formState.meta.keywords"
                label="Ключевые слова (SEO keywords)"
              />
            </a-form-model-item>
          </a-tab-pane>
          <a-tab-pane key="5" tab="Картинки" disabled></a-tab-pane>
        </a-tabs>

        <a-form-item v-if="activeKey < 3">
          <a-button type="primary" html-type="submit">Создать</a-button>
        </a-form-item>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import { filterOption, showErrorMsg, showSuccessMsg } from 'Core/utils'
import { formState, rules } from './constants'
import ModelFinder from 'Product/components/ModelFinder'
import BrandSelector from 'Product/components/BrandSelector'
//import ShowcaseView from 'Product/components/ShowcaseView'
import CategorySelector from 'Product/components/CategorySelector'
import UniqueList from 'Core/components/UniqueList/UniqueList'

import AttributesForm from 'Product/components/AttributesForm'

import { eventBus } from '@/main'

export default {
  name: 'BaseProductCreate',
  data: () => ({
    formState,
    rules,
    isFetching: true,
    attributeListState: [],
    categoryParent: [],
    categoryState: [],
    modelState: '',
    brandState: {},
    activeKey: '1',
    colorList: [],
    parsedFiles: [],
    chosenColor: null,
  }),
  watch: {
    'formState.categories': {
      handler() {
        this.$refs.form.validateField('categories')
      },
    },
    'formState.brand': {
      handler() {
        this.$refs.form.validateField('brand')
      },
    },
    'formState.model_id': {
      handler() {
        this.$refs.form.validateField('model_id')
      },
    },
  },
  methods: {
    filterOption,
    async initialize() {
      this.isFetching = true
      this.categoryParent = await this.$API.Storefront.getCategoryList({
        level: 1,
      })
      this.colorList = await this.$API.ConfigDiscovery.getColorList()
      this.formState.color = this.colorList[0].slug
      this.formState.categories = null
      this.formState.brand = null
      this.formState.model_id = null
      this.formState.title.ru = null
      this.formState.title.kz = null
      this.formState.meta.tags = []
      this.formState.meta.type_prefix = []
      this.isFetching = false
      await this.$refs.form.clearValidate()
    },
    onCreate(event) {
      event.preventDefault()
      this.validateFields()
    },
    async validateFields() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            eventBus.$emit('attributes:validate')
          } catch (error) {
            showErrorMsg(error.message)
          }
        } else {
          showErrorMsg('Заполните все поля')
          return false
        }
      })
    },
    async createModelAttributeGroupHandler(list) {
      const tempObj = {
        title: {
          ru: this.formState.title.ru,
          kz: this.formState.title.ru,
        },
        // about: this.formState.about,
        // about_page: this.formState.about_page,
        model_id: this.formState.model_id,
        color: this.colorList.find(
          (item) => item.slug === this.formState.color,
        ),
        minimum_price: this.formState.minimum_price,
        type: this.formState.type,
        dimensions: this.formState.dimensions,
        categories: this.categoryState,
        brand: this.brandState,
        attributes: list,
        meta: this.formState.meta,
      }
      try {
        this.isFetching = true
        const { sku } = await this.$API.Storefront.createBaseProduct(tempObj)
        showSuccessMsg('Базовый продукт успешно создан!')
        await this.$router.push(`/base-product/${sku}?tab=3`)
        this.isFetching = false
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isFetching = false
      }
    },
    async getModelInfo(id) {
      const data = await this.$API.Storefront.getModelAttributeList(id)
      data.sort((a, b) => {
        return b.is_required - a.is_required
      })
      this.attributeListState = data.map((item) => {
        return {
          ...Object.freeze(item),
          values: item.has_options ? [] : [{ value: '' }],
        }
      })
    },
    onSetModel(selectedModel) {
      this.formState.model_id = selectedModel.id
      this.modelState = selectedModel.title.ru
      this.getModelInfo(selectedModel.id)
    },
    setAboutPage(params) {
      this.formState.about_page.ru = params.ru
      this.formState.about_page.kz = params.kz
    },
    setBrandHandler(brand) {
      this.brandState = brand
      this.formState.brand = brand.title.ru
    },
    setCategory(category) {
      this.categoryState = category
      this.formState.categories = `${category[0].title.ru} > ${category[1].title.ru} > ${category[2].title.ru}`
    },
    addBoxHandler() {
      this.formState.dimensions.push({
        package: {
          depth: 1,
          height: 1,
          width: 1,
        },
        weight: 1,
      })
      this.rules.dimensions.push({
        package: {
          depth: {
            type: 'number',
            message: 'Поле обязательно для заполнения',
            required: true,
          },
          height: {
            type: 'number',
            message: 'Поле обязательно для заполнения',
            required: true,
          },
          width: {
            type: 'number',
            message: 'Поле обязательно для заполнения',
            required: true,
          },
        },
        weight: {
          type: 'number',
          message: 'Поле обязательно для заполнения',
          required: true,
        },
      })
    },
    deleteBoxHandler(index) {
      this.formState.dimensions.splice(index, 1)
      this.rules.dimensions.splice(index, 1)
      this.$refs.form.clearValidate()
    },
  },
  mounted() {
    this.initialize()
  },
  components: {
    ModelFinder,
    CategorySelector,
    BrandSelector,
    // ShowcaseView,
    //AttributeSelector,
    AttributesForm,
    UniqueList,
  },
}
</script>
<style lang="scss" scoped>
.dimension {
  padding: 5px 30px;
  border-bottom: 2px solid #f3f3f3;
  margin-bottom: 10px;
}

.box-wrap {
  background-color: lightgray;
}

.color-label {
  display: flex;

  &-box {
    width: 25px;
    height: 25px;
    margin-right: 7px;
  }
}

.reqField-box {
  padding: 20px;
  background-color: #ffcfcf;
}
</style>
