var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"settings-wrap"},[_c('h2',[_vm._v("Список рассрочек")]),_c('router-link',{directives:[{name:"can",rawName:"v-can",value:({
        service: 'payplan',
        permissions: ['installment-create'],
      }),expression:"{\n        service: 'payplan',\n        permissions: ['installment-create'],\n      }"}],attrs:{"to":{ name: 'InstallmentCreate' }}},[_c('a-button',[_vm._v("Создать новую рассрочку")])],1)],1),_c('a-table',{attrs:{"columns":_vm.installmentColumns,"data-source":_vm.installments,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
            var id = ref.id;

            return id;
},"pagination":_vm.pagination,"loading":_vm.isLoading,"bordered":""},on:{"change":_vm.onPageChange},scopedSlots:_vm._u([{key:"naming",fn:function(naming, record){return _c('div',{},[_c('router-link',{attrs:{"to":{
          name: 'InstallmentEdit',
          params: {
            installment_id: record.id,
          },
        }}},[(record.admin_title)?[_vm._v(" "+_vm._s(record.admin_title)+" ")]:[_vm._v(" "+_vm._s(naming)+" [old record] ")]],2)],1)}},{key:"duration",fn:function(duration){return _c('div',{},[_vm._v(" "+_vm._s(duration)+" месяца/месяцев ")])}},{key:"commission",fn:function(commission){return _c('div',{},[_vm._v(" "+_vm._s(commission / 100)+"% ")])}},{key:"start_at",fn:function(start_at){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("formatDate")(start_at && start_at))+" ")])}},{key:"end_at",fn:function(end_at){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("formatDate")(end_at))+" ")])}},{key:"isActive",fn:function(isActive, record){return _c('div',{},[(_vm.isOutdated(record.end_at))?_c('a-tag',{attrs:{"color":"red"}},[_vm._v(" Завершена ("+_vm._s(_vm._f("formatDateISO")(record.end_at))+") ")]):(isActive)?_c('a-tag',{attrs:{"color":"darkgreen"}},[_vm._v("Запущена")]):_c('a-tag',{attrs:{"color":"orange"}},[_vm._v("Не активна")])],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }