export const rules = {
  store_name: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  first_name: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  last_name: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  patronymic: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  phone: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
}

export const recognizedTablecolumns = [
  {
    title: 'Картинка',
    dataIndex: 'image_url',
    key: 'image_url',
    // width: "20%",
    scopedSlots: { customRender: 'image_url' },
  },
  {
    title: 'Название товара',
    dataIndex: 'title',
    key: 'title',
    // width: "10%",
    scopedSlots: { customRender: 'title' },
  },
  {
    title: 'Название товара в системе партнера',
    dataIndex: 'merchant_title',
    key: 'merchant_title',
    // width: "150px",
    scopedSlots: { customRender: 'merchant_title' },
  },
  {
    title: 'SKU товара',
    dataIndex: 'sku',
    key: 'sku',
    // width: "100px",
    scopedSlots: { customRender: 'sku' },
  },
  {
    title: 'Цена',
    dataIndex: 'price',
    key: 'price',
    // width: "150px",
    scopedSlots: { customRender: 'price' },
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Категории',
    dataIndex: 'category',
    key: 'category',
    // width: "20%",
    scopedSlots: { customRender: 'category' },
  },
  {
    title: 'Статус',
    dataIndex: 'is_enabled',
    key: 'is_enabled',
    // width: "20%",
    scopedSlots: { customRender: 'is_enabled' },
  },
  {
    title: 'Тип рассрочки',
    dataIndex: 'installment',
    key: 'installment',
    // width: "120px",
    scopedSlots: { customRender: 'installment' },
  },
  {
    title: 'В наличии',
    dataIndex: 'stocks',
    key: 'in_stock',
    // width: "120px",
    scopedSlots: { customRender: 'in_stock' },
  },
  {
    title: 'Операции',
    dataIndex: 'is_enabled',
    // width: "150px",
    key: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]

export const unRecognizedTablecolumns = [
  {
    title: 'Название товара',
    dataIndex: 'products',
    key: 'model',
    width: '40%',
    scopedSlots: { customRender: 'model' },
  },
  {
    title: 'Стоимость',
    dataIndex: 'products',
    key: 'price',
    width: '20%',
    scopedSlots: { customRender: 'price' },
  },

  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '20%',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'Действия',
    dataIndex: 'products',
    key: 'sku',
    width: '20%',
    scopedSlots: { customRender: 'sku' },
  },
]

export const bindProductFormRules = {
  search: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
}

export const unrecognizedMiniTableColumns = [
  {
    title: 'Название товара партнера',
    dataIndex: 'products',
    key: 'model',
    // width: "10%",
    scopedSlots: { customRender: 'model' },
  },
]

export const baseProductMiniTableColumns = [
  {
    title: 'Название базового продукта',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Доп.инфо',
    dataIndex: 'brand',
    key: 'brand',
    scopedSlots: { customRender: 'brand' },
  },
  {
    title: 'SKU',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
]
