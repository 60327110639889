import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис управления заказами церез колл центр
 *   <br> Swagger
 *   <br> {@link https://admin-api.test.airba.dev/preorder/swagger/index.html#/}
 *   */
export class PreorderService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'preorder',
    })
  }

  @Api
  async getOrderList(params) {
    const response = await this.GET(`/v1/admin/preorders`, params)
    return response
  }

  @Api
  async getOrderInfo(id): Promise<any> {
    const response = await this.GET(`/v1/admin/preorders/${id}`, id)
    return response
  }

  @Api
  async TakeToWork(orderId, userId): Promise<any> {
    const response = await this.PUT(`/v1/admin/preorders/${orderId}`, {
      employee: { tdid: userId },
    })
    return response
  }
  @Api
  async updateOrder(orderId, params): Promise<any> {
    const response = await this.PUT(`/v1/admin/preorders/${orderId}`, params)
    return response
  }
}
