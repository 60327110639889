

















































































































import { showErrorMsg } from '@/Core/utils'
import { Component, Vue } from 'vue-property-decorator'
import {
  IS_ACTIVE_FIELDS,
  IS_LINKED_FIELDS,
  rules,
} from 'Product/pages/DownloadReport/consts'
import ModelFinder from 'Product/components/ModelFinder.vue'
import BrandSelector from 'Product/components/BrandSelector.vue'
import { IColor } from 'Core/modules/API/@types/ConfigDiscovery.type'
import { skipEmptyValues } from 'Core/modules/helpers'
import { downloadCSVFile } from 'Core/modules/helpers'

@Component({ components: { ModelFinder, BrandSelector } })
class ModelReport extends Vue {
  isLoading = false
  IS_LINKED_FIELDS = IS_LINKED_FIELDS
  IS_ACTIVE_FIELDS = IS_ACTIVE_FIELDS
  modelState = ''
  colors: IColor[] = []
  rules = rules

  filter: any = {
    is_linked: null,
    is_active: null,
    model_id: null,
    brands: [],
    colors: [],
  }

  brand: any[] = []

  $refs: any

  async getReport() {
    try {
      this.isLoading = true
      const response: any = await this.$API.Storefront.getReport(
        skipEmptyValues(this.filter),
      )
      downloadCSVFile(this.modelState, response)
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  async getColors() {
    try {
      this.isLoading = true
      this.colors = await this.$API.ConfigDiscovery.getColorList()
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  onSetModel(selectedModel) {
    this.filter.model_id = selectedModel.id
    this.modelState = selectedModel.title.ru
  }

  setBrandHandler({ slug }) {
    if (!this.filter.brands.includes(slug)) {
      this.filter.brands.push(slug)
    }
  }

  onReset() {
    this.filter = {
      is_linked: null,
      is_active: null,
      model_id: null,
      brands: [],
      colors: [],
    }
    this.modelState = ''
    this.$refs.modelFinderRef.onReset()
  }

  async onSubmit() {
    this.$refs.form.validate(async (valid) => {
      if (!valid) {
        showErrorMsg('Выберите модель')
        return
      }
      await this.getReport()
    })
  }

  onBrandDropdownOpen() {
    this.$refs.brandSelectorRef?.showDrawer()
  }

  mounted() {
    this.getColors()
  }
}

export default ModelReport
