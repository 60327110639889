import { showErrorMsg } from 'Core/utils'
import Fuse from 'fuse.js'

export const productListMixin = (productType) => ({
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      merchantProducts: null,
      filters: null,
      fuse: null,
      categories: [],
      searchConfig: {
        isCaseSensitive: false,
        keys: ['title.ru', 'title.kz'],
      },
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 10,
        pageSize: 10,
      },
      sortingOrder: null,
      params: {
        page: 1,
        limit: 10,
        merchant_id: this.$route.params.id,
        with_installment: undefined,
        with_stocks: undefined,
        is_enabled: undefined,
        search: null,
        category: null,
        stock: null,
        sort: null,
      },
    }
  },
  watch: {
    isActive: {
      async handler(newValue) {
        if (newValue) {
          this.pagination.current = this.params.page = 1
          await this.getMerchantProductsList()
        }
      },
      immediate: true,
    },
  },
  methods: {
    onSearch(value) {
      if (value) {
        this.filters.categories = this.fuse.search(value).map((item) => {
          return item.item
        })
      } else {
        this.filters.categories = this.categories
      }
    },
    async onPageChange(pageNumber, filter, sorter) {
      if (this.sortingOrder != sorter.order) {
        this.params.page = this.pagination.current = 1
      } else {
        this.pagination.current = this.params.page = pageNumber.current
      }
      this.sortingOrder = sorter.order
      this.params.sort =
        sorter.order === 'descend'
          ? `${sorter.columnKey || 'price'}:desc`
          : `${sorter.columnKey || 'price'}:asc`
      await this.getMerchantProductsList()
    },
    async getMerchantProductsList() {
      try {
        this.isLoading = true
        const { products, total, count, filters } =
          productType === 'recognized'
            ? await this.$API.Storefront.getRecognizedMerchantProductsList(
                this.params,
              )
            : await this.$API.MerchantGatewayAdmin.getUnrecognizedMerchantProductsList(
                this.params,
              )

        this.filters = filters ? filters : {}
        this.categories = this.filters.categories
          ? this.filters.categories
          : this.categories
        this.fuse = new Fuse(this.categories, this.searchConfig)
        this.merchantProducts = products
        this.pagination.total = count || total || 0
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async searchHandler(event) {
      if (typeof event === 'object') {
        event.preventDefault()
      }
      try {
        this.pagination.current = this.params.page = 1
        await this.getMerchantProductsList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async dropFilter() {
      this.pagination.current = 1
      this.params = {
        page: 1,
        limit: 10,
        merchant_id: this.$route.params.id,
        with_installment: undefined,
        with_stocks: undefined,
        is_enabled: undefined,
        search: null,
        category: null,
        stock: null,
      }
      await this.getMerchantProductsList()
    },
  },
})
