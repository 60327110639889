import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис Хранение документов и пользователських подписей этих документов
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/audit/swagger/admin/index.html} */
export class AuditService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'audit',
    })
  }

  @Api
  async getAuditLogs(params) {
    const response = await this.GET(`/v1/admin/events`, params)
    return response
  }
}
