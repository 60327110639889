import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'
import { IGetEmployeeResponse } from 'Core/modules/API/@types/Role.type'

/**
 *  Сервис управления и создания шаблонов нотификаций
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/roles/swagger/index.html} */
export class RolesService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'roles',
    })
  }

  @Api
  async getEmployeeList(params): Promise<IGetEmployeeResponse> {
    const response = await this.GET<IGetEmployeeResponse>(
      `/v1/employees`,
      params,
    )
    return response
  }

  @Api
  async getAllowedRolesList(params) {
    const response = await this.GET(`/v1/employees/roles/allowed`, params)
    return response
  }
}
