<template>
  <div>
    <div class="settings-wrap">
      <h2>Список атрибутов</h2>
      <a-button
        @click="
          () => $router.push({ name: 'ModelEdit', params: { id: modelId } })
        "
      >
        {{ $t('common.back') }} к модели
      </a-button>
    </div>
    <a-form-model layout="inline" ref="form" :model="filter" @submit="onFilter">
      <div class="settings-wrap">
        <a-form-model-item label="Название атрибута:">
          <a-input v-model="filter.search" />
        </a-form-model-item>
        <a-form-item>
          <a-button-group>
            <a-button type="primary" html-type="submit">Поиск</a-button>
            <a-button type="dashed" @click="dropFilter"> сбросить</a-button>
          </a-button-group>
        </a-form-item>
      </div>
    </a-form-model>
    <a-table
      :columns="columnNames"
      :loading="loading"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="id" slot-scope="id">
        <router-link :to="`/model/${modelId}/attribute=${id}`">
          <code>{{ id }}</code>
        </router-link>
      </div>
      <div slot="groupId" slot-scope="groupId">
        <code v-if="groupList[groupId]">{{ groupList[groupId].title.ru }}</code>
      </div>
      <div slot="isRequired" slot-scope="isRequired, record">
        <div class="param-wrap">
          <p v-if="record.is_required">• Обязательное поле</p>
          <p v-if="record.is_description">• В кратком описании</p>
          <p v-if="record.is_variable">• Вариативное</p>
        </div>
      </div>
      <div slot="is_filter" slot-scope="is_filter, record">
        <a-switch
          v-model="record.is_filter"
          checked-children="Да"
          un-checked-children="Нет"
          @change="updateAttrHandler(record)"
        />
      </div>
      <div slot="weight" slot-scope="weight, record">
        <a-form-model
          :ref="'form' + record.id"
          @submit="confirmWeightChange(record)"
        >
          <a-input-number
            style="width: 75px; margin-right: 10px"
            :value="weight"
            :min="0"
            @change="(value) => onWeightChange(value, record)"
          />
          <a-button type="primary" html-type="submit" class="form__button">
            <a-icon type="save" />
          </a-button>
        </a-form-model>
      </div>
      <template slot="operation" slot-scope="text, record">
        <a-popconfirm
          v-if="dataSource.length"
          title="Удалить безвозвратно?"
          ok-text="Да, удалить"
          cancel-text="отмена"
          @confirm="() => onDelete(record.id)"
        >
          <a-button type="danger">
            <a-icon type="delete" />
          </a-button>
        </a-popconfirm>
      </template>
    </a-table>

    <a-row type="flex" justify="center">
      <a-col span="16">
        <a-divider>Создание нового Атрибута</a-divider>
        <a-button type="primary" block @click="goToCreateAttribute()">
          Создать
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { columnNames } from './constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { createMap } from 'Core/utils'
export default {
  name: 'AttributeList',
  data: () => ({
    columnNames,
    pagination: {
      current: 1,
      total: 0,
      defaultPageSize: 20,
    },
    filter: {
      search: null,
      page: 1,
      limit: 20,
    },
    loading: true,
    dataSource: [],
    groupList: {},
    isInUse: false,
  }),
  methods: {
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.initialize()
    },
    onFilter(event) {
      event.preventDefault()
      this.searchHandler()
    },
    async searchHandler() {
      try {
        this.loading = true
        this.pagination.current = 1
        this.filter.page = 1
        await this.getModelAttributeList()
        this.loading = false
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    dropFilter() {
      this.filter.search = null
      this.getModelAttributeList()
    },
    async initialize() {
      try {
        this.loading = true
        this.isInUse = !!(
          await this.$API.Storefront.getModelDependencyCount(this.modelId)
        ).count
        await this.getModelAttributeList()
        await this.getModelGroups()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.loading = false
      }
    },
    async getModelGroups() {
      const data = await this.$API.Storefront.getModelAttributeGroupList(
        this.modelId,
      )
      this.groupList = createMap('id', data)
    },
    async getModelAttributeList() {
      this.dataSource = await this.$API.Storefront.getModelAttributeList(
        this.modelId,
        this.filter,
      )
    },
    async onDelete(id) {
      await this.$API.Storefront.deleteModelAttributeById(this.modelId, id)
      showSuccessMsg('Атрибут удален.')
      await this.initialize()
    },
    goToCreateAttribute() {
      this.$router.push({
        name: 'AttributeCreate',
        params: { id: this.modelId },
      })
    },
    async updateAttrHandler(data) {
      try {
        const formObjState = {
          is_filter: data.is_filter,
        }
        await this.$API.Storefront.updateAttribute(
          this.modelId,
          data.id,
          formObjState,
        )
        showSuccessMsg('Атрибут обновлен.')
        await this.initialize()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async onWeightChange(value, record) {
      if (value && typeof value === 'number') {
        try {
          this.dataSource.map((item) => {
            if (item.id === record.id) record.weight = value
            return record
          })
        } catch (error) {
          showErrorMsg(error.message)
        }
      }
    },
    async confirmWeightChange(record) {
      try {
        await this.$API.Storefront.updateAttribute(this.modelId, record.id, {
          weight: record.weight,
        })
        showSuccessMsg(`Приоритет изменен на:  ${record.weight}`)
        await this.initialize()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
  computed: {
    modelId() {
      return this.$route.params.id
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped lang="scss">
.param-wrap {
  font-size: 12px;

  & p {
    text-align: left;
    margin: 0;
    font-weight: bold;
  }
}
</style>
