export const RETURN_STATUSES = {
  created: {
    value: 'created',
    label: 'На подтверждении',
    variant: '',
    icon: 'thunderbolt',
  },
  waiting_for_delivery: {
    value: 'waiting_for_delivery',
    label: 'Ожидает курьера',
    variant: 'orange',
    icon: 'car',
  },
  delivery_to_client: {
    value: 'delivery_to_client',
    label: 'Скоро будет принят курьером',
    variant: 'orange',
    icon: 'car',
  },
  rejected_by_courier: {
    value: 'rejected_by_courier',
    label: 'Отклонён курьером',
    variant: 'red',
    icon: 'stop',
  },
  on_stock: {
    value: 'on_stock',
    label: 'На складе',
    variant: 'orange',
    icon: 'code-sandbox',
  },
  delivery_to_partner: {
    value: 'delivery_to_partner',
    label: 'Едет к партнёру',
    variant: 'orange',
    icon: 'car',
  },
  in_progress: {
    value: 'in_progress',
    label: 'На проверке у партнёра',
    variant: 'blue',
    icon: 'question-circle',
  },

  finished: {
    value: 'finished',
    label: 'Возвращён',
    variant: 'green',
    icon: 'check',
  },
  rejected_by_merchant: {
    value: 'rejected_by_merchant',
    label: 'Отклонён продавцом',
    variant: 'red',
    icon: 'stop',
  },
  on_moderation: {
    value: 'on_moderation',
    label: 'Заявка на проверке',
    variant: 'blue',
    icon: 'search',
  },
  rejected_by_support: {
    value: 'rejected_by_support',
    label: 'Отклонён контакт-центром',
    variant: 'red',
    icon: 'stop',
  },
  canceled_by_oms: {
    value: 'canceled_by_oms',
    label: 'Отклонено системой',
    variant: 'red',
    icon: 'stop',
  },
}
