export const durations = [
  { label: '15 мин', value: 15 },
  { label: '20 мин', value: 20 },
  { label: '30 мин', value: 30 },
  { label: '1 час', value: 60 },
]

export const headers = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Название магазина',
    dataIndex: 'store_name',
    key: 'store_name',
    scopedSlots: { customRender: 'store_name' },
  },
  {
    title: 'Название точки выдачи',
    dataIndex: 'pickup_point_name',
    key: 'pickup_point_name',
    scopedSlots: { customRender: 'pickup_point_name' },
  },
  {
    title: 'Контактное лицо сервисной точки',
    dataIndex: 'contact_name',
    key: 'contact_name',
    scopedSlots: { customRender: 'contact_name' },
  },
  {
    title: 'Номер телефона контактного лица',
    dataIndex: 'contact_phone',
    key: 'contact_phone',
    scopedSlots: { customRender: 'contact_phone' },
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'Прошло времени с момента создания',
    dataIndex: 'time_passed_since_creation',
    key: 'time_passed_since_creation',
    scopedSlots: { customRender: 'time_passed_since_creation' },
  },
]
