
















































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { IStatusHistory } from 'Core/modules/API/@types/Audit.type'
import TimelineView from 'Core/components/StatusView/TimelineView.vue'
import { ITimeline } from 'Core/types/Timeline'

@Component({ components: { TimelineView } })
class HistoryModal extends Vue {
  @Prop({ default: false, type: Boolean })
  loading: boolean
  @Prop()
  statuses: IStatusHistory[]
  @Prop()
  currentStatus: string
  @Prop({ default: 'История изменения статусов' })
  title: string
  @Prop()
  orderId: string
  @Prop()
  type: 'order' | 'product' | 'partner_client_product'
  @Prop()
  productName: string

  visible = false
  isOpened = false

  @Ref('partnerTimelineRef')
  partnerTimelineRef: TimelineView
  @Ref('clientTimelineRef')
  clientTimelineRef: TimelineView

  @Ref('testTef')
  testTef: any

  get statusesMapped(): ITimeline[] {
    return (
      this?.statuses?.map((s) => ({
        title: s.meta.title,
        date: s.created_at,
        status: s.meta.status,
        description: s.meta.description,
        color: s.meta.color,
      })) ?? []
    )
  }

  showModal() {
    this.visible = true
    this.$nextTick(() => {
      this.isOpened = true
    })
  }

  handleCancel() {
    this.visible = false
    this.isOpened = false
  }

  @Watch('isOpened', { immediate: true })
  onVisibilityChange() {
    if (this.type !== 'partner_client_product') return
    this.$nextTick(function () {
      this.equalizeTimelineHeights()
    })
  }

  equalizeTimelineHeights() {
    const partnerRefs = this?.partnerTimelineRef?.$refs?.stepRef ?? []
    const clientRefs = this?.clientTimelineRef?.$refs?.stepRef ?? []
    if (!partnerRefs?.length || !clientRefs?.length) return
    partnerRefs.forEach((ref, i) => {
      if (ref?.clientHeight < clientRefs?.[i]?.clientHeight) {
        ref.style.height = clientRefs[i].clientHeight + 'px'
      } else clientRefs[i].style.height = ref?.clientHeight + 'px'
    })
  }
}

export default HistoryModal
