import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import cookie from 'js-cookie'
import { Api } from '../ApiServiceDecorator'
import {
  IRecoverPasswordByPhoneParams,
  IUsersParams,
} from 'Core/modules/API/@types/SSO'

import { BusinessLogicException } from 'Core/modules/API/@types/BusinessLogicException'

/**
 *  Сервис единого входа в систему
 * <br> Swagger
 * <br> {@link https://api.mp-test.airba.dev/sso/swagger/index.html} */
export class SSOService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'sso',
    })
  }

  async LogIn(params): Promise<any> {
    try {
      const response = await this.POST<{ data: any; success: boolean }>(
        '/v1/auth/signin/phone',
        params,
      )
      if (response.success) {
        cookie.set('accessToken', response.data.access_token)
        cookie.set('refreshToken', response.data.refresh_token)
      } else {
        throw new BusinessLogicException(
          response?.data.error?.message
            ? response?.data.error?.message
            : 'LogIn',
        )
      }
    } catch (error) {
      throw new Error(error.transformErrorMessage())
    }
  }
  async LogOut(): Promise<any> {
    await this.DELETE('/v1/auth/signout')
    cookie.remove('accessToken')
    cookie.remove('refreshToken')
  }
  async ProfileInfo(): Promise<any> {
    const { data } = await this.GET('/v1/users/info')
    return data
  }

  @Api
  async Users(params?: IUsersParams): Promise<any> {
    const response = await this.GET('/v1/users', params)
    return response
  }

  @Api
  async getUserByTdId(tdId: string): Promise<any> {
    const response = await this.GET(`/v1/users/${tdId}`)
    return response
  }

  @Api
  async signupFast(phone) {
    const response = await this.POST(`/v1/auth/signup/fast`, { phone })
    return response
  }

  @Api
  async updateUserInfo(payload) {
    const response = await this.PUT(`/v1/users/info`, payload)
    return response
  }

  @Api
  async recoverPasswordByPhone(payload: IRecoverPasswordByPhoneParams) {
    const response = await this.PUT(`/v1/recovery/phone/otp`, payload)
    return response
  }

  @Api
  async sendRecoveryOTPToPhone(phone: string) {
    const response = await this.PUT(`/v1/recovery/phone`, { phone })
    return response
  }

  @Api
  async updatePassword(password: string) {
    const response = await this.PUT(`/v1/users/password`, { password })
    return response
  }
}
