export const bannerFormRules = {
  // alt: {
  //   type: 'string',
  //   message: 'Поле обязательно для заполнения',
  //   required: true,
  // },
  weight: {
    type: 'number',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  // switch_text: {
  //   type: 'string',
  //   message: 'Поле обязательно для заполнения',
  //   required: true,
  // },
  // 'info.title': {
  //   type: 'string',
  //   message: 'Поле обязательно для заполнения',
  //   required: true,
  // },
  'color.background': [
    // {
    //   type: 'string',
    //   message: 'Поле обязательно для заполнения',
    //   required: true,
    // },
    {
      pattern: /^#[0-9a-f]{3,6}$/i,
      message: 'Невалидный hex цвет',
    },
  ],
  'color.text': [
    // {
    //   type: 'string',
    //   message: 'Поле обязательно для заполнения',
    //   required: true,
    // },
    {
      pattern: /^#[0-9a-f]{3,6}$/i,
      message: 'Невалидный hex цвет',
    },
  ],
  url: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  image: {
    type: 'string',
    message: 'Выберите картинку',
    required: true,
  },
  platforms: {
    type: 'array',
    message: 'Выберите как минимум одно устройство',
    required: true,
  },
  position: {
    type: 'string',
    message: 'Выберите позицию',
    required: true,
  },
  'search.type': {
    type: 'string',
    message: 'Выберите тип',
    required: true,
  },
  'search.id': {
    type: 'string',
    message: 'Необходимо выбрать/заполнить значение',
    required: true,
  },
  position_id: {
    type: 'string',
    message: 'Необходимо выбрать/заполнить значение',
    required: true,
  },
}
