<template>
  <div>
    <h4>Категории 3 уровня</h4>
    <a-table
      :columns="comissionsColumnNames"
      :data-source="dataSource"
      :scroll="{ x: true }"
      size="small"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ category }) => category"
      :loading="isLoading"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="rate" slot-scope="rate, record">
        <a-tag color="green" class="rate-box">
          <span>{{ rate / 100 }}</span>
          %
        </a-tag>
        <a-icon @click="editHandler(record)" class="edit-box" type="edit" />
      </div>
    </a-table>
    <a-modal
      v-model="modalIsShown"
      title="Укажите значение от 0-100%"
      cancel-text="Отмена"
      @ok="handleUpdateCategoryRate"
    >
      <h4 v-if="currentCat.category_title">
        {{ currentCat.category_title.ru }}
      </h4>
      <a-form-model-item label="Сумма комиссии">
        <a-input-number
          v-model="rate"
          :min="0"
          block
          :formatter="(value) => `${value}%`"
          :parser="(value) => value.replace('%', '')"
          :max="100"
          :precision="2"
        />
      </a-form-model-item>
    </a-modal>
  </div>
</template>

<script>
import { comissionsColumnNames } from 'Partner/pages/Partner/PartnerInfo/constants'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  name: 'PartnerComissionsInfo',
  data: () => ({
    comissionsColumnNames,
    dataSource: [],
    isLoading: false,
    modalIsShown: false,
    pagination: {
      current: 1,
      total: 0,
      defaultPageSize: 10,
    },
    filter: {
      page: 1,
      limit: 10,
    },
    currentCat: {},
    rate: 0,
  }),
  methods: {
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.getCommissions()
    },
    editHandler(category) {
      this.currentCat = category
      this.rate = category.value / 100
      this.modalIsShown = true
    },
    async getCommissions() {
      try {
        this.isLoading = true
        await this.getCommissionRequest()
        this.isLoading = false
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async handleUpdateCategoryRate() {
      try {
        await this.updateOrCreateCommissions()
        showSuccessMsg('Успешно обновили комиссию')
        await this.getCommissions()
        this.modalIsShown = false
      } catch (err) {
        showErrorMsg('Обратитесь к разработчикам')
      } finally {
        this.modalIsShown = false
      }
    },
    async updateOrCreateCommissions() {
      const params = {
        value: this.rate * 100,
        category_title: {
          ru: this.currentCat.category_title.ru,
          kz: this.currentCat.category_title.kz,
        },
        category: this.currentCat.category,
        merchant_id: this.merchantID,
      }
      if (this.currentCat.id) {
        await this.$API.Commissions.updateCommission(this.currentCat.id, params)
      } else {
        await this.$API.Commissions.createCustomCommission(params)
      }
    },
    async getCommissionRequest() {
      const { commissions, count } =
        await this.$API.Commissions.getPersonalCommissionList(
          this.merchantID,
          this.filter,
        )
      this.dataSource = commissions
      this.pagination.total = count
    },
  },
  computed: {
    merchantID() {
      return this.$route.params.id
    },
  },
  mounted() {
    this.getCommissions()
  },
}
</script>

<style scoped lang="scss">
.rate-box {
  padding: 7px 12px;

  & span {
    font-weight: bold;
    margin-right: 5px;
    font-size: 1.1rem;
  }
}

.edit-box {
  cursor: pointer;
  color: orange;

  &:hover {
    color: orangered;
  }
}
</style>
