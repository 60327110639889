import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'
import {
  INotification,
  INotificationsResponse,
} from 'Core/modules/API/@types/Notifier.type'

export class NotifierService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'notifier',
    })
  }

  @Api
  async getNotifications(params): Promise<INotificationsResponse> {
    const response = await this.GET<INotificationsResponse>(
      `/v1/notifications`,
      params,
    )
    return response
  }

  @Api
  async getNotificationById(id: string): Promise<INotification> {
    const response = await this.GET<INotification>(`/v1/notifications/${id}`)
    return response
  }
}
