
























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  ICategoryChildSelection,
  ICategoryLevel2,
  ICategoryLevel3,
  ICategoryTree,
  IFuseItem,
  IFuseItemMatch,
} from 'Core/modules/API/@types/Storefront.type'
import ListItem from 'Core/components/ListDefault/ListItem.vue'
import Fuse from 'fuse.js'

@Component({ components: { ListItem } })
class CategorySelectorTree extends Vue {
  @Prop({ type: Array })
  preselectedCategories: { id: string; title: { kz: string; ru: string } }[]
  @Prop()
  merchantCategories: undefined | ICategoryTree[]

  visible = false
  level2Categories: ICategoryLevel2[] = []
  level3Categories: ICategoryLevel3[] = []
  selectedCategories: ICategoryLevel3[] = []
  categoriesFiltered: IFuseItem<ICategoryLevel3>[] = []

  activeL1: string | null = null
  activeL2: string | null = null

  search: string = ''
  fuse: any = null
  searchConfig = {
    isCaseSensitive: false,
    includeMatches: true,
    includeScore: true,
    keys: ['title.kz', 'title.ru'],
  }

  onClose() {
    this.visible = false
  }

  get isLoading() {
    return this.$store.getters['config/categories/isLoading']
  }

  get categories(): ICategoryTree[] {
    if (this.merchantCategories?.length) return this.merchantCategories
    return this.$store.getters['config/categories/getCategoryTree']
  }

  get categoriesFlatObject(): { [key: string]: ICategoryLevel2 } {
    if (this.merchantCategories?.length) {
      return this.$store.getters[
        'config/categories/merchantCategoriesFlatObject'
      ]
    }
    return this.$store.getters['config/categories/categoriesFlatObject']
  }

  get categoriesFlatArray(): ICategoryLevel2[] {
    if (this.merchantCategories?.length) {
      return this.$store.getters[
        'config/categories/merchantCategoriesFlatArray'
      ]
    }
    return this.$store.getters['config/categories/categoriesFlatArray']
  }

  get selectedCategorySlugs() {
    if (!this.selectedCategories?.length) return []
    return this.selectedCategories.map((c) => c.slug)
  }

  // eslint-disable-next-line no-undef
  get isAllChildSelected(): Partial<ICategoryChildSelection> {
    const defineSelection = (items: any[]) => {
      if (!items.length) return undefined
      const firstItem = items?.[0]
      if (
        (firstItem && this.isParentSelected(firstItem)) ||
        this.isGrandParentSelected(firstItem)
      ) {
        return true
      }
      return items.every((prop) =>
        this.selectedCategorySlugs.includes(prop.slug),
      )
    }
    const isAllLevel2Checked = defineSelection(this.level2Categories)
    const isAllLevel3Checked = defineSelection(this.level3Categories)

    return { isAllLevel2Checked, isAllLevel3Checked }
  }

  @Watch('isAllChildSelected')
  onAllChildSelected(
    newVal: ICategoryChildSelection,
    oldVal: ICategoryChildSelection,
  ) {
    const shouldTrigger = (b1: boolean, b2: boolean) => b1 === false && b2

    if (shouldTrigger(oldVal.isAllLevel2Checked, newVal.isAllLevel2Checked)) {
      this.onAllChildCategoriesSelected(this.level2Categories?.[0])
    }
    if (shouldTrigger(oldVal.isAllLevel3Checked, newVal.isAllLevel3Checked)) {
      if (this.isGrandParentSelected(this.level3Categories?.[0])) {
        return
      }
      this.onAllChildCategoriesSelected(this.level3Categories?.[0])
    }
  }

  getCategoryChild(step: number, item: ICategoryTree) {
    if (step === 2) {
      this.level2Categories = item.children
      this.level3Categories = []
      this.activeL1 = item.slug
      this.activeL2 = null
    }
    if (step === 3) {
      this.level3Categories = item.children
      this.activeL2 = item.slug
    }
  }

  @Watch('merchantCategories', { immediate: true })
  onMerchantCategoriesChanged() {
    this.fuse = new Fuse(this.categoriesFlatArray, this.searchConfig)
    this.search = ''
    this.level2Categories = []
    this.level3Categories = []
  }

  onCheckboxValueChange(event, category: ICategoryLevel2) {
    event.stopPropagation()
    // eslint-disable-next-line no-unused-vars
    const { children, ...categoryItem } = category
    if (
      !event.target.checked &&
      (this.isParentSelected(categoryItem) ||
        this.isGrandParentSelected(categoryItem))
    ) {
      return this.onPreselectedCategoryUnchecked(categoryItem)
    }

    if (event.target.checked) {
      this.onCategoryChecked(categoryItem)
    } else {
      this.onCategoryUnchecked(categoryItem)
    }
  }

  isCategorySelected(category) {
    if (
      this.isParentSelected(category) ||
      this.isGrandParentSelected(category)
    ) {
      return true
    }
    return this.selectedCategorySlugs.includes(category.slug)
  }
  isParentSelected(category) {
    return (
      category.parent && this.selectedCategorySlugs.includes(category.parent)
    )
  }
  isGrandParentSelected(category) {
    return (
      category.grandParent &&
      this.selectedCategorySlugs.includes(category.grandParent)
    )
  }

  onCategoryChecked(category) {
    this.unselectChildItems(category)
    if (!this.selectedCategorySlugs.includes(category.slug)) {
      this.selectedCategories.push(category)
    }
  }
  onCategoryUnchecked(category) {
    this.selectedCategories = this.selectedCategories.filter(
      (c) => c.slug !== category.slug,
    )
    this.unselectChildItems(category)
  }
  /**
   * Handler для предвыбранных категории.  К примеру, если мы выберем категорию внутри L1, то
   * на UI все его дочерние категории(L2, L3) будут предвыбраны, но эти предвыбранные категории не будут сохранены
   * внутри массива selectedCategories, так как по умолчанию подразумевается, что выбирая L1, мы выбираем и все его дочерние категории.
   * Данная функция позволяет обработать corner case, когда предвыбранная категория убирается. То есть, в таком случае, мы
   * должны убрать признак checked у парента и grandParent'a, так как теперь не все его дочерние категории выбраны.
   */
  onPreselectedCategoryUnchecked(category) {
    const handleLevel2Delete = (slug, parentSlug) => {
      if (this.selectedCategorySlugs.includes(parentSlug)) {
        const selectedItems = this.level2Categories.filter(
          (p) => p.slug !== slug,
        )
        selectedItems.forEach((item) => {
          this.onCategoryChecked(item)
        })
      } else {
        const parentItem = this.level2Categories.find((p) => p.slug === slug)
        this.onCategoryUnchecked(parentItem)
      }
      this.onCategoryUnchecked({ slug: parentSlug })
    }

    if (category.level === 2) {
      handleLevel2Delete(category.slug, category.parent)
    }

    if (category.level === 3) {
      handleLevel2Delete(category.parent, category.grandParent)
      const selectedItems = this.level3Categories.filter(
        (p) => p.slug !== category.slug,
      )
      selectedItems.forEach((item) => {
        this.onCategoryChecked(item)
      })
    }
  }
  /**
   * Удаляет все выбранные категории уровнем выше у предков. К примеру, если были выбраны несколько
   * категории внутри L2, а мы выбрали\убрали его прямого предка в L1, то все его выбранные категории
   * и подкатегории будут удалены
   */
  unselectChildItems(category) {
    this.selectedCategories = this.selectedCategories.filter(
      (item) =>
        !(
          item.level > category.level &&
          (item.parent === category.slug || item.grandParent === category.slug)
        ),
    )
  }

  /**
   * Функция, срабатывающая при выборе всех child категории.
   */
  onAllChildCategoriesSelected(category) {
    const items: any =
      category.level === 3 ? this.level2Categories : this.categories

    const parentCategory = items?.find((item) => item.slug === category.parent)
    // eslint-disable-next-line no-unused-vars
    const { children, ...item } = parentCategory
    this.onCategoryChecked(item)
  }

  saveCategory() {
    this.$emit('onCategoriesSelected', this.selectedCategories)
    this.onClose()
  }

  getCategoryCount(category: ICategoryTree) {
    const { L2, L3 } = this.getSubcategoriesCount(category)
    return [L2 ? `L2: ${L2}` : null, L3 ? `L3: ${L3}` : null]
      .filter((v) => v)
      .join('; ')
  }

  /**
   * Возвращает количество выбранных L2 и/или L3 подкатегорий
   */
  getSubcategoriesCount(category: ICategoryTree) {
    let L2 = 0
    let L3 = 0

    // категория 1 или 2 выбрана
    if (this.selectedCategorySlugs.includes(category.slug)) {
      if (category.level === 2) L3 = category.children?.length ?? ''
      else if (category.level === 1) {
        for (const category2 of category.children) {
          L2++
          L3 += category2.children?.length ?? 0
        }
      }
      // категория 1 не выбрана(но выбраны некоторые child категории)
    } else if (category.level === 1) {
      this.selectedCategories.forEach((cat: any) => {
        if (cat.level === 2 && cat.parent === category.slug) {
          L2++
          const children = cat.children
            ? cat.children
            : this.categoriesFlatObject?.[cat.slug]?.children
          L3 += children?.length ?? 0
        } else if (cat.level === 3 && cat.grandParent === category.slug) {
          L3++
        }
      })
      //  категория 2 не выбрана(но выбраны child L3 категории)
    } else if (category.level === 2) {
      this.selectedCategories.forEach((cat) => {
        if (cat.level === 3 && cat.parent === category.slug) {
          L3++
        }
      })
      // выбрана parent категория для L2(то есть, его L1 предок)
      if (this.isParentSelected(category)) {
        L3 = category?.children?.length
      }
    }

    return { L2, L3 }
  }

  async getCategoryBySlug(slug: string) {
    const cat = await this.$store.dispatch(
      'config/categories/getCategoryBySlug',
      { slug, rootCategory: this.merchantCategories },
    )
    return cat
  }

  /**
   * Возвращает новый массив категории с метаданными
   */
  async mapSourceCategories() {
    const results = await Promise.all(
      this.preselectedCategories.map(async (cat) => {
        const categoryMeta = await this.getCategoryBySlug(cat.id)
        return {
          slug: cat.id,
          title: cat.title,
          level: categoryMeta?.level,
          parent: categoryMeta?.parent,
          grandParent: categoryMeta?.grandParent,
        }
      }),
    )
    return results
  }

  /**
   * Функция, которая хайлайтит текст согласно количеству match'ей в тексте
   * @param text
   * @param {IFuseItemMatch[]} matches
   */
  highlight(text: string, matches: IFuseItemMatch[]) {
    const elements = [...text].map((s, i) => {
      let shouldHighlight = false
      matches.forEach((match) => {
        match.indices.forEach((nums) => {
          if (nums[0] <= i && nums[1] >= i) {
            shouldHighlight = true
          }
        })
      })

      return !shouldHighlight
        ? s
        : this.$createElement('span', { class: { highlight: true } }, s)
    })

    return elements
  }

  onSearch(searchText: string) {
    const items: IFuseItem<ICategoryLevel3>[] = this.fuse.search(searchText)
    this.categoriesFiltered = items.map((searchItem) => {
      const startIndex = searchItem.item.path?.ru?.indexOf(
        searchItem.item.title.ru,
      )
      const pathWithoutTitle = searchItem.item.path?.ru?.slice(0, startIndex)
      const selectedText = this.highlight(
        searchItem.item.title.ru,
        searchItem.matches,
      )
      const highlightedText = this.$createElement('div', [
        pathWithoutTitle,
        selectedText,
      ])

      return { ...searchItem, highlightedText }
    })
  }

  async mounted() {
    if (
      this.preselectedCategories &&
      Array.isArray(this.preselectedCategories)
    ) {
      this.selectedCategories = await this.mapSourceCategories()
    }
  }
}

export default CategorySelectorTree
