import { format as FNS_format } from 'date-fns'
/* eslint-disable */
const capitalize = function (string) {
  if (string) {
    return string.length
      ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
      : string
  } else {
    return string
  }
}
const format = function format() {
  var formatRegExp = /%[sdj%]/g
  for (
    var _len = arguments.length, args = new Array(_len), _key = 0;
    _key < _len;
    _key++
  ) {
    args[_key] = arguments[_key]
  }

  var i = 1
  var f = args[0]
  if (f === '' || f === undefined) {
    console.warn('Ошибка валидации. Нет ключа для перевода')
    f =
      'Поле "%s" не валидно. Если видите это сообщение сообщите администратору'
  }
  var len = args.length

  if (typeof f === 'function') {
    return f.apply(null, args.slice(1))
  }

  if (typeof f === 'string') {
    var str = String(f).replace(formatRegExp, function (x) {
      if (x === '%%') {
        return '%'
      }

      if (i >= len) {
        return x
      }

      switch (x) {
        case '%s':
          return String(args[i++])

        case '%d':
          return Number(args[i++])

        case '%j':
          try {
            return JSON.stringify(args[i++])
          } catch (_) {
            return '[Circular]'
          }

          break

        default:
          return x
      }
    })
    return str
  }

  return f
}

export const convertBlobToBase64 = function (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const removeFromArrayByIndex = function (array, index) {
  array.splice(index, 1)
  return array
}

const ByteToMB = function (bytes) {
  return bytes / (1024 * 1024)
}
const MBtoByte = function (mb) {
  return mb * 1024 * 1024
}

/**
 * Возвращает значение по заданному {path} из объекта.
 * @param {object} obj - исходный объект
 * @param {string} path - путь до свойства объекта. Может быть задано в виде: "foo.bar.baz"
 * @example getDeepValue(object, "some.inner.property")
 */
const getDeepValue = (obj, path) => {
  if (!path) return undefined
  return path.split('.').reduce((o, key) => (o && o[key] ? o[key] : null), obj)
}

/**
 * Функция для преобразования регулярных массивов в массив вида FSM
 * @param {any[]} items - исходный массив
 * @param {label: string, value: string, variant: string, icon?: string} params - перечисляемые свойства FSM
 * @example mapStateToFSM(items, {label: "object.someProp", value: "id", variant: "hex" })
 */
const mapStateToFSM = (items, { label, value, variant, icon }) => {
  return items.map((item) => ({
    label: getDeepValue(item, label),
    value: getDeepValue(item, value),
    variant: getDeepValue(item, variant),
    icon: getDeepValue(item, icon),
  }))
}

/**
 * Функция, позволяющая объединять строки. Ключевая особенность от Array.Prototype.join
 * заключается в том, что можно указать отдельный сепаратор перед последним словом.
 * @param {String[]} lines - строки
 * @param {String} lastSeparator  - сепаратор перед последним словом. Default = ' или '
 * @param {*} primarySeparator - основной сеператор. Default = ', '
 * @returns {String}
 *
 * @example joinWith(['карандаш', 'ручка', 'бумага'], ', либо') => 'карандаш, ручка, либо бумага'
 */
const joinWith = (lines, lastSeparator = ' или ', primarySeparator = ', ') => {
  if (!lines || !Array.isArray(lines)) return undefined

  let message = ''
  lines.forEach((line, i) => {
    message += line
    if (lines.length > 1 && i === lines.length - 2) message += lastSeparator
    else if (i !== lines.length - 1) message += primarySeparator
  })

  return message
}

/**
 * Возвращает из объекта только те key-values, где value !== undefined, null, ""
 * @param obj исходный объект
 * @returns {{[p: string]: unknown}} объект, где все ключи имеют value
 */
const skipEmptyValues = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => value !== null && value !== undefined && value !== '',
    ),
  )
}

const downloadCSVFile = (fileName, resp, extension = 'csv') => {
  const url = window.URL.createObjectURL(new Blob([resp]))
  const link = document.createElement('a')
  link.href = url
  const filename = `${fileName} - ${FNS_format(
    new Date(),
    'dd.MM.yyyy HH:mm:ss',
  )}`
  link.setAttribute('download', `${filename}.${extension}`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export {
  capitalize,
  format,
  ByteToMB,
  MBtoByte,
  mapStateToFSM,
  joinWith,
  removeFromArrayByIndex,
  skipEmptyValues,
  downloadCSVFile,
  getDeepValue,
}
