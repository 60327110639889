






















import { Component, Vue } from 'vue-property-decorator'
import { showErrorMsg } from '@/Core/utils'
import { downloadCSVFile } from 'Core/modules/helpers'

@Component
class GeneralReport extends Vue {
  isLoading = false

  async getConnectedProductsReport() {
    try {
      this.isLoading = true
      const response: any =
        await this.$API.Storefront.getConnectedMerchantProductsReport()
      downloadCSVFile('Связанные товары', response)
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  async getUnconnectedProductsReport() {
    try {
      this.isLoading = true
      const response: any =
        await this.$API.MerchantGatewayAdmin.getUnconnectedMerchantProductsReport()
      downloadCSVFile('НЕсвязанные товары', response)
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }

  async getMatchedSkuReport() {
    try {
      this.isLoading = true
      const response: any = await this.$API.BPM.getMatchedSkuReport()
      downloadCSVFile('Сопоставленные SKU', response)
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isLoading = false
    }
  }
}

export default GeneralReport
