import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'
import { INotesResponse } from 'Core/modules/API/@types/Note.type'

export class NotesService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'notes',
    })
  }

  @Api
  async getNotes(params): Promise<INotesResponse> {
    const response = await this.GET<INotesResponse>(`/v1/notes`, params)
    return response
  }
}
