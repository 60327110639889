var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Заказы в один клик")]),_c('a-table',{attrs:{"columns":_vm.columnNames,"loading":_vm.loading,"data-source":_vm.orders,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
        var id = ref.id;

        return id;
},"pagination":_vm.pagination,"bordered":""},on:{"change":_vm.onPageChange},scopedSlots:_vm._u([{key:"id",fn:function(id){return _c('div',{},[_c('router-link',{attrs:{"to":{
          name: 'OrderByClickInfo',
          params: { id: id },
        }}},[_vm._v(" "+_vm._s(id)+" ")])],1)}},{key:"customer",fn:function(customer){return _c('div',{},[_c('p',[_vm._v(_vm._s(customer.Name))]),_c('p',[_vm._v(_vm._s(customer.Phone))])])}},{key:"products",fn:function(products){return _c('div',{},_vm._l((products),function(item,idx){return _c('p',{key:idx},[_vm._v(" "+_vm._s(((idx + 1) + ". " + (item.title.ru)))+" ")])}),0)}},{key:"status",fn:function(status){return _c('div',{},[_c('status-view',{attrs:{"status-list":_vm.ORDER_STATUSES,"status":status}})],1)}},{key:"employee",fn:function(employee){return _c('div',{},[_vm._v(" "+_vm._s(employee.LastName)+" "+_vm._s(employee.FirstName)+" ")])}},{key:"city_id",fn:function(city_id){return _c('div',{},[_vm._v(" "+_vm._s(_vm.getCityNameById(city_id))+" ")])}},{key:"created_at",fn:function(created_at){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("formatDate")(created_at))+" ")])}},{key:"updated_at",fn:function(updated_at){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("formatDate")(updated_at))+" ")])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }