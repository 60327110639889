export const formState = {
  agreement: {
    body: null,
    title: null,
  },
  meta: {
    description: null,
    title: null,
    keywords: null,
  },
  code: null,
  description: null,
  template_id: null,
  valid_days_count: 365,
  verification_required: true,
}

export const rules = {
  description: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  code: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  template_id: {
    type: 'string',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  valid_days_count: {
    type: 'number',
    message: 'Поле обязательно для заполнения',
    required: true,
  },
  agreement: {
    body: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
    title: {
      type: 'string',
      message: 'Поле обязательно для заполнения',
      required: true,
    },
  },
}
