


































import { Component, Vue } from 'vue-property-decorator'
import { columnNames } from './constants'
import { showErrorMsg } from '@/Core/utils'
import { StatusType } from 'Core/constants'

@Component
class NotifyGoodArrive extends Vue {
  isLoading = false
  dataSource = []
  columnNames = columnNames
  pagination = {
    current: 1,
    total: 0,
    defaultPageSize: 10,
  }
  filter = {
    page: 1,
    limit: 10,
    type: 'out_of_stock',
  }
  StatusType = StatusType
  $API: any

  async initialize() {
    try {
      this.isLoading = true
      const { requests, count } = await this.$API.Wishlist.getWishList(
        this.filter,
      )
      this.dataSource = requests
      this.pagination.total = count
    } catch (error) {
      showErrorMsg(error.messasge)
    } finally {
      this.isLoading = false
    }
  }
  onPageChange({ current }) {
    this.filter.page = current
    this.pagination.current = current
    this.initialize()
  }

  created() {
    this.initialize()
  }
}

export default NotifyGoodArrive
