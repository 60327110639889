<template>
  <div>
    <div class="settings-wrap">
      <h2>Создание Промо страницы</h2>
      <a-button
        @click="
          () =>
            $router.push({
              name: 'PromoPageList',
            })
        "
      >
        {{ $t('common.back') }}
      </a-button>
    </div>
    <a-form-model
      ref="form"
      :model="formState"
      :rules="rules"
      @submit="onValidate"
    >
      <a-form-model-item label="Статаус">
        <a-switch
          checked-children="Активен"
          un-checked-children="Выключен"
          v-model="formState.is_active"
        />
      </a-form-model-item>
      <a-form-model-item prop="title" label="Загаловок">
        <a-input v-model="formState.title" />
      </a-form-model-item>
      <a-form-model-item prop="slug" label="Slug">
        <a-input v-model="formState.slug" />
      </a-form-model-item>
      <a-form-model-item prop="type" label="Тип">
        <a-select v-model="formState.type">
          <a-select-option
            v-for="(item, index) in PROMO_TYPES"
            :key="index"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-collapse>
        <a-collapse-panel key="1" header="SEO данные">
          <a-form-model-item label="Заголовок">
            <a-input v-model="formState.meta.title" />
          </a-form-model-item>
          <a-form-model-item label="Описание">
            <a-input v-model="formState.meta.description" />
          </a-form-model-item>
          <a-form-model-item label="Ключевые слова">
            <a-input v-model="formState.meta.keywords" />
          </a-form-model-item>
        </a-collapse-panel>
      </a-collapse>

      <a-form-model-item prop="html" label="HTML код">
        <code-editor v-model="formState.html" :options="cmOptions" />
      </a-form-model-item>
      <a-form-model-item prop="css" label="CSS код">
        <code-editor v-model="formState.css" :options="cmOptions" />
      </a-form-model-item>
      <a-form-model-item label="JS код">
        <code-editor v-model="formState.js" :options="cmOptions" />
      </a-form-model-item>

      <a-form-item>
        <a-button type="primary" html-type="submit">Создать</a-button>
      </a-form-item>
    </a-form-model>
  </div>
</template>

<script>
import { formState, PROMO_TYPES, rules } from './constants'
import { codemirror as CodeEditor } from 'vue-codemirror'

import 'codemirror/mode/htmlembedded/htmlembedded.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/theme/material.css'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'

export default {
  name: 'PromoPageCreate',
  components: {
    CodeEditor,
  },
  data() {
    return {
      formState,
      rules,
      cmOptions: {
        tabSize: 2,
        mode: 'text/html',
        theme: 'material',
        lineNumbers: true,
        line: true,
      },
      PROMO_TYPES,
    }
  },
  methods: {
    onValidate(event) {
      event.preventDefault()
      this.validateFields()
    },
    async validateFields() {
      try {
        await this.$refs.form.validate()
        await this.createPromoPage()
        showSuccessMsg('Страница создана!')
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async createPromoPage() {
      await this.$API.PPS.createPromoPage(this.formState)
      await this.$router.push({
        name: 'PromoPageEdit',
        params: { id: this.formState.slug },
      })
    },
  },
}
</script>

<style scoped></style>
