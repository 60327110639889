var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"settings-wrap"},[_c('h2',[_vm._v("Список динамических QR кодов")]),_c('a-button',{on:{"click":_vm.gotoCreateQR}},[_vm._v("Создать новый QR код")])],1),_c('a-table',{attrs:{"columns":_vm.tableColumns,"data-source":_vm.QRCodeList,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
        var id = ref.id;

        return id;
},"pagination":_vm.pagination,"loading":_vm.isLoading,"bordered":""},on:{"change":_vm.onPageChange},scopedSlots:_vm._u([{key:"id",fn:function(id){return _c('div',{},[_c('router-link',{attrs:{"to":{
          name: 'DynamicQRCodeEdit',
          params: {
            id: id,
          },
        }}},[_vm._v(" "+_vm._s(_vm._f("shortID")(id,5))+" ")])],1)}},{key:"info",fn:function(info, record){return _c('div',{},[_c('h4',[_vm._v(_vm._s(info))]),_c('p',{staticClass:"info"},[_vm._v(_vm._s(record.description))])])}},{key:"static_link",fn:function(static_link){return _c('div',{},[_c('a',{attrs:{"href":_vm.addPrefix(static_link),"target":"_blank"}},[_c('code',[_vm._v(_vm._s(static_link))]),_c('a-icon',{attrs:{"type":"link"}})],1)])}},{key:"dynamic_link",fn:function(dynamic_link){return _c('div',{},[_c('a',{attrs:{"href":_vm.addPrefix(dynamic_link),"target":"_blank"}},[_c('code',[_vm._v(_vm._s(dynamic_link))]),_c('a-icon',{attrs:{"type":"link"}})],1)])}},{key:"dataSrc",fn:function(dataSrc){return _c('div',{},[_c('img',{attrs:{"src":("data:image/png;base64," + dataSrc)}})])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }