
























































































































import { Component, Vue } from 'vue-property-decorator'
import { showErrorMsg, showSuccessMsg } from '@/Core/utils'
import { ORDER_STATUSES } from 'Core/types/order'
import { PaymentTypes } from 'Core/constants'
import { columnNames } from './constants'

@Component
class SingleOrder extends Vue {
  ORDER_STATUSES = ORDER_STATUSES
  PaymentTypes = PaymentTypes
  columnNames = columnNames
  isLoading = false
  isVisible = false
  orderForm = {}
  order_id = null
  showcaseUrl = process.env.VUE_APP_SHOWCASE_URL
  $API: any

  get orderId() {
    return this.$route.params.id
  }
  get cityList() {
    return this.$store.getters['core/cities']
  }

  get User() {
    return this.$store.getters['user/profile']
  }

  async initialize() {
    try {
      this.isLoading = true
      this.orderForm = await this.$API.PreorderService.getOrderInfo(
        this.orderId,
      )
    } catch (error) {
      showErrorMsg(error.messasge)
    } finally {
      this.isLoading = false
    }
  }

  getResposibleEmpolyeeFullName(employee) {
    if (employee && employee.first_name) {
      return `В работе у ${employee.first_name}  ${employee.last_name}`
    }
    return 'Заказ ожидает обработки.'
  }

  getTotalSum(products) {
    let sum = 0
    products.forEach((item) => {
      sum += item.price * item.quantity
    })
    return sum
  }

  async TakeToWork() {
    try {
      this.isLoading = true
      await this.$API.PreorderService.TakeToWork(this.orderId, this.User.id)
      await this.initialize()
      showSuccessMsg('Вы взяли заказ в работу')
    } catch (error) {
      showErrorMsg(error.messasge)
    } finally {
      this.isLoading = false
    }
  }

  async cancelOrder() {
    try {
      this.isLoading = true
      const params = {
        status: 'canceled',
      }
      await this.$API.PreorderService.updateOrder(this.orderId, params)
      await this.initialize()
      showSuccessMsg('Вы отменили заказ!')
    } catch (error) {
      showErrorMsg(error.messasge)
    } finally {
      this.isLoading = false
    }
  }

  finishOrder() {
    this.isVisible = true
  }
  closeModal() {
    this.isVisible = false
    this.order_id = null
  }
  async onSubmit() {
    try {
      this.isLoading = true
      const params = {
        order_id: this.order_id,
        status: 'completed',
      }
      await this.$API.PreorderService.updateOrder(this.orderId, params)
      await this.initialize()
      showSuccessMsg('Заказ завершен!')
    } catch (error) {
      showErrorMsg(error.messasge)
    } finally {
      this.isVisible = false
      this.isLoading = false
    }
  }

  created() {
    this.initialize()
  }
}
export default SingleOrder
