<template>
  <div>
    <div class="settings-wrap">
      <h2>
        Пользователи админки <span>(Всего: {{ pagination.total }})</span>
      </h2>
      <search-user-modal
        v-if="allowedRoleList.length"
        :allowed-role-list="allowedRoleList"
        @updateUserList="initialize"
      />
    </div>
    <!--    <a-form-model layout="inline" ref="form" :model="filter" @submit="onFilter">-->
    <!--      <div class="settings-wrap">-->
    <!--        <a-form-model-item label="Номер телефона:">-->
    <!--          <a-input v-model="filter.phone" />-->
    <!--        </a-form-model-item>-->
    <!--        <a-form-model-item label="Имя сотрудника:">-->
    <!--          <a-input v-model="filter.name" />-->
    <!--        </a-form-model-item>-->
    <!--        <a-form-model-item label="Имя сотрудника:">-->
    <!--          <a-input v-model="filter.name" />-->
    <!--        </a-form-model-item>-->
    <!--        <a-form-item>-->
    <!--          <a-button-group>-->
    <!--            <a-button type="primary" html-type="submit">Поиск</a-button>-->
    <!--            <a-button type="dashed" @click="dropFilter"> сбросить </a-button>-->
    <!--          </a-button-group>-->
    <!--        </a-form-item>-->
    <!--      </div>-->
    <!--    </a-form-model>-->
    <a-table
      :columns="columnNames"
      :loading="isLoading"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="tdid" slot-scope="tdid, record">
        <router-link :to="`/user/${record.id}`">
          <code>{{ tdid | shortID(3) }}</code>
        </router-link>
      </div>
      <div slot="fullName" slot-scope="fullName, record">
        {{ record.last_name }}
        {{ record.first_name }}
      </div>
      <div slot="roles" slot-scope="roles">
        <a-tag class="tag-label" v-for="role in roles" :key="role.id">
          {{ role.title.ru }}
        </a-tag>
      </div>
      <div slot="isActive" slot-scope="isActive, record">
        <a-button
          v-if="isActive"
          type="primary"
          @click="toggleEmployeeStatusHandler(record)"
        >
          <a-icon type="check-circle" />
        </a-button>
        <a-button
          v-else
          type="danger"
          @click="toggleEmployeeStatusHandler(record)"
        >
          <a-icon type="stop" />
        </a-button>
      </div>
      <div slot="phone" slot-scope="phone">
        {{ phone | phoneFormat }}
      </div>
      <div slot="updatedAt" slot-scope="updatedAt">
        {{ updatedAt | formatDate }}
      </div>
      <div slot="createdAt" slot-scope="createdAt">
        {{ createdAt | formatDate }}
      </div>
    </a-table>
  </div>
</template>

<script>
import {
  deactivateEmployeeByIdAPI,
  updateEmployeeByIdAPI,
} from 'User/api/users'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { columnNames } from './constants'

import SearchUserModal from 'User/components/SearchUserModal'

export default {
  name: 'UserList',
  components: {
    SearchUserModal,
  },
  data: () => ({
    columnNames,
    dataSource: [],
    isLoading: true,
    pagination: {
      current: 1,
      total: 0,
      defaultPageSize: 20,
    },
    filter: {
      phone: null,
      fio: null,
      roles: [],
      tdid: null,
      page: 1,
      limit: 20,
    },
    allowedRoleList: [],
  }),
  methods: {
    async initialize() {
      try {
        this.isLoading = true
        await this.getAllowedRoleList()
        await this.getEmployeeList()
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async getAllowedRoleList() {
      const { roles } = await this.$API.Roles.getAllowedRolesList()
      this.filter.roles = roles.map((item) => {
        return item.value
      })
      this.allowedRoleList = roles
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.getEmployeeList()
    },
    async onFilter(event) {
      event.preventDefault()
      try {
        this.isLoading = true
        this.pagination.current = 1
        this.filter.page = 1
        await this.getEmployeeList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getEmployeeList() {
      this.isLoading = true
      const { data, count } = await this.$API.Roles.getEmployeeList(this.filter)
      this.pagination.total = count
      this.dataSource = data
      this.isLoading = false
    },
    dropFilter() {
      this.pagination.current = 1
      this.filter = {
        phone: null,
        name: null,
        page: 1,
        limit: 20,
        roles: [],
      }
      this.getEmployeeList()
    },
    toggleEmployeeStatusHandler(employee) {
      employee.is_active
        ? this.deactivateHandler(employee.tdid)
        : this.activateHandler(employee)
    },
    async activateHandler(employee) {
      const tempObj = {
        is_active: true,
        role_ids: employee.roles.map((role) => role.id),
      }
      try {
        await updateEmployeeByIdAPI(employee.id, tempObj)
        showSuccessMsg('Вы активировали пользователя')
        this.initialize()
      } catch (e) {
        showErrorMsg('Не удалось активировать пользователя')
      }
    },
    async deactivateHandler(tdid) {
      try {
        await deactivateEmployeeByIdAPI(tdid)
        showSuccessMsg('Вы Деактивировали пользователя')
        this.initialize()
      } catch (e) {
        showErrorMsg('Не удалось Деактивировать пользователя')
      }
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style scoped lang="scss">
.tag-label {
  padding: 3px 5px;
  margin: 2px;
  color: #2c3e50;
}
</style>
