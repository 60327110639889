



















































import { Component, Vue } from 'vue-property-decorator'
import { showErrorMsg, showSuccessMsg } from 'Core/utils'
import { formState, rules } from './constants'

@Component
class DynamicQRCodeEdit extends Vue {
  formState = formState
  rules = rules
  isFetching = false
  $refs

  get QR_code_id() {
    return this.$route.params.id
  }

  async initialize() {
    try {
      this.isFetching = true
      await this.getQRCodeById()
      this.isFetching = false
    } catch (error) {
      showErrorMsg(error.message)
    } finally {
      this.isFetching = false
    }
  }
  onValidate(event) {
    event.preventDefault()
    this.validateFields()
  }
  async validateFields() {
    try {
      this.isFetching = true
      await this.$refs.form.validate()
      const { description, name, dynamic_link } = this.formState
      await this.$API.QRCode.updateDynamicQRCode(this.QR_code_id, {
        description,
        name,
        dynamic_link,
      })
      await this.getQRCodeById()
      showSuccessMsg('Удачно обновлен!')
      this.isFetching = false
    } catch (error) {
      showErrorMsg(error.message)
    } finally {
      this.isFetching = false
    }
  }
  async getQRCodeById() {
    this.formState = await this.$API.QRCode.getDynamicQRCodeById(
      this.QR_code_id,
    )
  }

  async onDelete() {
    try {
      this.isFetching = true
      await this.$API.QRCode.deleteDynamicQRCode(this.QR_code_id)
      showSuccessMsg('Успешно удален')
      this.$router.push({ name: 'DynamicQRCodeList' })
    } catch (e) {
      showErrorMsg(e.message)
    } finally {
      this.isFetching = false
    }
  }

  mounted() {
    this.initialize()
  }
}

export default DynamicQRCodeEdit
