import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'

/**
 *  Сервис просмотра смапленных sku
 * <br> Swagger
 * <br> {@link https://admin-api.test.airba.dev/bpm/swagger/index.html} */
export class BPM extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'bpm',
    })
  }

  @Api
  async getMatchedSkuList(params) {
    const response = await this.GET(`/v1/bpm/markets`, params)
    return response
  }

  @Api
  async getMatchedSkuReport() {
    const response = await this.GET(`/v1/bpm/markets/csv`)
    return response
  }

  @Api
  async postSkuToMarket(params) {
    const response = await this.POST(
      `/v1/bpm/markets/products-relation`,
      params,
    )
    return response
  }
}
