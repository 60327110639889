import { ApiServiceClass } from 'Core/modules/API/ApiServiceClass'
import { Api } from 'Core/modules/API/ApiServiceDecorator'
import {IDeliveryRequest, IReturnProductsChangeStatus, IReturnRequestParams} from 'Core/modules/API/@types/RMS'

/**
 *  Сервис управления возвратами заказов
 * <br> Swagger
 * <br> {@link https://admin-api.test.airba.dev/rms-new/swagger/index.html} */
export class RMSService extends ApiServiceClass {
  constructor(axios) {
    super({
      axios: axios,
      serviceUrl: 'rms-new',
    })
  }

  @Api
  async getReturnRequestList(params) {
    const response = await this.GET(`/v1/products`, params)
    return response
  }

  @Api
  async getReturnRequestInfo(id) {
    const response = await this.GET(`/v1/request/${id}`)
    return response
  }

  @Api
  async createReturnRequest(params: IReturnRequestParams) {
    const response = await this.POST(`/v1/products`, params)
    return response
  }

  @Api
  async changeReturnProductStatus(params: IReturnProductsChangeStatus) {
    const response = await this.PATCH(`/v1/products`, params)
    return response
  }

  @Api
  async orderDelivery(params: IDeliveryRequest, request_uuid: string) {
    const response = await this.PATCH(`/v1/products/${request_uuid}`, params)
    return response
  }
}
