<template>
  <div>
    <h2>Партнеры</h2>
    <a-form-model layout="inline" ref="form" :model="filter" @submit="onSearch">
      <div class="settings-wrap">
        <div>
          <a-radio-group
            :default-value="null"
            button-style="solid"
            v-model="filter.status"
            @change="searchHandler"
          >
            <a-radio-button :value="null"> Все</a-radio-button>
            <a-radio-button
              v-for="(item, index) in PARTNER_STATUSES"
              :value="item.value"
              :key="index"
            >
              <a-icon :type="item.icon" />
              {{ item.label }}
            </a-radio-button>
          </a-radio-group>
        </div>
        <div>
          <a-form-item>
            <a-button
              type="primary"
              @click.prevent.stop="addPartner"
              v-can="{
                service: 'merchant-profile',
                permissions: ['merchant-create'],
              }"
            >
              <a-icon type="user-add" />
              Зарегистрировать партнера
            </a-button>
          </a-form-item>
        </div>
      </div>
      <div class="settings-wrap">
        <a-form-model-item label="Название юр.лица">
          <a-input v-model="filter.company_name" />
        </a-form-model-item>
        <a-form-model-item label="Бин">
          <a-input v-model="filter.bin" />
        </a-form-model-item>
        <phone-input
          label="Номер партнера"
          v-model="filter.phone"
          prop="phone"
        />
        <a-form-model-item label="ID партнера">
          <a-input v-model="filter.merchant_id" />
        </a-form-model-item>
        <a-form-model-item label="Название магазина">
          <a-input v-model="filter.store_name" />
        </a-form-model-item>
        <a-form-model-item label="Аккаунт менеджер">
          <div class="flex-autocomplete">
            <a-auto-complete
              v-model="filter.account_manager_tdids[0]"
              @search="onManagerSearch"
              @select="onManagerSelect"
              @click="onManagerSearch"
              :allow-clear="true"
            >
              <template slot="dataSource">
                <a-select-option
                  v-for="item in accountManagersList"
                  :key="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </template>
            </a-auto-complete>
            <a-button
              v-if="userId"
              @click="selectSelf"
              class="search-btn"
              type="primary"
            >
              Выбрать себя
            </a-button>
          </div>
        </a-form-model-item>
        <a-form-item>
          <a-button-group>
            <a-button type="primary" html-type="submit">
              <a-icon type="search" />
              Поиск
            </a-button>
            <a-button type="dashed" @click="dropFilter">
              <a-icon type="close" />
              Сбросить
            </a-button>
          </a-button-group>
        </a-form-item>
      </div>
    </a-form-model>
    <a-table
      :columns="columnNames"
      :data-source="dataSource"
      :scroll="{ x: true }"
      :locale="{ emptyText: 'Данные не найдены ):' }"
      :row-key="({ id }) => id"
      :loading="isLoading"
      :pagination="pagination"
      @change="onPageChange"
      bordered
    >
      <div slot="id" slot-scope="id">
        <router-link :to="`/partner/${id}`">
          <code>{{ id | shortID(5) }}</code>
        </router-link>
      </div>
      <div slot="fullName" slot-scope="fullName">
        {{ fullName.first_name | fio(fullName.last_name, fullName.patronymic) }}
      </div>
      <div slot="status" slot-scope="status">
        <status-view
          :status-list="PARTNER_STATUSES"
          :status="status"
        ></status-view>
      </div>
      <div slot="account_manager" slot-scope="account_manager">
        <template v-if="account_manager">
          {{ account_manager.name }} {{ account_manager.surname }}
          {{ account_manager.patronymic }}
        </template>
        <template v-else>
          <a-tag color="red">Менеджер не закреплен</a-tag>
        </template>
      </div>
      <div slot="createdAt" slot-scope="createdAt">
        {{ createdAt | formatDate }}
      </div>
    </a-table>
    <!--    <a-divider>Создание партнера вручную</a-divider>-->
    <!--    <a-button-->
    <!--      type="primary"-->
    <!--      block-->
    <!--      @click="$router.push({ name: 'MerchantCreate' })"-->
    <!--    >-->
    <!--      Создать-->
    <!--    </a-button>-->
  </div>
</template>

<script>
import { columnNames } from './constants'
import { showErrorMsg } from 'Core/utils'
import { PARTNER_STATUSES } from 'Core/types/partner'
import { skipEmptyValues } from 'Core/modules/helpers'
import Fuse from 'fuse.js'

export default {
  name: 'PartnersList',
  data: () => ({
    PARTNER_STATUSES,
    columnNames,
    searchLabel: '',
    dataSource: [],
    isLoading: true,
    pagination: {
      current: 1,
      total: 0,
      defaultPageSize: 10,
    },
    allAccountManagersList: [],
    accountManagersList: [],
    fuse: null,
    fuseConfig: {
      isCaseSensitive: false,
      keys: ['label'],
    },
    filter: {
      merchant_id: null,
      store_name: null,
      bin: null,
      status: null,
      company_name: null,
      phone: null,
      page: 1,
      limit: 10,
      account_manager_tdids: [],
    },
  }),
  computed: {
    userId() {
      const tdid = this.$store.getters['user/profile'].id
      let index = this.allAccountManagersList.find((item) => {
        return item.value === tdid
      })
      return index ? index : false
    },
  },
  methods: {
    addPartner() {
      this.$router.push({ name: 'PartnerCreate' })
    },
    onSearch(event) {
      event.preventDefault()
      this.searchHandler()
    },
    async searchHandler() {
      try {
        this.isLoading = true
        this.pagination.current = 1
        this.filter.page = 1
        await this.getPartnerList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    onPageChange({ current }) {
      this.filter.page = current
      this.pagination.current = current
      this.getPartnerList()
    },
    onManagerSearch(value) {
      if (value !== '') {
        this.accountManagersList = this.fuse.search(value).map((item) => {
          return item.item
        })
      } else {
        this.accountManagersList = this.allAccountManagersList
      }
    },
    onManagerSelect(value) {
      this.filter.account_manager_tdids[0] = value
    },
    selectSelf() {
      if (this.userId) {
        this.accountManagersList = [this.userId]
        this.filter.account_manager_tdids[0] = this.userId.value
      }
      this.searchHandler()
    },
    async initialize() {
      try {
        this.isLoading = true
        const { data } = await this.$API.Roles.getEmployeeList({
          page: 1,
          limit: 200,
          roles: 'account_manager',
        })
        this.allAccountManagersList = data.map((item) => {
          return {
            label: `${item.first_name} ${item.last_name}`,
            value: item.tdid,
          }
        })
        this.accountManagersList = this.allAccountManagersList
        this.fuse = new Fuse(this.allAccountManagersList, this.fuseConfig)
        await this.getPartnerList()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
    async getPartnerList() {
      const { data, count } = await this.$API.MerchantProfile.getPartnerList(
        skipEmptyValues(this.filter),
      )
      this.pagination.total = count
      this.dataSource = data
      this.isLoading = false
    },
    dropFilter() {
      this.pagination.current = 1
      this.filter = {
        merchant_id: null,
        store_name: null,
        bin: null,
        status: null,
        company_name: null,
        phone: null,
        page: 1,
        limit: 10,
        account_manager_tdids: [],
      }
      this.getPartnerList()
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style lang="scss">
.active {
  background-color: red;
}
.flex-autocomplete {
  display: flex;
  margin-top: 4px;
  & .search-btn {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-left: -2px;
    z-index: 2;
  }
}
</style>
