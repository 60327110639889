var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Все заказы")]),_c('a-table',{attrs:{"columns":_vm.columnNames,"loading":_vm.loading,"data-source":_vm.mixedOrders,"scroll":{ x: true },"locale":{ emptyText: 'Данные не найдены ):' },"row-key":function (ref) {
        var id = ref.id;

        return id;
},"row-class-name":_vm.rowClassName,"bordered":""},scopedSlots:_vm._u([{key:"id",fn:function(id, record){return _c('div',{},[(_vm.isOrdinaryOrder(record))?_c('router-link',{attrs:{"to":{
          name: 'OrderInfo',
          params: {
            id: id,
          },
        }}},[_vm._v(_vm._s(id)+" ")]):_c('router-link',{attrs:{"to":{
          name: 'OrderByClickInfo',
          params: {
            id: id,
          },
        }}},[_vm._v(" "+_vm._s((id + " (заказ в один клик)"))+" ")])],1)}},{key:"status",fn:function(status){return _c('div',{},[_c('status-view',{attrs:{"status-list":_vm.ORDER_STATUSES,"status":status}})],1)}},{key:"customer",fn:function(customer, record){return _c('div',{},[(_vm.isOrdinaryOrder(record))?[_c('div',[_vm._v(_vm._s(customer.full_name))]),_c('div',[_vm._v(_vm._s(customer.phone))])]:[_c('div',[_vm._v(_vm._s(customer.Name))]),_c('div',[_vm._v(_vm._s(customer.Phone))])]],2)}},{key:"employee",fn:function(employee, record){return _c('div',{},[(_vm.isOrdinaryOrder(record))?[_c('div',[_vm._v(_vm._s(employee.full_name ? employee.full_name : '-'))])]:[_c('div',[_vm._v(" "+_vm._s(employee.FirstName ? employee.FirstName + ' ' + employee.LastName : '-')+" ")])]],2)}},{key:"created_at",fn:function(created_at){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("formatDate")(created_at))+" ")])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }