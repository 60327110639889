











































































import { Component, Vue } from 'vue-property-decorator'
import { showErrorMsg } from '@/Core/utils'
import { columnNames } from './constants'
import { ORDER_STATUSES } from 'Core/types/order'

const TimeInterval = 60_000

@Component
class MixedOrderList extends Vue {
  mixedOrders: any[] = []
  loading = true
  pagination = {
    current: 1,
    total: 0,
    defaultPageSize: 50,
  }
  columnNames = columnNames
  ORDER_STATUSES = ORDER_STATUSES
  getRefreshedData = 0
  $API: any

  async initialize() {
    const PARAMS = {
      limit: 500,
      page: 1,
    }
    try {
      this.loading = true
      const { data } = await this.$API.PreorderService.getOrderList(PARAMS)
      const { orders } = await this.$API.OMS.getOrderList(PARAMS)
      this.mixedOrders = this.greedyMergeFilterByDate(data, orders)
    } catch (error) {
      showErrorMsg(error.message)
    } finally {
      this.loading = false
    }
  }
  greedyMergeFilterByDate(arr1, arr2) {
    let merged: any[] = []
    let index1 = 0
    let index2 = 0
    let current = 0
    const LEN = arr1.length + arr2.length
    while (current < LEN) {
      let unmerged1 = arr1[index1]
      let unmerged2 = arr2[index2]
      if (!unmerged1) {
        merged[current] = unmerged2
        index2++
      } else if (!unmerged2) {
        merged[current] = unmerged1
        index1++
      } else if (unmerged1.created_at > unmerged2.created_at) {
        merged[current] = unmerged1
        index1++
      } else {
        merged[current] = unmerged2
        index2++
      }
      current++
    }

    return merged
  }

  isOrdinaryOrder(item) {
    // определить обычный заказ или заказ в один клик
    return item.payment_type
  }

  rowClassName(record) {
    return this.isOrdinaryOrder(record) ? null : 'row-color'
  }

  mounted() {
    this.initialize()
    this.getRefreshedData = setInterval(this.initialize, TimeInterval)
  }
  beforeDestroy() {
    clearInterval(this.getRefreshedData)
  }
}

export default MixedOrderList
