export const order = {
  namespaced: true,
  state: () => ({
    filter: {
      id: null,
      status: null,
      paymentType: null,
      productStatuses: [],
      dateFrom: null,
      dateTo: null,
      customerPhone: null,
      limit: 50,
      employeeTdid: null,
    },
  }),
  mutations: {
    UPDATE_FILTER: (state, filter) => {
      state.filter = filter
    },
  },
  actions: {
    updateFilter({ commit }, filter) {
      commit('UPDATE_FILTER', filter)
    },
  },
  getters: {
    filter: (state) => state.filter,
  },
}
