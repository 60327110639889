export const columnNames = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'БИН',
    dataIndex: 'bin',
    key: 'bin',
    scopedSlots: { customRender: 'bin' },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    scopedSlots: { customRender: 'email' },
  },
  {
    title: 'Имя',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
    scopedSlots: { customRender: 'phone' },
  },
  {
    title: 'Название магазина',
    dataIndex: 'company_name',
    key: 'company_name',
    scopedSlots: { customRender: 'company_name' },
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'Дата обновления',
    dataIndex: 'updated_at',
    key: 'updated_at',
    scopedSlots: { customRender: 'updated_at' },
  },
  {
    title: 'Действия',
    key: 'action',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
