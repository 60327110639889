import BannerList from 'Content/pages/Banner/BannerList/BannerList'
import BannerEdit from 'Content/pages/Banner/BannerEdit/BannerEdit'
import BannerCreate from 'Content/pages/Banner/BannerCreate/BannerCreate'

import FastLinkList from 'Content/pages/FastLink/FastLinkList/FastLinkList'
import FastLinkInfo from 'Content/pages/FastLink/FastLinkInfo/FastLinkInfo'
import FastLinkCreate from 'Content/pages/FastLink/FastLinkCreate/FastLinkCreate'
import LinkEdit from 'Content/pages/FastLink/LinkEdit/LinkEdit'
import LinkCreate from 'Content/pages/FastLink/LinkCreate/LinkCreate'

import ProductReviewInfo from 'Content/pages/Reviews/ProductReviews/ProductReviewInfo/ProductReviewInfo'

import NestedMenuList from 'Content/pages/NestedMenu/NestedMenuList/NestedMenuList'
import NestedMenuCreate from 'Content/pages/NestedMenu/NestedMenuCreate/NestedMenuCreate'

import NestedMenuEdit from 'Content/pages/NestedMenu/NestedMenuEdit/NestedMenuEdit'
import DocumentsList from 'Content/pages/Documents/DocumentsList/DocumentsList'
import DocumentsCreate from 'Content/pages/Documents/DocumentsCreate/DocumentsCreate'
import DocumentsEdit from 'Content/pages/Documents/DocumentsEdit/DocumentsEdit'
import Reviews from 'Content/pages/Reviews/Reviews'

import CategoriesView from 'Content/pages/Categories/Categories'

import FaqList from 'Content/pages/Faq/FaqList/FaqList'
const FaqCreate = () => import('Content/pages/Faq/FaqCreate/FaqCreate')
const FaqEdit = () => import('Content/pages/Faq/FaqEdit/FaqEdit')

import PromoPageList from 'Content/pages/PromoPage/PromoPageList/PromoPageList'
import PromoPageCreate from 'Content/pages/PromoPage/PromoPageCreate/PromoPageCreate'
import PromoPageEdit from 'Content/pages/PromoPage/PromoPageEdit/PromoPageEdit'

export default [
  {
    path: '/banner',
    name: 'BannerList',
    component: BannerList,
    meta: {
      permission: {
        service: 'banner',
        permissions: ['banner-view'],
      },
      search: {
        name: 'Баннеры',
        icon: 'picture',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/banner/create',
    name: 'BannerCreate',
    component: BannerCreate,
    meta: {
      permission: {
        service: 'banner',
        permissions: ['banner-update'],
      },
      search: {
        name: 'Создать баннер',
        icon: 'picture',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/banner/:id',
    name: 'BannerEdit',
    component: BannerEdit,
    meta: {
      permission: {
        service: 'banner',
        permissions: ['banner-update'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: Reviews,
    meta: {
      permission: {
        service: 'feedback',
        permissions: ['product-review-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/reviews/products/:id',
    name: 'ProductReviewInfo',
    component: ProductReviewInfo,
    meta: {
      permission: {
        service: 'feedback',
        permissions: ['product-review-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/fast_links',
    name: 'FastLinkList',
    component: FastLinkList,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-view'],
      },
      search: {
        name: 'Группа быстрых ссылок',
        icon: 'link',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/fast_link/:id',
    name: 'FastLinkInfo',
    component: FastLinkInfo,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/fast_link/:fast_link_id/link_id=:id',
    name: 'LinkEdit',
    component: LinkEdit,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/fast_link/:fast_link_id/create',
    name: 'LinkCreate',
    component: LinkCreate,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/fast_link_create',
    name: 'FastLinkCreate',
    component: FastLinkCreate,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-view'],
      },
      search: {
        name: 'Создание быстрой ссылки',
        icon: 'link',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/nested-menu',
    name: 'NestedMenuList',
    component: NestedMenuList,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-view'],
      },
      search: {
        name: 'Вложенное меню',
        icon: 'unordered-list',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/nested-menu/create',
    name: 'NestedMenuCreate',
    component: NestedMenuCreate,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-create'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/nested-menu/:menu_id',
    name: 'NestedMenuEdit',
    component: NestedMenuEdit,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-update'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/documents',
    name: 'DocumentsList',
    component: DocumentsList,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-view'],
      },
      search: {
        name: 'Документы',
        icon: 'file-done',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/document-create',
    name: 'DocumentsCreate',
    component: DocumentsCreate,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-view'],
      },
      search: {
        name: 'Создание Документа',
        icon: 'file-done',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/document/:id',
    name: 'DocumentsEdit',
    component: DocumentsEdit,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/promo',
    name: 'PromoPageList',
    component: PromoPageList,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-view'],
      },
      search: {
        name: 'Промо страницы',
        icon: 'rocket',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/promo/:id',
    name: 'PromoPageEdit',
    component: PromoPageEdit,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/promo-create',
    name: 'PromoPageCreate',
    component: PromoPageCreate,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/faq/:type',
    name: 'FaqList',
    component: FaqList,
    meta: {
      permission: {
        service: 'banner',
        permissions: ['banner-view'],
      },
      search: {
        name: 'Faq эфейкю',
        icon: 'alert',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/faq-create',
    name: 'FaqCreate',
    component: FaqCreate,
    meta: {
      permission: {
        service: 'banner',
        permissions: ['banner-view'],
      },
      search: {
        name: 'Созадние FAQ',
        icon: 'alert',
      },
      failPath: '/censor',
    },
  },
  {
    path: '/faq-edit',
    name: 'FaqEdit',
    component: FaqEdit,
    meta: {
      permission: {
        service: 'banner',
        permissions: ['banner-view'],
      },
      failPath: '/censor',
    },
  },
  {
    path: '/categories',
    name: 'CategoriesView',
    component: CategoriesView,
    meta: {
      permission: {
        service: 'menu',
        permissions: ['menu-view'],
      },
      search: {
        name: 'Категории',
        icon: 'appstore',
      },
      failPath: '/censor',
    },
  },
]
