<template>
  <div>
    <div class="settings-wrap">
      <h2>Редактирование рассрочки</h2>
      <router-link :to="{ name: 'InstallmentList' }">
        <a-button>Назад</a-button>
      </router-link>
    </div>
    <a-divider>Текущий статус</a-divider>
    <a-descriptions bordered layout="vertical">
      <a-descriptions-item label="Статус">
        <a-tag v-if="isOutdated" color="red">
          Завершена ({{ this.installmentForm.end_at | formatDateISO }})
        </a-tag>
        <a-tag v-else-if="installmentForm.is_enabled" color="darkgreen">
          Запущена
        </a-tag>
        <a-tag v-else color="orange">Не активна</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="Автор">
        {{ auditInfo.first_name }} {{ auditInfo.last_name }}
      </a-descriptions-item>
      <a-descriptions-item
        label="Действия"
        v-if="!isOutdated && !installmentForm.is_enabled"
      >
        <div>
          <span v-if="startOfDayIsOld">
            Для запуска необходимо что бы день начала не был задним числом
          </span>
          <a-popconfirm
            v-else
            title="Процесс необратим. Продолжить?"
            ok-text="Да"
            cancel-text="Отмена"
            @confirm="launchHandler"
          >
            <a-button type="primary" shape="round">
              <a-icon type="rocket" /> Запустить!
            </a-button>
          </a-popconfirm>
        </div>
      </a-descriptions-item>
    </a-descriptions>
    <a-spin :spinning="isLoading">
      <a-form-model
        v-if="installmentForm"
        ref="form"
        :model="installmentForm"
        :rules="installmentFormRules"
        @submit="editInstallment"
      >
        <a-form-model-item prop="title.ru" label="Заголовок для сайта">
          <a-input v-model="installmentForm.title.ru" />
        </a-form-model-item>

        <a-form-model-item
          prop="admin_title"
          label="Заголовок для Админ панели"
        >
          <a-input v-model="installmentForm.admin_title" />
        </a-form-model-item>

        <a-form-model-item
          prop="partner_title"
          label="Заголовок для кабинета партнера"
        >
          <a-input v-model="installmentForm.partner_title" />
        </a-form-model-item>

        <a-form-model-item prop="categories" label="Зона действия рассрочки">
          <div class="list-wrap">
            <div
              v-for="(category, index) in installmentForm.categories"
              :key="index"
            >
              <a-tag closable @close.prevent="removeCategoryHandler(index)">
                {{ category.title.ru }}
              </a-tag>
            </div>
          </div>

          <category-selector
            :drawer-title="'Выберите категорию товара'"
            :drawer-width="'85%'"
            :multiple="true"
            :only-third-lvl="true"
            v-model="installmentForm.categories"
          />
        </a-form-model-item>
        <a-form-model-item prop="commission" label="Комиссионные">
          <a-input-number
            v-model="installmentForm.commission"
            block
            :min="0"
            :formatter="(value) => `${value}%`"
            :parser="(value) => value.replace('%', '')"
            :max="100"
            :precision="2"
          />
        </a-form-model-item>
        <a-form-model-item prop="duration" label="Продолжительность ( месяцы )">
          <a-input-number
            v-model="installmentForm.duration"
            block
            :min="3"
            :max="99"
          />
        </a-form-model-item>
        <a-form-model-item prop="min_product_price" label="Мин. сумма товара">
          <a-input-number
            :precision="0"
            :min="0"
            :max="99999999"
            v-model="installmentForm.min_product_price"
            block
          />
        </a-form-model-item>
        <a-form-model-item prop="max_product_price" label="Макс. сумма товара">
          <a-input-number
            :min="100"
            :max="99999999"
            :precision="0"
            v-model="installmentForm.max_product_price"
            block
          />
        </a-form-model-item>
        <a-form-model-item prop="end_at" label="Даты с-по">
          <a-range-picker
            :locale="locale"
            :disabled-date="disabledDate"
            :format="'DD MMM YYYY'"
            @change="onDateRangeChange"
            :placeholder="['С:', 'По:']"
            :value="[
              moment(installmentForm.start_at),
              moment(installmentForm.end_at),
            ]"
            :allow-clear="false"
          ></a-range-picker>
        </a-form-model-item>
        <a-button
          type="primary"
          html-type="submit"
          class="form__button"
          v-can="{
            service: 'payplan',
            permissions: ['installment-update'],
          }"
          :disabled="!isEditable"
          >{{
            !isEditable
              ? 'Нельзя редактировать старые или активные рассрочки'
              : 'Сохранить'
          }}
        </a-button>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import CategorySelector from 'Core/components/Selector/CategorySelector'

import { showErrorMsg, showSuccessMsg } from 'Core/utils'

import { installmentFormRules } from 'Marketing/page/Installment/InstallmentCreate/constants'

import moment from 'moment'
import 'moment/locale/ru'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import { removeFromArrayByIndex } from 'Core/modules/helpers'

export default {
  components: {
    CategorySelector,
  },
  computed: {
    installmentID() {
      return this.$route.params.installment_id
    },
    isOutdated() {
      return new Date() > new Date(this.installmentForm.end_at)
    },
    startOfDayIsOld() {
      return new Date() > new Date(this.installmentForm.start_at)
    },
    isEditable() {
      return !(this.isOutdated || this.installmentForm.is_enabled)
    },
  },
  watch: {
    'installmentForm.type': {
      handler(newValue) {
        if (newValue === 'category') {
          this.installmentForm.merchant_ids = []
        } else {
          this.installmentForm.categories = []
        }
      },
    },
  },
  data() {
    return {
      locale,
      installmentFormRules,
      isLoading: false,
      installmentForm: {
        background_color: '#000000',
        min_product_price: 0,
        max_product_price: 100,
        categories: [],
        commission: 1,
        duration: null,
        end_at: null,
        start_at: null,
        text_color: '#ffffff',
        title: { kz: null, ru: null },
      },
      auditInfo: {
        first_name: null,
        last_name: null,
        id: null,
      },
    }
  },
  created() {
    this.getInstallmentById()
  },
  methods: {
    moment,
    disabledDate(current) {
      return current && current < moment(new Date())
    },
    removeCategoryHandler(index) {
      removeFromArrayByIndex(this.installmentForm.categories, index)
    },
    async getInstallmentById() {
      try {
        this.isLoading = true
        this.installmentForm = await this.$API.Payplan.getInstallmentById(
          this.installmentID,
        )
        const response = await this.$API.Audit.getAuditLogs({
          event_type: 'payplan.create_installment',
          entity_id: this.installmentID,
        })
        this.auditInfo = response?.events[0]?.meta?.author
          ? JSON.parse(response?.events[0]?.meta?.author)
          : '{}'
        this.installmentForm.commission = this.installmentForm.commission / 100
      } catch (error) {
        showErrorMsg(error.message)
      } finally {
        this.isLoading = false
      }
    },
    onDateRangeChange(date) {
      this.installmentForm.start_at = moment(date[0]).format(
        'YYYY-MM-DDT00:00:00Z',
      )
      this.installmentForm.end_at = moment(date[1]).format(
        'YYYY-MM-DDT23:59:59Z',
      )
    },
    async editInstallment(event) {
      event.preventDefault()
      this.$refs.form.validate(async (result) => {
        if (result) {
          try {
            this.isLoading = true
            this.installmentForm.title.kz = this.installmentForm.title.ru
            await this.$API.Payplan.updateInstallmentById(
              this.installmentID,
              this.prepareForRequestHandler(),
            )
            showSuccessMsg('Изменения сохранены.')
            this.isLoading = false
          } catch (error) {
            showErrorMsg(error.message)
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    prepareForRequestHandler() {
      const params = { ...this.installmentForm }
      params.title.kz = params.title.ru
      params.commission = params.commission * 100
      params.categories = params.categories.map((item) => item.slug)
      return params
    },
    async launchHandler() {
      try {
        await this.$API.Payplan.launchInstallmentById(this.installmentID)
        showSuccessMsg('Рассрочка запущена!')
        await this.getInstallmentById()
      } catch (error) {
        showErrorMsg(error.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.ant-input-number {
  width: 200px;
}

.form__button {
  margin-top: 30px !important;
}

::v-deep {
  .ant-form-item-label {
    font-weight: 600 !important;
  }
}

.color {
  &__preview {
    width: 15px;
    height: 15px;
    border: 1px solid black;
  }

  &__option {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.installment {
  &__content {
    padding: 5px 30px;
    width: fit-content;
    border-radius: 5px;
  }

  &__text {
    margin: 0;
    font-weight: 600;
    padding: 0;
  }
}
.list-wrap {
  display: flex;
  flex-wrap: wrap;

  & span {
    padding: 7px 12px;
    font-size: 0.9rem;
  }
  margin-bottom: 10px;
}
.status-wrap {
  display: flex;
  justify-content: center;
  & span {
    padding: 7px 12px;
    font-weight: bold;
  }
}
</style>
